import { Loading } from '@components/loading/Loading'
import { Product, ProductVariant, Products } from 'Types'
import { FatalError } from 'errors/FatalError'
import { jsonFetch } from 'http/http'
import { genericRetryStrategy } from 'http/retryEstrategy'
import {
  useObservable,
  useObservableCallback,
  useObservableGetState,
} from 'observable-hooks'
import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { catchError, mergeMap, of, retryWhen } from 'rxjs'
import { mergeWith } from 'rxjs/operators'
import { useSelector } from '../store/Store'
import { useConfig } from './ConfigProvider'
import { useSettings } from './SettingsProviders'

type ProductsProviderProps = PropsWithChildren<{
  //
}>

type ProductsContextData = Products

const ProductsContext = React.createContext<ProductsContextData>([])

const configRetryStrategy = genericRetryStrategy({
  maxRetryAttempts: 1,
})

export default function ProductsProvider(props: ProductsProviderProps) {
  const { children } = props

  const config = useConfig()
  const { settings } = useSettings()
  const lastProductUpdate = settings?.lastProductUpdate

  const { t } = useTranslation()

  const [refresh, refresh$] = useObservableCallback<'refresh'>(
    ($events) => $events
  )

  const request$ = useObservable(() => {
    return of('refresh').pipe(
      mergeWith(refresh$),
      mergeMap(() => {
        return jsonFetch<Products>(
          config.products.endpoint +
            '?lastUpdate=' +
            (lastProductUpdateRef?.current || lastProductUpdate || 'latest')
        ).pipe(retryWhen(configRetryStrategy))
      })
    )
  }, [config.products.endpoint])

  const products = useObservableGetState(
    request$.pipe(
      catchError((err: Error) => {
        throw new FatalError(t('error_to_load_products'), err)
      })
    ),
    undefined
  )

  const lastProductUpdateRef = useRef<number | undefined>(undefined)
  useEffect(() => {
    if (products) {
      lastProductUpdateRef.current = lastProductUpdate
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  useEffect(() => {
    if (lastProductUpdate !== lastProductUpdateRef.current && products) {
      lastProductUpdateRef.current = lastProductUpdate
      refresh('refresh')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastProductUpdate])

  return (
    <ProductsContext.Provider value={products || []}>
      {!products && <Loading />}
      {!!products && children}
    </ProductsContext.Provider>
  )
}

function GetInvisibleProducts() {
  const settings = useSettings()
  const config = useConfig()

  const ended = settings.settings?.live.status == 'ended'

  const isRecorded = ended && config?.recorded_live

  const invisibletProductRecorded = useSelector((v) => v.video.invisibleProduct)

  const invisible =
    (isRecorded
      ? JSON.stringify(invisibletProductRecorded)
      : settings.settings?.invisible) || '[]'

  // const invisible = settings.settings?.invisible || '[]'

  try {
    return JSON.parse(invisible)
  } catch (e) {
    console.error(e)
    return []
  }
}

// force nonNullable casting because the consumer will always have a valid config
export function useProducts() {
  const productsList = useContext<NonNullable<ProductsContextData>>(
    ProductsContext as React.Context<NonNullable<ProductsContextData>>
  )
  const invisibleProducts = GetInvisibleProducts()
  return productsList.filter((product) => {
    return !invisibleProducts.includes(product.id)
  })
}

export function useProductById(id: Product['id']) {
  const products = useProducts()
  return products.find((v) => v.id == id)
}

export function useVariantById(id: ProductVariant['id']) {
  const products = useProducts()

  return products.flatMap((v) => v.variants).find((v) => v.id === id)
}
