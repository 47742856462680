import { createSlice } from '@reduxjs/toolkit'

export type RecordedLiveState = {
  recordedLive: boolean
}

const initialState: RecordedLiveState = {
  recordedLive: false,
}

export const recordedLiveReducer = createSlice({
  name: 'recorded-live',
  initialState: initialState,
  reducers: {
    setRecordedLive: (state, action) => {
      state.recordedLive = action.payload
    },
  },
})
