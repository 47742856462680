import { useSubscription } from 'observable-hooks'
import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import { useScreenSize } from '../../hooks/useScreenSize'
import { LikeCountIncrease } from '../../icons/LikeCountIncrease'
import { useChat } from '../../providers/ChatProviders'
import { useSettings } from '../../providers/SettingsProviders'

export function LikeAnimation() {
  const chat = useChat()
  const ref = useRef<HTMLDivElement | null>(null)
  const { isMobile } = useScreenSize()
  const { settings } = useSettings()
  const colorMode =
    settings?.uiSettings.colors.immutableBlack === '#FFFFFF' ? 'dark' : 'light'

  useSubscription(chat.likesDiff$, (diff) => {
    if (document.hidden) {
      return
    }

    // add a like heart for each diff.
    new Array(diff)
      .fill(0)
      .map((v, i) => new HeartAnimation(i * 5000, isMobile, colorMode))
      .forEach((v) => ref.current?.appendChild(v.elem!))
  })

  return <div ref={ref} />
}

class HeartAnimation {
  public elem?: HTMLDivElement
  private color: string

  constructor(delay: number, isMobile: boolean, colorMode: string) {
    this.elem = document.createElement('div')
    this.elem.className = `w-24 h-auto absolute right-[-7px] ${
      isMobile ? 'bottom-72' : 'bottom-40'
    }`
    this.color = colorMode

    setTimeout(() => {
      this.renderReactComponent()
    }, delay)

    setTimeout(() => {
      this.elem?.remove()
    }, delay + 9000)
  }

  renderReactComponent() {
    if (this.elem) {
      ReactDOM.render(<LikeCountIncrease type={this.color} />, this.elem)
    }
  }
}
