import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAccessibility } from '../../providers/AccessibilityProvider'

export type TextLiveProps = {
  recordedLive?: boolean | null
  isMobile?: boolean
}

export default function TextLive(props: TextLiveProps) {
  const { t } = useTranslation()
  const { accessibility } = useAccessibility()
  const showRecordedLive = useSelector(
    // @ts-ignore
    (state) => state.recordedLive.recordedLive
  )

  return (
    <div
      className={`flex-row items-center justify-center text-14 font-bold space-x-1 px-3 py-1 rounded-[24px] text-white ${
        props.recordedLive && (!props.isMobile ? 'mt-8' : 'mt-5')
      } ${!props.recordedLive || showRecordedLive ? '' : 'hidden'} ${
        accessibility.highContrast
          ? 'bg-primary border border-text animate-pulse'
          : 'bg-[#FF3838] animation-live-pulse'
      }`}
    >
      {!props.recordedLive ? t('live') : t('recorded')}
    </div>
  )
}
