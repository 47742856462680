import { MimoPlayerOptions } from 'Types'

export const API_VERSION = 'v1'

export const DEFAULT_OPTIONS: MimoPlayerOptions = {
  chatMessagesLimit: 10,
  debug: false,
  liveId: 'live-id-not-set',
  customerId: 'customer-id-not-set',
  liveUrl: 'live-url-not-set',
  useShadowDom: true,
  configEndpoint:
    import.meta.env.VITE_CONFIG_ENDPOINT || 'config-endpoint-not-set',
  dataCheckoutModalOff: 'false',
}
