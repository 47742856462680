import React from 'react'
import { IconProps } from 'Types'
export function BagIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      height="1.5em"
      width="1.5em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2.66675L4 8.00008V26.6667C4 27.374 4.28095 28.0523 4.78105 28.5524C5.28115 29.0525 5.95942 29.3334 6.66667 29.3334H25.3333C26.0406 29.3334 26.7189 29.0525 27.219 28.5524C27.719 28.0523 28 27.374 28 26.6667V8.00008L24 2.66675H8Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 8H28"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3332 13.3335C21.3332 14.748 20.7713 16.1045 19.7711 17.1047C18.7709 18.1049 17.4143 18.6668 15.9998 18.6668C14.5853 18.6668 13.2288 18.1049 12.2286 17.1047C11.2284 16.1045 10.6665 14.748 10.6665 13.3335"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
