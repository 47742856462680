import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { useSettings } from './SettingsProviders'

export default function WebBrowserProvider(props: PropsWithChildren<any>) {
  const { settings } = useSettings()

  const inputRef = useRef<HTMLAnchorElement>(null)
  const { children } = props
  const isInstagram =
    typeof window !== 'undefined'
      ? navigator.userAgent.includes('Instagram')
      : false

  useEffect(() => {
    if (isInstagram && inputRef) {
      const url = settings?.live.shareURL || window.location.href

      if (/android/i.test(navigator.userAgent)) {
        window.location.replace(
          'intent://' +
            url.replace('https://', '') +
            '/#Intent;scheme=https;S.browser_fallback_url=' +
            url +
            ';end;'
        )
      } else {
        window.open(url)
      }
    }
  }, [settings])

  return <>{children}</>
}
