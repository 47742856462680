import React, { PropsWithChildren } from 'react'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'
import { MimoRouterProvider } from './MimoRouter'
import { ROUTES } from './Routes'

const RouterType: typeof MemoryRouter = import.meta.env.PROD
  ? MemoryRouter
  : BrowserRouter

export function Router({
  children,
}: PropsWithChildren<{
  //
}>) {
  return (
    <RouterType>
      <RenderRoutes>{children}</RenderRoutes>
    </RouterType>
  )
}

function RenderRoutes({
  children,
}: PropsWithChildren<{
  //
}>) {
  return <MimoRouterProvider routes={ROUTES}>{children}</MimoRouterProvider>
}
