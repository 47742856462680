import { ProductsInfoHandler } from '@pages/productInfoPage/ProductInfoPage'
import { ProductsPageHandler } from '@pages/productsPage/ProductsPage'
import LiveOverlay from 'containers/liveOverlay/LiveOverlay'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Wall } from '../../components/wall/Wall'
import PoweredByMimoLogo from '../../icons/PoweredByMimoLogo'
import { CartPageHandler } from '../../pages/cartPage/CartPage'
import { useConfig } from '../../providers/ConfigProvider'
import { useSettings } from '../../providers/SettingsProviders'
import { useTracking } from '../../providers/TrackingProvider'
import { useVideo } from '../../providers/VideoProvider'
import { recordedLiveReducer } from '../../store/recordedLiveReducer'
import { useTheme } from '../../theme/ThemeProvider'
import ChatDesktop from '../chatDesktop/ChatDesktop'
import DesktopLiveOverlay from '../desktopLiveOverlay/DesktopLiveOverlay'
import { HeaderDesktop } from '../headerDesktop/HeaderDesktop'
import ProductsDesktop from '../productsDesktop/ProductsDesktop'
import VideoPlayer from '../videoPlayer/VideoPlayer'
import aspect from './assets/aspect-9-16.png'

export default function Stage() {
  const theme = useTheme()
  const tracking = useTracking()
  const settings = useSettings()

  useEffect(() => {
    let interval: NodeJS.Timeout
    // this is an ugly workaround to matomo audience event
    if (settings.settings?.live.status === 'live') {
      interval = setInterval(async () => {
        tracking.track({
          event: 'audience',
          status: settings.settings?.live.status,
        })
      }, 60000)
    }

    if (
      settings.settings?.live.status === 'ended' &&
      settings.settings?.live.recorded_live
    ) {
      interval = setInterval(async () => {
        tracking.track({
          event: 'recorded_live',
          status: settings.settings?.live.status,
        })
      }, 60000)
    }

    return () => {
      // unmount
      clearInterval(interval)
    }
  }, [tracking, settings])

  if (theme.size === 'desk') {
    return <StageDesktop />
  } else {
    return <StageMobile />
  }
}

function StageMobile() {
  const config = useConfig()

  const settings = useSettings()

  const ended = settings.settings?.live.status === 'ended'

  const shouldShowRecording = Boolean(config?.recorded_live) && ended

  const live = settings.settings?.live.status === 'live'

  const awaiting = settings.settings?.live.status === 'awaiting'

  const video = useVideo()

  const dispacth = useDispatch()
  const showRecordedLive = useSelector(
    // @ts-ignore
    (state) => state.recordedLive.recordedLive
  )

  useEffect(() => {
    const milliSeconds = config?.ended && Date.now() - Date.parse(config?.ended)
    if (milliSeconds && config?.recorded_live) {
      dispacth(
        recordedLiveReducer.actions.setRecordedLive(
          milliSeconds >= config?.recorded_live_schedule
        )
      )
    }
  }, [config?.recorded_live_schedule, config?.ended])

  return (
    <div data-testid="Stage" className="overflow-hidden mimo-container">
      {/* Modal pages */}
      <>
        <CartPageHandler type="secondary" />
        <ProductsPageHandler type="secondary" />
        <ProductsInfoHandler type="secondary" />

        {/* Chat/Overlay */}
        <LiveOverlay />

        {config.water_mark && (
          <div className="h-[15px] w-full backgroundPoweredMimo absolute flex items-center px-2 pl-2 pt-4 z-[999] bottom-3">
            <PoweredByMimoLogo />
          </div>
        )}
        {awaiting && config.walls?.awaiting?.live_thumb && (
          <Wall image={config.walls?.awaiting?.live_thumb} />
        )}

        {/* Video */}
        {((live && !video.error) ||
          (shouldShowRecording && showRecordedLive)) && <VideoPlayer />}

        {live && video.error && (
          <Wall
            videoError={video.error}
            image={config.walls.callback.live_thumb}
          />
        )}

        {ended && !showRecordedLive && (
          <Wall
            image={config.walls.end.live_thumb}
            title={config.walls.end.title}
            text={config.walls.end.text}
            msg_powered={Boolean(Number(config.msg_powered))}
          />
        )}
      </>
    </div>
  )
}

function StageDesktop() {
  const config = useConfig()

  const settings = useSettings()

  const ended = settings.settings?.live.status === 'ended'

  const awaiting = settings.settings?.live.status === 'awaiting'

  const shouldShowRecording = Boolean(config?.recorded_live) && ended

  const live = settings.settings?.live.status === 'live'

  const video = useVideo()

  const dispacth = useDispatch()
  const showRecordedLive = useSelector(
    // @ts-ignore
    (state) => state.recordedLive.recordedLive
  )

  useEffect(() => {
    const milliSeconds = config?.ended && Date.now() - Date.parse(config?.ended)
    if (milliSeconds && config?.recorded_live) {
      dispacth(
        recordedLiveReducer.actions.setRecordedLive(
          milliSeconds >= config?.recorded_live_schedule
        )
      )
    }
  }, [config?.recorded_live_schedule, config?.ended])

  return (
    <div data-testid="Stage" className="overflow-hidden mimo-container flex">
      <div className="flex-1 flex justify-end">
        <div className="w-full max-w-lg flex-shrink-0 relative flex flex-col">
          <HeaderDesktop />
          <ProductsDesktop />
          <ProductsInfoHandler />
          <CartPageHandler type="secondary" />
        </div>
      </div>

      <div className=" h-full relative">
        <DesktopLiveOverlay />

        {awaiting && config.walls?.awaiting?.live_thumb && (
          <Wall image={config.walls?.awaiting?.live_thumb} />
        )}

        {((live && !video.error) ||
          (shouldShowRecording && showRecordedLive)) && <VideoPlayer />}

        {live && video.error && (
          <Wall
            videoError={video.error}
            image={config.walls.callback.live_thumb}
          />
        )}

        {ended && !showRecordedLive && (
          <Wall
            image={config.walls.end.live_thumb}
            title={config.walls.end.title}
            text={config.walls.end.text}
            msg_powered={Boolean(Number(config.msg_powered))}
          />
        )}
        {config.water_mark && (
          <div className="h-[15px] w-full backgroundPoweredMimo absolute flex items-center pl-2 pt-4 z-[999] bottom-3">
            <PoweredByMimoLogo />
          </div>
        )}
        <img alt="none" className="h-full w-auto opacity-0" src={aspect} />
      </div>
      <div className="flex-1 flex justify-start">
        <div className="w-full max-w-lg flex-shrink-0">
          <ChatDesktop />
        </div>
      </div>
    </div>
  )
}
