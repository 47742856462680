import React, { PropsWithChildren, ReactNode } from 'react'
import { BorderedBlock } from '../borderedBlock/BorderedBlock'

type DesktopTitleProps = PropsWithChildren<{
  leftSlot?: ReactNode
  rightStot?: ReactNode
}>
export function DesktopTitle(props: DesktopTitleProps) {
  return (
    <BorderedBlock className="flex text-24 font-barlow-cond justify-center text-text items-center min-h-[66px]">
      <div>{props.leftSlot}</div>
      <div className="text-center flex-1">{props.children}</div>
      <div>{props.rightStot}</div>
    </BorderedBlock>
  )
}
