import React from 'react'
import { IconProps } from 'Types'
export function AddToCartIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="1.2em"
      height="1.2em"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.582 13.1667V8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10.583H19.1667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8306 25.1847C11.4403 25.1847 11.9345 24.6904 11.9345 24.0806C11.9345 23.4709 11.4403 22.9766 10.8306 22.9766C10.2208 22.9766 9.72656 23.4709 9.72656 24.0806C9.72656 24.6904 10.2208 25.1847 10.8306 25.1847Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2.69865"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9751 25.1847C23.5848 25.1847 24.0791 24.6904 24.0791 24.0806C24.0791 23.4709 23.5848 22.9766 22.9751 22.9766C22.3654 22.9766 21.8711 23.4709 21.8711 24.0806C21.8711 24.6904 22.3654 25.1847 22.9751 25.1847Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2.69865"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2H6.41597L9.37468 16.7825C9.47563 17.2908 9.75214 17.7473 10.1558 18.0723C10.5595 18.3972 11.0646 18.5698 11.5827 18.5599H22.3135C22.8316 18.5698 23.3367 18.3972 23.7403 18.0723C24.144 17.7473 24.4205 17.2908 24.5215 16.7825L26.2879 7.51997"
        stroke="currentColor"
        strokeWidth="2.69865"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
