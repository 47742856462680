import FloatingButton from '@components/floatingButton/FloatingButton'
import { CartIcon } from '@icons/CartIcon'
import { CheckoutProductURLConfig, Product, ProductVariant } from 'Types'
import { CheckoutProviderContext } from 'providers/CheckoutProvider'
import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useActionLoop } from 'store/Store'
import { checkoutReducer } from 'store/checkoutReducer'
import { useToaster } from '../../components/toaster/Toaster'

export function CheckoutProductURLProvider({
  config,
  children,
}: PropsWithChildren<{
  config: CheckoutProductURLConfig
}>) {
  const [showToaster] = useToaster()
  const { t } = useTranslation()
  const addToCartLabel = config.meta.addProduct.label
  const intentAddToCart = useActionLoop(
    checkoutReducer.actions.intent_addToCart,
    (_action) => {
      if (_action.payload.variant.url) {
        window.open(_action.payload.variant.url)
      } else {
        showToaster(
          `${t('unable_to_find_product_url')} ${_action.payload.variant.sku}`
        )
      }
    }
  )

  const addToCart = useCallback(
    (product: Product, variant: ProductVariant, quantity: number) => {
      intentAddToCart({
        product,
        quantity,
        variant,
      })
    },
    [intentAddToCart]
  )

  const renderCartButton = useCallback(() => {
    return config.meta.cartButton?.url ? (
      <a
        target={config.meta.cartButton.target || undefined}
        href={config.meta.cartButton.url}
        rel="noreferrer"
        aria-label={t('cart')}
      >
        <FloatingButton
          color="primary"
          circled={true}
          count={undefined}
          icon={<CartIcon className="text-text" />}
        />
      </a>
    ) : null
  }, [config.meta])

  const contextValue = useMemo(() => {
    return {
      addToCartLabel,
      renderCartButton,
      type: config.type,
      getStockInfo: () => ({ available: true, quantityAvailable: true }),
      addToCart: addToCart,
      isAddingToCart: false,
      allowQuantityControl: false,
      editCart: () => {}, // n/a
      cartCount: 0,
      cart: [],
      finishCart: () => {}, // n/a
    }
  }, [config, addToCart, renderCartButton, addToCartLabel])

  return (
    <CheckoutProviderContext.Provider value={contextValue}>
      {children}
    </CheckoutProviderContext.Provider>
  )
}
