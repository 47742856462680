import { Field } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface RadioButtonProps {
  text?: string
  name: string
}

export function RadioButton({ text, name }: RadioButtonProps) {
  const { t } = useTranslation()

  return (
    <>
      <div id={`${name}_field`} className="!mt-4">
        {t(text ? text : name)}
      </div>
      <div
        role="group"
        aria-labelledby={`${name}_field`}
        className="flex w-full h-auto space-x-6 !mb-4 pl-1"
      >
        <label className="flex items-center cursor-pointer">
          <Field
            type="radio"
            name={name}
            value={'true'}
            className={'radio-chat'}
          />
          <span className="ml-[10px]">{t('Sim')}</span>
        </label>
        <label className="flex items-center cursor-pointer">
          <Field
            type="radio"
            name={name}
            value={'false'}
            className={'radio-chat'}
          />
          <span className="ml-[10px]">{t('Não')}</span>
        </label>
      </div>
    </>
  )
}
