import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

type BtnIconProps = PropsWithChildren<{
  className?: string
  label: string
}>

export default function BtnIcon(props: BtnIconProps) {
  const { className, label, children } = props

  return (
    <div className={clsx('flex w-[64px] flex-col items-center', className)}>
      <div className={clsx('text-[40px]')}>{children}</div>
      <div className="text-12 text-text mt-1">{label}</div>
    </div>
  )
}
