import React from 'react'
import { IconProps } from 'Types'
export function HighContrastIcon({ className }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M9.99996 18.3333C5.39746 18.3333 1.66663 14.6025 1.66663 9.99996C1.66663 5.39746 5.39746 1.66663 9.99996 1.66663C14.6025 1.66663 18.3333 5.39746 18.3333 9.99996C18.3333 14.6025 14.6025 18.3333 9.99996 18.3333ZM9.99996 16.6666V3.33329C8.23185 3.33329 6.53616 4.03567 5.28591 5.28591C4.03567 6.53616 3.33329 8.23185 3.33329 9.99996C3.33329 11.7681 4.03567 13.4638 5.28591 14.714C6.53616 15.9642 8.23185 16.6666 9.99996 16.6666Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
