import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BorderedBlock } from '../../components/borderedBlock/BorderedBlock'
import { DesktopTitle } from '../../components/desktopTitle/DesktopTitle'
import { useChatEnabled } from '../../hooks/useChatHooks'
import { ChatONIcon } from '../../icons/ChatONIcon'
import { useChat } from '../../providers/ChatProviders'
import { useSettings } from '../../providers/SettingsProviders'
import { ChatInputControl } from '../chatInputControl/ChatInputControl'
import ChatList from '../chatList/ChatList'
import { useConfig } from '@providers/ConfigProvider'

export default function ChatDesktop() {
  const { t } = useTranslation()

  const chat = useChat()
  const { settings } = useSettings()
  const config = useConfig()
  const chatEnabled = useChatEnabled()

  const [showInputMsg, setShowInputMsg] = useState(true)

  const isRecorded = config?.recorded_live

  useEffect(() => {
    if (isRecorded && !chatEnabled) setShowInputMsg(false)
    else setShowInputMsg(true)
  }, [showInputMsg, chatEnabled])

  if (!isRecorded && !chatEnabled) {
    return null
  }

  return (
    <div className="mimo-container flex flex-col justify-end">
      <DesktopTitle
        leftSlot={
          <span className="text-20">
            <ChatONIcon />
          </span>
        }
        rightStot={null}
      >
        {t('chat').toUpperCase()}
      </DesktopTitle>

      {chat.visible && (
        <div className="fade-mask flex-1 flex flex-col justify-end min-h-0 py-3 pl-6 pr-2">
          <ChatList />
        </div>
      )}

      {showInputMsg && (
        <BorderedBlock className="py-5">
          <ChatInputControl />
        </BorderedBlock>
      )}
    </div>
  )
}
