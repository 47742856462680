import ChatInput from '@components/chatInput/ChatInput'
import { useObservable, useSubscription } from 'observable-hooks'
import { useChat } from 'providers/ChatProviders'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { filter, tap } from 'rxjs'
import { Product } from 'Types'
import { chatReducer } from '../../store/chatReducer'
import { useAction$ } from '../../store/Store'

export function ChatInputControl() {
  const { sendMessage, isSendingMessage } = useChat()

  const inputRef = useRef<HTMLInputElement | null>(null)

  const [value, setValue] = useState('')

  const actions$ = useAction$()

  const { t } = useTranslation()

  const askEvents$ = useObservable(() =>
    actions$.pipe(
      filter(
        (v) => v.type === chatReducer.actions.intent_AskForInformation.type
      ),
      tap((v) => {
        const payload = v.payload as Product
        //const txt = `Quero saber mais sobre '${payload.title}': `
        const txt = `${t('i_want_to_know_more_about')} ${payload.title}`

        setValue(txt)
        requestAnimationFrame(() => {
          inputRef.current?.focus()
          inputRef.current?.setSelectionRange(txt.length, txt.length)
        })
      })
    )
  )

  useSubscription(askEvents$)

  const onSubmit = useCallback(
    (message: string) => {
      sendMessage(message)
    },
    [sendMessage]
  )

  return (
    <ChatInput
      ref={inputRef}
      value={value}
      setValue={setValue}
      loading={isSendingMessage}
      onSubmit={onSubmit}
    />
  )
}
