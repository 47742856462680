import { fromFetch } from 'rxjs/fetch'

export class HTTPError extends Error {
  constructor(message: string, public json: any) {
    super(message)
  }
}

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

// We expect all requests to be json
function normalizeOptions(options: RequestInit): RequestInit {
  return {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers,
    },
  }
}

export function jsonFetch<T>(endpoint: string, options: RequestInit = {}) {
  return fromFetch<T>(endpoint, {
    ...normalizeOptions(options),
    selector: async (res) => {
      const contentType = res.headers.get('content-type')
      let json = null
      // We expect all requests to be json
      if (contentType && contentType.indexOf('application/json') !== -1) {
        json = await res.json()
      } else {
        const text = await res.text()
        throw new Error(text)
      }

      if (!res.ok) {
        throw new HTTPError(
          (json as any)?.message || 'Erro ao recuperar dados',
          json
        )
      }

      return json
    },
  })
}
