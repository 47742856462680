import React from 'react'

const PoweredByMimoLogo = () => {
  return (
    <svg
      width="50"
      height="19"
      viewBox="0 0 50 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_7565_57382)">
        <path
          d="M17.7436 2.26641C17.7436 2.0356 17.9306 1.84863 18.1614 1.84863C18.3922 1.84863 18.5791 2.0356 18.5791 2.26641V10.4306C18.5791 10.6615 18.3922 10.8484 18.1614 10.8484C17.9306 10.8484 17.7436 10.6615 17.7436 10.4306V2.26641Z"
          fill="white"
        />
        <path
          d="M40.7371 1.89286C40.1173 1.89286 39.5366 2.0102 38.9955 2.24573C38.4543 2.48084 37.9816 2.80062 37.5775 3.20464C37.1735 3.60866 36.8542 4.0836 36.6186 4.6286C36.3835 5.17403 36.2658 5.75642 36.2658 6.37621C36.2658 6.99599 36.3835 7.57666 36.6186 8.11779C36.8537 8.65893 37.1735 9.13172 37.5775 9.53574C37.9816 9.93976 38.4539 10.2591 38.9955 10.4946C39.5366 10.7297 40.1173 10.8475 40.7371 10.8475C41.3569 10.8475 41.9371 10.7302 42.4787 10.4946C43.0198 10.2595 43.4926 9.93976 43.8966 9.53574C44.3006 9.13172 44.6204 8.65893 44.8555 8.11779C45.091 7.57666 45.2088 6.99599 45.2088 6.37621C45.2088 5.75642 45.091 5.17403 44.8555 4.6286C44.6204 4.0836 44.3006 3.60866 43.8966 3.20464C43.4926 2.80105 43.0198 2.48127 42.4787 2.24573C41.9375 2.01063 41.3569 1.89286 40.7371 1.89286ZM37.0888 6.37664C37.0888 5.86688 37.1847 5.38635 37.3772 4.93505C37.5694 4.48418 37.8307 4.09005 38.1599 3.75265C38.4896 3.41525 38.8756 3.14833 39.3191 2.95234C39.7623 2.75635 40.2355 2.65792 40.7371 2.65792C41.2387 2.65792 41.7119 2.75635 42.155 2.95234C42.5981 3.14833 42.9845 3.41525 43.3142 3.75265C43.6439 4.08962 43.9048 4.48418 44.0969 4.93505C44.289 5.38635 44.3853 5.86645 44.3853 6.37664C44.3853 6.88682 44.289 7.36735 44.0969 7.81822C43.9043 8.26952 43.6439 8.66193 43.3142 8.99504C42.9845 9.32857 42.5981 9.59333 42.155 9.78932C41.7119 9.98532 41.2391 10.0833 40.7371 10.0833C40.2351 10.0833 39.7623 9.98575 39.3191 9.78932C38.8756 9.59333 38.4896 9.32857 38.1599 8.99504C37.8303 8.66193 37.5694 8.26952 37.3772 7.81822C37.1847 7.36735 37.0888 6.88682 37.0888 6.37664Z"
          fill="white"
        />
        <path
          d="M31.2506 2.28038L30.8036 2.88211L27.675 7.26789L24.5464 2.88211L24.0994 2.28038C23.8463 1.92536 23.0262 1.93739 23.0262 2.73684V10.4304C23.0262 10.6612 23.2132 10.8482 23.444 10.8482C23.6748 10.8482 23.8618 10.6612 23.8618 10.4304V3.25519L27.2981 7.9861C27.4632 8.21398 27.6211 8.20841 27.6669 8.20679L27.6746 8.20659L27.6823 8.20679C27.7282 8.20841 27.8864 8.21399 28.0511 7.9861L31.4874 3.25519V10.4309C31.4874 10.6617 31.6744 10.8486 31.9052 10.8486C32.136 10.8486 32.323 10.6617 32.323 10.4309V2.73684C32.323 1.93782 31.5038 1.92536 31.2506 2.28038Z"
          fill="white"
        />
        <path
          d="M12.2244 2.28038L11.7774 2.88211L8.64882 7.26789L5.52024 2.88211L5.07323 2.28038C4.82008 1.92536 4 1.93739 4 2.73684V10.4304C4 10.6612 4.18697 10.8482 4.41777 10.8482C4.64858 10.8482 4.83555 10.6612 4.83555 10.4304V3.25519L8.27188 7.9861C8.43695 8.21398 8.59486 8.20841 8.64068 8.20679L8.64839 8.20659L8.65611 8.20679C8.70196 8.20841 8.86023 8.21399 9.0249 7.9861L12.4612 3.25519V10.4309C12.4612 10.6617 12.6482 10.8486 12.879 10.8486C13.1098 10.8486 13.2968 10.6617 13.2968 10.4309V2.73684C13.2968 1.93782 12.4776 1.92536 12.2244 2.28038Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_7565_57382"
          x="0"
          y="0.848633"
          width="49.209"
          height="18"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7565_57382"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_7565_57382"
            result="effect2_dropShadow_7565_57382"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7565_57382"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
export default PoweredByMimoLogo
