import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

export type BulletButtonColorProps = PropsWithChildren<{
  onClick(): void
  type: 'primary' | 'secondary'
}>

BulletButtonColor.defaultProps = {
  type: 'primary',
  onClick: () => {},
}

export default function BulletButtonColor(props: BulletButtonColorProps) {
  return (
    <button
      onClick={props.onClick}
      className={clsx(
        'bg-opacity-70 text-[11px] text-center w-full overflow-hidden rounded-full font-medium block px-1 py-2',
        props.type == 'primary' && 'bg-ctaPrimary text-ctaText',
        props.type == 'secondary' && 'bg-secondary text-text'
      )}
    >
      {props.children}
    </button>
  )
}
