import { MimoPlayerOptions } from 'Types'
import Stage from 'containers/stage/Stage'
import { ErrorHandler } from 'errors/ErrorHandler'
import i18next from 'i18next'
import I18NextHttpBackend from 'i18next-http-backend'
import BusinessProvider from 'providers/BusinessProvider'
import ConfigProvider from 'providers/ConfigProvider'
import EmbedParamsProvider from 'providers/EmbedParamsProvider'
import ProductsProvider from 'providers/ProductsProvider'
import { VideoProvider } from 'providers/VideoProvider'
import WebBrowserProvider from 'providers/WebBrowserProvider'
import React from 'react'
import { initReactI18next } from 'react-i18next'
import { Router } from 'router/Router'
import { Store } from 'store/Store'
import '../embed/player.html'
import { PopupProvider } from './components/popup/popup'

i18next
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .init({
    load: 'currentOnly',
    backend: {
      loadPath: `${import.meta.env.VITE_S3_BUCKET_ASSETS}/languages/{{lng}}.json`,
      requestOptions: {
        cache: 'no-cache',
      },
    },
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',
    react: {
      useSuspense: false,
    },
  })

type AppProps = {
  options: MimoPlayerOptions
}
export function App(props: AppProps) {
  return (
    <EmbedParamsProvider options={props.options}>
      <ErrorHandler>
        <Store>
          <Router>
            <ConfigProvider>
              <BusinessProvider>
                <WebBrowserProvider>
                  <PopupProvider>
                    <VideoProvider>
                      <ProductsProvider>
                        <Stage />
                      </ProductsProvider>
                    </VideoProvider>
                  </PopupProvider>
                </WebBrowserProvider>
              </BusinessProvider>
            </ConfigProvider>
          </Router>
        </Store>
      </ErrorHandler>
    </EmbedParamsProvider>
  )
}
