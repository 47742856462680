import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccessibility } from '../../providers/AccessibilityProvider'

export type QuantityInputProps = {
  value: number
  onChange(value: number): void

  max: number
  min: number
}

QuantityInput.defaultProps = {
  min: 1,
  max: 99,
}

export default function QuantityInput(props: QuantityInputProps) {
  const { value, onChange, min, max } = props
  const onMinusClick = useCallback(() => {
    onChange(Math.max(min, value - 1))
  }, [onChange, value, min])

  const onPlusClick = useCallback(() => {
    onChange(Math.min(value + 1, max))
  }, [onChange, value, max])

  return (
    <div
      className={clsx(
        'text-immutableBlack bg-immutableWhite items-center rounded-full px-[0.25em] py-[0.15em] inline-flex'
      )}
    >
      <QuantityButton disabled={value <= 1} onClick={onMinusClick} label="-" />
      <div className="text-center px-[0.2em] font-bold">{value}</div>
      <QuantityButton onClick={onPlusClick} label="+" />
    </div>
  )
}

type QuantityButtonProps = {
  onClick(): void
  label: string
  disabled?: boolean
}
function QuantityButton(props: QuantityButtonProps) {
  const { t } = useTranslation()
  const { accessibility } = useAccessibility()
  const { onClick, disabled } = props
  return (
    <button
      aria-disabled={disabled}
      onClick={onClick}
      className={clsx(
        'text-ctaPrimary text-[1.3em] bold py-[0.25em] px-[0.4em] leading-none',
        disabled && 'pointer-events-none opacity-30',
        accessibility.highContrast && '!text-white'
      )}
      aria-label={
        props.label === '+' ? t('increase_quantity') : t('decrease_quantity')
      }
    >
      {props.label}
    </button>
  )
}
