import React from 'react'
import { IconProps } from 'Types'

export function CartIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="1.2em"
      height="1.2em"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54474 19.0915C7.99661 19.0915 8.36293 18.7252 8.36293 18.2733C8.36293 17.8214 7.99661 17.4551 7.54474 17.4551C7.09288 17.4551 6.72656 17.8214 6.72656 18.2733C6.72656 18.7252 7.09288 19.0915 7.54474 19.0915Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5447 19.0915C16.9966 19.0915 17.3629 18.7252 17.3629 18.2733C17.3629 17.8214 16.9966 17.4551 16.5447 17.4551C16.0929 17.4551 15.7266 17.8214 15.7266 18.2733C15.7266 18.7252 16.0929 19.0915 16.5447 19.0915Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1.90918H4.27273L6.46546 12.8646C6.54027 13.2413 6.7452 13.5797 7.04436 13.8205C7.34351 14.0613 7.71784 14.1893 8.10182 14.1819H16.0545C16.4385 14.1893 16.8129 14.0613 17.112 13.8205C17.4112 13.5797 17.6161 13.2413 17.6909 12.8646L19 6.00009H5.09091"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
