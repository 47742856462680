import React from 'react'
import { IconProps } from 'Types'

/**
 * fill: #4259D2
 */
export function FacebookIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="8" fill="currentColor" />
      <path
        d="M21.7956 31V20.9811H25.3496L25.8779 17.0584H21.7956V14.5597C21.7956 13.4278 22.1273 12.6528 23.8355 12.6528H26V9.15545C24.9468 9.04811 23.8882 8.99629 22.8291 9.00021C19.6877 9.00021 17.5308 10.824 17.5308 14.1722V17.051H14V20.9738H17.5386V31H21.7956Z"
        fill="white"
      />
    </svg>
  )
}
