import React from 'react'
import { IconProps } from 'Types'

export function ShareIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="0.95em"
      height="1em"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3346 6.44466C15.8074 6.44466 17.0013 5.25075 17.0013 3.77799C17.0013 2.30524 15.8074 1.11133 14.3346 1.11133C12.8619 1.11133 11.668 2.30524 11.668 3.77799C11.668 5.25075 12.8619 6.44466 14.3346 6.44466Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66667 12.6673C5.13943 12.6673 6.33333 11.4734 6.33333 10.0007C6.33333 8.52789 5.13943 7.33398 3.66667 7.33398C2.19391 7.33398 1 8.52789 1 10.0007C1 11.4734 2.19391 12.6673 3.66667 12.6673Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3346 18.889C15.8074 18.889 17.0013 17.6951 17.0013 16.2223C17.0013 14.7496 15.8074 13.5557 14.3346 13.5557C12.8619 13.5557 11.668 14.7496 11.668 16.2223C11.668 17.6951 12.8619 18.889 14.3346 18.889Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.96875 11.3418L12.0399 14.8796"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.031 5.12012L5.96875 8.65789"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
