import React from 'react'
import { LikeIcon } from '../../icons/LikeIcon'

interface HeartSwitcherProps {
  isLoading: boolean
  imageUrl: string
}

export function HeartSwitcher({ isLoading, imageUrl }: HeartSwitcherProps) {
  return (
    <div className="w-[1.688rem] h-[1.688rem] relative flex justify-center items-center">
      {isLoading ? (
        <div className="w-20 h-20 absolute max-w-none max-h-none">
          <img
            src={imageUrl}
            alt="Several red hearts appear on the screen"
            className=" object-contain"
          />
        </div>
      ) : (
        <LikeIcon />
      )}
    </div>
  )
}
