import FloatingButton from '@components/floatingButton/FloatingButton'
import { CartIcon } from '@icons/CartIcon'
import { CheckoutMagentoApiConfig, Product, ProductVariant } from 'Types'
import { useObservableCallback, useSubscription } from 'observable-hooks'
import { CheckoutProviderContext } from 'providers/CheckoutProvider'
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { catchError, mapTo, of, switchMap, tap } from 'rxjs'
import { useActionLoop } from 'store/Store'
import { AddToCartPayload, checkoutReducer } from 'store/checkoutReducer'
import { useToaster } from '../../components/toaster/Toaster'
import { API_VERSION } from '../../consts'
import { jsonFetch } from '../../http/http'
import { useMimoRouter } from '../../router/MimoRouter'
import { PATHS } from '../../router/Paths'
import { generateURL } from '../../utils/FormatUtil'
import { useEmbedParams } from '../EmbedParamsProvider'

export function CheckoutMagentoAPIProvider({
  config,
  children,
}: PropsWithChildren<{
  config: CheckoutMagentoApiConfig
}>) {
  const params = useEmbedParams()

  const addToCartLabel = config.meta.addProduct.label || undefined

  const intentAddToCart = useActionLoop(
    checkoutReducer.actions.intent_addToCart,
    (_action) => {
      addToCartPost(_action.payload)
    }
  )

  const intentAddedToCart = useActionLoop(
    checkoutReducer.actions.intent_addedToCart,
    (_action) => {}
  )

  const router = useMimoRouter()

  const { t } = useTranslation()

  const addToCart = useCallback(
    (product: Product, variant: ProductVariant, quantity: number) => {
      intentAddToCart({
        product,
        quantity,
        variant,
      })
    },
    [intentAddToCart]
  )

  const [showToaster] = useToaster()
  const [isAddingToCart, setIsAddingToCart] = useState(false)

  // POST - send message
  const [addToCartPost, addToCartPost$] = useObservableCallback<
    string,
    AddToCartPayload
  >(($evt) =>
    $evt.pipe(
      switchMap((payload) => {
        setIsAddingToCart(true)
        return jsonFetch<{ success: boolean; message: string }>(
          generateURL(config.meta.addProduct.endpoint, {
            MIMO_LIVE_ID: params.liveId!,
            MIMO_API_VERSION: API_VERSION,
          }),
          {
            method: 'POST',
            body: JSON.stringify({
              cart: params.cartId || 'not-set',
              sku: payload.variant.sku,
              quantity: payload.quantity,
            }),
          }
        ).pipe(
          tap((res) => {
            setIsAddingToCart(false)

            intentAddedToCart(undefined)

            !!res.message && showToaster(res.message)
            if (res.success) {
              router.go(PATHS.home)
            }
          }),
          catchError((err) => {
            console.error(err)
            showToaster(t('unable_to_add_to_cart'))
            setIsAddingToCart(false)
            return of('ok')
          }),
          mapTo('ok')
        )
      })
    )
  )

  useSubscription(addToCartPost$)

  // TODO: make it generic since headless has the exact same code
  const renderCartButton = useCallback(() => {
    return config.meta.cartButton?.url ? (
      <a
        target={config.meta.cartButton.target || undefined}
        href={config.meta.cartButton.url}
        rel="noreferrer"
        aria-label={t('cart')}
      >
        <FloatingButton
          color="primary"
          circled={true}
          count={undefined}
          icon={<CartIcon className="text-text" />}
        />
      </a>
    ) : null
  }, [config.meta])

  const contextValue = useMemo(() => {
    return {
      finishCart: () => {},
      renderCartButton,
      type: config.type,
      addToCart: addToCart,
      getStockInfo: () => ({ available: true, quantityAvailable: true }),
      isAddingToCart,
      addToCartLabel,
      allowQuantityControl: true,
      cart: [],
      editCart: () => {},
      cartCount: 0,
    }
  }, [config, addToCart, renderCartButton, isAddingToCart, addToCartLabel])

  return (
    <CheckoutProviderContext.Provider value={contextValue}>
      {children}
    </CheckoutProviderContext.Provider>
  )
}
