import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AccessibilityIcon } from '../../icons/AccessibilityIcon'
import { useAccessibility } from '../../providers/AccessibilityProvider'

interface Props {
  type: string
  className: string
}
export const AccessibilityMenu = (props: Props) => {
  const { t } = useTranslation()
  const { items } = useAccessibility()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const toggleMenu = (event: React.MouseEvent) => {
    event.stopPropagation()
    setShowMenu((prevShowMenu) => !prevShowMenu)
  }

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        aria-label={t('accessibility')}
        className={`${
          props.type === 'mobile' &&
          'flex items-center justify-center w-[56px] h-[56px] rounded-full bg-primary'
        } cursor-pointer`}
        onClick={toggleMenu}
      >
        <AccessibilityIcon className={props.className} />
      </button>

      {showMenu && (
        <div
          ref={menuRef}
          className={`flex absolute gap-y-[16px] p-[16px] w-[160px] bg-[#242425] rounded-[8px] z-[999] ${
            props.type === 'mobile' && 'right-0 top-[60px]'
          }`}
          onClick={(event) => event.stopPropagation()}
        >
          {items.map((item) => (
            <button
              key={item.title}
              className={`flex items-center relative gap-x-[8px] p-[8px] w-full cursor-pointer ${
                item.active
                  ? 'border border-white'
                  : 'border border-transparent'
              }`}
              onClick={item.onClick}
              aria-label={item.title}
            >
              {item.active && (
                <div className="absolute top-[-10px] right-[-5px] w-[16px] h-[16px] rounded-full bg-white" />
              )}
              {item.icon}{' '}
              <p className="text-[0.75rem] font-medium text-white">
                {item.title}
              </p>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
