import { CloseIcon } from '@icons/CloseIcon'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

export type FloatingInfoPageProps = PropsWithChildren<{
  type?: string
  onClose(): void
}>

FloatingInfoPage.defaultProps = {
  type: 'primary',
  onClose: () => {},
}
export default function FloatingInfoPage(props: FloatingInfoPageProps) {
  const { t } = useTranslation()
  const { onClose, type } = props
  const bg = type === 'primary' ? 'bg-secondary' : 'bg-primary'

  return (
    <div
      className={`relative max-w-xl text-text mx-auto w-[95%] backdrop-blur-sm rounded-t-3xl ${bg}`}
    >
      <div className="flex justify-end pr-4 pb-2">
        <button
          onClick={onClose}
          className="p-3 text-text text-12"
          title={t('close')}
        >
          <CloseIcon />
        </button>
      </div>
      <div className={clsx('overflow-hidden')}>{props.children}</div>
    </div>
  )
}
