import React from 'react'
import { IconProps } from 'Types'
export function LikeIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="1.2em"
      height="1.2em"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8382 2.81007C20.3274 2.29907 19.721 1.89371 19.0535 1.61715C18.3861 1.34059 17.6707 1.19824 16.9482 1.19824C16.2257 1.19824 15.5103 1.34059 14.8428 1.61715C14.1754 1.89371 13.5689 2.29907 13.0582 2.81007L11.9982 3.87007L10.9382 2.81007C9.90647 1.77838 8.5072 1.19878 7.04817 1.19878C5.58913 1.19878 4.18986 1.77838 3.15817 2.81007C2.12647 3.84176 1.54688 5.24103 1.54688 6.70007C1.54687 8.1591 2.12647 9.55838 3.15817 10.5901L4.21817 11.6501L11.9982 19.4301L19.7782 11.6501L20.8382 10.5901C21.3492 10.0793 21.7545 9.47288 22.0311 8.80542C22.3076 8.13796 22.45 7.42256 22.45 6.70007C22.45 5.97758 22.3076 5.26217 22.0311 4.59471C21.7545 3.92725 21.3492 3.32082 20.8382 2.81007V2.81007Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
