import React from 'react'
import { useTranslation } from 'react-i18next'
import { GearIcon } from '../../icons/GearIcon'
import { bgURL } from '../../utils/FormatUtil'
import bg from './assets/bg.png'
import logo from './assets/logo.png'

export type WallProps = {
  title?: string
  text?: string
  image?: string
  msg_powered?: boolean
  videoError?: boolean
}

export function Wall(props: WallProps) {
  const { t } = useTranslation()
  return (
    <>
      {props.videoError && (
        <div
          role="img"
          aria-label={t('video_not_available')}
          className="h-[100%] w-[100%] bg-center bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url("${props.image}")`,
          }}
        >
          <div className="w-full h-full flex flex-col items-center justify-center space-y-2 sm:space-y-3">
            <GearIcon />
            <div className="flex flex-col w-full h-auto items-center justify-center space-y-1 sm:space-y-3">
              <span className="text-16 sm:text-18 text-white font-bold leading-4 font-roboto">
                {t('wait')}
              </span>
              <span className="text-16 sm:text-18 text-white font-regular leading-4 font-roboto">
                {t('back_soon')}
              </span>
            </div>
          </div>
        </div>
      )}
      {!props.videoError && !props.image && (
        <div
          style={{
            backgroundImage: bgURL(bg),
            backgroundSize: '280px',
            backgroundPosition: 'calc(50% + 120px) -40px',
          }}
          className="bg-black text-white mimo-container flex flex-col bg-no-repeat absolute left-0 top-0 z-1-video"
        >
          <div className="space-y-4 text-center flex-1 m-4 pt-24">
            <div className="text-48 font-barlow-cond uppercase">
              {t(props.title || '')}
            </div>
            <div className="text-14">{t(props.text || '')}</div>
          </div>
          {props.msg_powered && (
            <div className="text-center flex flex-row items-center justify-center">
              <span className="text-12 pt-1">{t('powered_by')}</span>&nbsp;
              <a href="https://mimo.com.br/" target="_blank" rel="noreferrer">
                <img
                  alt="mimo"
                  className="h-7 w-auto my-4 mx-2 inline-block"
                  src={logo}
                />
              </a>
            </div>
          )}
        </div>
      )}
      {!props.videoError && props.image && (
        <div
          role="img"
          aria-label={t('live_cover')}
          className="h-[100%] w-[100%] bg-center bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url("${props.image}")`,
          }}
        ></div>
      )}
    </>
  )
}
