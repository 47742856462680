import React from 'react'
import { IconProps } from 'Types'
export function ArrowUpIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 10L7 4L1 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
