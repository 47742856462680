import 'firebase/database'
import { useFirebase, useObjectVal } from 'hooks/useFirebase'
import { SettingsProviderContext } from 'providers/SettingsProviders'
import React, { PropsWithChildren, useMemo } from 'react'
import { Settings, SettingsFirebaseConfig } from 'Types'

export function SettingsFirebaseProvider({
  config,
  children,
}: PropsWithChildren<{
  config: SettingsFirebaseConfig
}>) {
  const firebase = useFirebase('settings', config.meta)

  const [settings] = useObjectVal<Settings>(firebase?.database().ref())

  const contextValue = useMemo(() => {
    return {
      enabled: true,
      type: config.type,
      settings,
    }
  }, [config.type, settings])

  return (
    <SettingsProviderContext.Provider value={contextValue}>
      {settings && children}
    </SettingsProviderContext.Provider>
  )
}
