/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Currency from '@components/currency/Currency'
import QuantityInput from '@components/quantityInput/QuantityInput'
import { AddToCartIcon } from '@icons/AddToCartIcon'
import { TrashIcon } from '@icons/TrashIcon'
import { useSettings } from '@providers/SettingsProviders'
import clsx from 'clsx'
import React, { MouseEvent, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

export type CartProductCardProps = PropsWithChildren<{
  price: number
  priceCompare?: number
  title: string
  variantTitle?: string
  image?: string
  className?: string
  onClick?(): void
  errorMessage?: string
  type?: string
}>

export default function CartProductCard(props: CartProductCardProps) {
  const {
    image,
    price,
    errorMessage,
    title,
    variantTitle,
    className,
    children,
    onClick,
    priceCompare,
  } = props

  const { settings } = useSettings()
  const { t } = useTranslation()

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      onClick?.()
    }
  }

  return (
    <div
      onClick={onClick}
      className={clsx(
        'bg-secondary text-text rounded-2xl',
        className,
        !!onClick && 'cursor-pointer'
      )}
    >
      <div className="p-2 space-x-2 flex items-center">
        {/* image */}
        <img
          className="w-20 h-20 rounded-xl"
          src={
            image ||
            import.meta.env.VITE_S3_BUCKET_ASSETS + '/product_image/no-product-img.png'
          }
          alt={t('product_image')}
        />
        {/* texts */}
        <div className="flex-1 flex items-center">
          <div
            onKeyDown={handleKeyDown}
            onClick={(e) => {
              onClick?.()
              e.stopPropagation()
            }}
            {...(props.type !== 'cart' ? { role: 'button', tabIndex: 0 } : {})}
          >
            <div className="font-semibold text-16">{title}</div>

            <div className="text-subtext text-14">{variantTitle}</div>

            {errorMessage && errorMessage !== 'false' && (
              <div className="text-14 text-primary">{errorMessage}</div>
            )}
            {!!settings?.uiSettings.showProductPrice && !errorMessage && (
              <div className="flex items-center space-x-1">
                <Currency value={price} className="text-16" />

                {!!(priceCompare && priceCompare > price) && (
                  <div aria-hidden className="text-subtext line-through">
                    <Currency value={priceCompare} className="text-12" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* options */}
        <div className="self-stretch flex">{children}</div>
      </div>
    </div>
  )
}

CartProductCard.options = {
  AddToCartOption,
  EditCartOption,
}

// Add to cart options
type AddToCartOptionProps = {
  hideQuestion?: boolean
  onAddClick(e: MouseEvent): void
  onInfoClick(e: MouseEvent): void
  title?: string
}

function AddToCartOption(props: AddToCartOptionProps) {
  const { onAddClick, onInfoClick, hideQuestion, title } = props
  const { t } = useTranslation()
  const { settings } = useSettings()

  return (
    <div className="text-text flex flex-1 justify-center items-center flex-col text-20 space-y-4">
      {!hideQuestion && (
        <button
          aria-label={t('ask')}
          className="p-1 text-10"
          onClick={onInfoClick}
        >
          {t('ask')}
        </button>
      )}

      {!!settings?.uiSettings.showAddToCartButton && (
        <div className="p-1" onClick={onAddClick}>
          <AddToCartIcon />
        </div>
      )}
    </div>
  )
}

// Remove from cart / edit amount
type EditCartOptionProps = {
  onChangeQuantity(v: number): void
  onRemoveClick(): void
  value: number
  title?: string
}

function EditCartOption(props: EditCartOptionProps) {
  const { onChangeQuantity, onRemoveClick, value, title } = props

  const { t } = useTranslation()

  return (
    <div className="flex flex-1 justify-between items-end flex-col text-24">
      <button
        aria-label={t('remove_at_cart') + title}
        className="p-1"
        onClick={onRemoveClick}
      >
        <TrashIcon />
      </button>
      <div className="text-16">
        <QuantityInput value={value} onChange={onChangeQuantity} />
      </div>
    </div>
  )
}
