import { CartItem } from '../store/checkoutReducer'

export function getCartCount(cart: CartItem[]) {
  return cart.reduce((acc, next) => {
    return acc + next.quantity
  }, 0)
}

export function getCartTotal(cart: CartItem[]) {
  return cart.reduce((acc, next) => {
    return acc + next.quantity * next.variant.price
  }, 0)
}

export function getCartItemsForTracking(cart: CartItem[], currency: string) {
  return cart.map((next) => {
    return {
      item_id: next.variant.sku,
      item_name: next.product.title,
      currency: currency,
      price: next.variant.price,
      quantity: next.quantity,
    }
  })
}
