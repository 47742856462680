import { usePopup } from '@components/popup/popup'
import { PlayWhiteIcon } from '@icons/PlayWhiteIcon'
import { MimoPlayer } from '@mimo-live-sales/mimo-player'
import { useConfig } from '@providers/ConfigProvider'
import { useSubscription } from 'observable-hooks'
import { useSettings } from 'providers/SettingsProviders'
import { useVideo } from 'providers/VideoProvider'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PauseWhiteIcon } from '../../icons/PauseWhiteIcon'

export default function VideoPlayer() {
  const { t } = useTranslation()
  const video = useVideo()
  const [showPopup] = usePopup()
  const { settings } = useSettings()
  const config = useConfig()

  const player = useRef<any>()
  const { setMute, setPlay } = video

  const [firstPlay, setFirstPlay] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)

  const [played, setPlayed] = useState(0)
  const [touch, setTouch] = useState(false)

  const videoUrl =
    settings?.live.status === 'ended' && config?.recorded_live
      ? import.meta.env.VITE_CLOUNDFRONT_IVS + config.video_url
      : settings?.stream.meta.url ?? ''

  const isRecordedLive =
    settings?.live.status === 'ended' && Boolean(config?.recorded_live)

  useSubscription(video.intentMuteActions$, (v) => {
    if (player) {
      player.current.mute(v.payload)
      setMute(v.payload)
      setFirstPlay(true)
    }
  })

  useEffect(() => {
    const controller = new AbortController()
    if (firstPlay) showFirstPopup()
    return () => controller.abort()
  }, [firstPlay])

  const play = useCallback(() => {
    if (player) {
      player.current.play()
      if (video.playing) handleButtonClicked()
      if (!video.muted) showFirstPopup()
    }
  }, [player])

  const pause = useCallback(() => {
    if (player) player.current.pause()
  }, [player])

  const showFirstPopup = useCallback(() => {
    let timer: any
    if (video.muted && !firstPlay) {
      setFirstPlay(true)
      timer = setTimeout(() => {
        showPopup(t('sound_deactivated'))
      }, 10000)
    } else clearTimeout(timer)
  }, [firstPlay, video, showPopup])

  const handleButtonClicked = () => {
    setDisable(true)
    setTimeout(() => setDisable(false), 10000)
  }

  function onTimedMetadata(value: any) {
    if (value.hot) {
      video.setHotProduct(value.hot)
    }

    if (value.invisible) {
      video.setInvisibleProduct(value.invisible)
    }
  }

  function onProgress(value: any) {
    if (parseFloat(value.playedSeconds) == 0) {
      video.setHotProduct([])
      video.setInvisibleProduct([])
    }

    setPlayed(parseFloat(value.played))
    video.setProgress(value)
  }

  function onPlay() {
    setPlay(true)
  }

  function onPause() {
    setPlay(false)
  }

  function seekChange(e: any) {
    player.current.seekTo(e.target.value)
    setPlayed(parseFloat(e.target.value))
  }

  function onError(e: string) {
    if (e === 'no error') video.setError(false)
    else video.setError(true)
  }

  const onTouch = useCallback(() => {
    setTouch(true)
    setTimeout(() => {
      setTouch(false)
    }, 2000)
  }, [touch])

  return (
    <div
      data-testid="VideoPlayer"
      className="bg-black mimo-container"
      onTouchStart={onTouch}
    >
      {/* Don't use padding or border on the following div, it will affect the video aspect calc */}
      <div className="mx-auto max-w-[700px] overflow-hidden mimo-container">
        {!video.playing && (
          <button
            className="cursor-pointer text-[120px] text-primary z-[25] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 filter drop-shadow"
            onClick={play}
            disabled={disable}
            aria-label={t('play')}
          >
            <PlayWhiteIcon />
          </button>
        )}

        {video.playing && showFirstPopup()}

        {isRecordedLive && (
          <div className="absolute left-0 z-[999] w-full p-3">
            <input
              className="w-full appearance-none bg-immutableWhite-bgop bg-opacity-75 rounded-full h-2 accent-white"
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={played}
              onChange={seekChange}
              aria-label={t('progress_bar')}
            />
          </div>
        )}
        {isRecordedLive && video.playing && (
          <button
            className={`cursor-pointer text-[120px] text-primary z-[25] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 filter drop-shadow opacity-0 hover:opacity-100 ${
              touch ? 'opacity-100' : 'opacity-0'
            } z-[999]`}
            onClick={pause}
            aria-label={t('pause')}
          >
            <PauseWhiteIcon />
          </button>
        )}

        <MimoPlayer
          ref={player}
          loop
          muted={video.muted}
          autoplay={true}
          tech={settings?.stream.type}
          src={videoUrl}
          forceCover={settings?.video?.forceCover}
          forcePortrait={settings?.video?.forcePortrait}
          onTimedMetadata={(res: any) => onTimedMetadata(JSON.parse(res))}
          onPlay={() => onPlay()}
          onPause={() => onPause()}
          onProgress={(res: any) => onProgress(res)}
          onError={(e: string) => onError(e)}
        />
      </div>
    </div>
  )
}
