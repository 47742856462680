import { ChatContext, ChatProviderContext } from 'providers/ChatProviders'
import React, { PropsWithChildren } from 'react'
import { of } from 'rxjs'
import { ChatConfig } from 'Types'

export const disabledContext: ChatContext = {
  enabled: false,
  type: 'disabled',
  messages: [],
  visible: false,
  isSendingMessage: false,
  isSendingLike: false,
  like() {},
  likesDiff$: of(),

  toggleVisibility: () => {
    throw new Error('Chat is disabled')
  },
  sendMessage: () => {
    throw new Error('Chat is disabled')
  },
  setUser: () => {
    throw new Error('Chat is disabled')
  },
}

export function ChatDisabledProvider({
  children,
}: PropsWithChildren<{
  config: ChatConfig
}>) {
  return (
    <ChatProviderContext.Provider value={disabledContext}>
      {children}
    </ChatProviderContext.Provider>
  )
}
