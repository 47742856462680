import React from 'react'
import { EyeIcon } from '../../icons/EyeIcon'

export type LiveCountProps = {
  total?: number
}

export default function LiveCount({ total }: LiveCountProps) {
  if (!total || total == 0) {
    return null
  }

  return (
    <div className="inline-flex items-center justify-center flex-row bg-immutableWhite-bgop bg-opacity-50 text-immutableBlack text-12 space-x-1 px-3 py-1 rounded-[24px] w-full">
      <span className="text-16">
        <EyeIcon />
      </span>
      <span className="pr-1">{total}</span>
    </div>
  )
}
