import { ArrowDownIcon } from '@icons/ArrowDownIcon'
import { CloseIcon } from '@icons/CloseIcon'
import clsx from 'clsx'
import React, { PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export type FloatingPageProps = PropsWithChildren<{
  type?: string
  title: ReactNode
  onClose(): void
  asModal: boolean
}>

FloatingPage.defaultProps = {
  type: 'primary',
  title: 'FLOATING PAGE TITLE',
  onClose: () => {},
  asModal: false,
}
export default function FloatingPage(props: FloatingPageProps) {
  const { title, onClose, asModal, type } = props
  const { t } = useTranslation()
  const bg = type === 'primary' ? 'bg-secondary-bgop' : 'bg-primary-bgop'
  return (
    <div
      className={clsx(
        'max-w-xl mx-auto',
        bg,
        'bg-opacity-90 text-text backdrop-blur-sm',
        asModal ? 'rounded-3xl' : 'rounded-t-3xl'
      )}
    >
      <div className="flex justify-between items-center  p-4 pb-2">
        <div className="font-barlow-cond font-medium text-20 pl-2 uppercase">
          {title}
        </div>
        <button
          title={t('close')}
          onClick={onClose}
          className="px-2 py-3 text-text text-12 mr-2"
        >
          {asModal ? <CloseIcon /> : <ArrowDownIcon />}
        </button>
      </div>
      {props.children}
    </div>
  )
}
