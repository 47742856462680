/* eslint-disable react/prop-types */
import { Loading } from '@components/loading/Loading'
import { ChatONIcon } from '@icons/ChatONIcon'
import clsx from 'clsx'
import React, { ChangeEvent, FormEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccessibility } from '../../providers/AccessibilityProvider'

export type ChatInputProps = {
  onSubmit?(value: string): void
  loading: boolean
  value: string
  setValue(v: string): void
}
export default React.forwardRef<HTMLInputElement, ChatInputProps>(
  function ChatInput(props, ref) {
    const { onSubmit, loading, value, setValue } = props
    const { t } = useTranslation()
    const { accessibility } = useAccessibility()

    const onSubmitHandler = useCallback(
      (e: FormEvent) => {
        e.preventDefault()
        if (loading) {
          return
        }
        if (onSubmit && value.length > 0) {
          onSubmit(value)
        }
        setValue('')
      },
      [onSubmit, setValue, value, loading]
    )

    const onInputChange = useCallback(
      (e: ChangeEvent) => {
        setValue((e.target as HTMLInputElement).value)
      },
      [setValue]
    )

    return (
      <div>
        <form
          onSubmit={onSubmitHandler}
          className={`rounded-[40px] ${
            value.length > 280 ? 'h-16' : 'h-14'
          } border-[1px] border-transparent bg-immutableWhite px-2 space-x-2 flex items-center`}
        >
          <div className="w-full h-auto flex flex-col justify-center">
            <input
              placeholder={t('send_a_question')}
              ref={ref}
              value={value}
              onChange={onInputChange}
              className={clsx(
                'bg-transparent py-2 text-immutableBlack flex-1 text-14 pl-2 ml-1 w-full',
                {
                  'cursor-wait': loading,
                },
                value.length > 280 &&
                  'focus:outline-none  focus:ring-1 focus:ring-error focus:rounded-[3px]',
                accessibility.highContrast && '!placeholder-text'
              )}
              type="text"
            />
            <span
              className={clsx(
                'text-10 text-error ml-5 mt-1',
                value.length > 280 ? 'block' : 'hidden'
              )}
            >{`${t('limit_reached')}: ${value.length}/280`}</span>
          </div>
          <button
            title={t('send_question')}
            type="submit"
            className={`text-18 text-accent flex items-center ${
              value.length > 280 && 'cursor-not-allowed'
            }`}
            disabled={value.length > 280}
          >
            {!loading && (
              <div className="p-2 text-accent">
                <ChatONIcon />
              </div>
            )}
            {loading && <Loading className="bg-transparent" />}
          </button>
        </form>
      </div>
    )
  }
)
