/* a11y is disabled because the click event is only used for the admin panel */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from 'clsx'
import React from 'react'
import { Message } from 'Types'

export type ChatMessageProps = {
  message: Message
  onClick?(): void
  className?: string
  mobile?: boolean
}
export default function ChatMessage(props: ChatMessageProps) {
  const { message, onClick = () => {}, className, mobile } = props

  const { isCompany, sender, text, replyTo } = message

  return (
    <div onClick={onClick}>
      <div
        className={clsx(
          'inline-block relative rounded-2xl break-words text-12 p-2 text-chatText',
          className,
          isCompany && `bg-chatAccent-wo`,
          !isCompany && `bg-chatPrimary-wo`
        )}
      >
        {replyTo && (
          <div className="mb-2">
            <ChatMessage message={replyTo} mobile={mobile} className={className} />
          </div>
        )}
        <strong>{sender}:</strong> {text}
      </div>
    </div>
  )
}
