import clsx from 'clsx'
import React, { LegacyRef, PropsWithChildren } from 'react'

export type ChatMaskProps = PropsWithChildren<{
  //
  className?: string
}>
function ChatMask(props: ChatMaskProps, ref: LegacyRef<HTMLDivElement>) {
  const { children, className } = props

  return (
    <div ref={ref} className={clsx('scrollbar overflow-auto', className)}>
      {children}
    </div>
  )
}

export default React.forwardRef<HTMLDivElement, ChatMaskProps>(ChatMask)
