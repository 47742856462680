import Currency from '@components/currency/Currency'
import FloatingPage from '@components/floatingPage/FloatingPage'
import { useConfig } from '@providers/ConfigProvider'
import { useSettings } from '@providers/SettingsProviders'
import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useMimoRouter } from 'router/MimoRouter'
import { PATHS } from 'router/Paths'
import { PageHandlerProps, Product, ProductVariant } from '../../Types'
import Button from '../../components/button/Button'
import CartProductCard from '../../components/cartProductCard/CartProductCard'
import { CartIcon } from '../../icons/CartIcon'
import { useAccessibility } from '../../providers/AccessibilityProvider'
import { StockInfo, useCheckout } from '../../providers/CheckoutProvider'
import { useTracking } from '../../providers/TrackingProvider'
import { CartItem } from '../../store/checkoutReducer'
import { getCartItemsForTracking, getCartTotal } from '../../utils/CartUtils'
import { pluralize } from '../../utils/FormatUtil'
import { getProductThumb, getVariantTitle } from '../../utils/ProductUtil'

export function CartPage({ type }: PageHandlerProps) {
  const { accessibility } = useAccessibility()
  const modalRef = useRef<HTMLDivElement>(null)
  const { go } = useMimoRouter()

  const { t } = useTranslation()

  const { cart, cartCount, editCart, finishCart, getStockInfo, isFinishing } =
    useCheckout()

  const onClose = useCallback(() => {
    go(PATHS.home, {})
  }, [go])

  const { settings } = useSettings()

  const tracking = useTracking()
  const currency = useConfig().settings?.currency?.code

  const cartTotal = useMemo(() => {
    return getCartTotal(cart)
  }, [cart])

  const cartTrackingItems = useMemo(() => {
    return getCartItemsForTracking(cart, currency)
  }, [cart, currency])

  const isCartEmpty = cart.length === 0

  const router = useMimoRouter()

  const goToProducts = useCallback(() => {
    router.go(PATHS.products)
  }, [router])

  const cartWithStock = useMemo(() => {
    return cart.map((v) => {
      return {
        ...v,
        stock: getStockInfo(v.variant, v.quantity),
      }
    })
  }, [cart, getStockInfo])

  const hasUnavailable = useMemo(() => {
    return !!cartWithStock.find((v) => {
      return !v.stock.available || !v.stock.quantityAvailable
    })
  }, [cartWithStock])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (modalRef.current) modalRef.current.focus()
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div
      ref={modalRef}
      tabIndex={-1}
      className="absolute bottom-0 w-full left-0 z-3-page"
      role="dialog"
      aria-modal
    >
      <FloatingPage
        type={type}
        title={
          <div className="flex items-center space-x-1">
            <span>{t('shopping_cart').toLocaleUpperCase()}</span>
            <span className="text-14">
              {pluralize(
                cartCount,
                {
                  0: ' ',
                  1: ` (1 ${t('item')})`,
                },
                ` (${cartCount} ${t('items')})`
              )}
            </span>
          </div>
        }
        onClose={onClose}
      >
        <div className=" px-4 pb-4 ">
          <div className="space-y-1 overflow-auto max-h-64 scrollbar">
            {isCartEmpty && (
              <div>
                <div className="text-text text-center my-12">
                  <CartIcon className="text-48 inline-block" />

                  <div className="my-8">{t('your_shopping_cart_is_empty')}</div>
                </div>
              </div>
            )}

            {cartWithStock.map((item) => {
              return (
                <CartItemRender
                  editCart={editCart}
                  item={item}
                  stockInfo={item.stock}
                  tracking={tracking}
                  currency={currency}
                  key={item.variant.id}
                />
              )
            })}
          </div>
          {!isCartEmpty && (
            <div>
              {!hasUnavailable && (
                <>
                  {settings?.uiSettings.showProductPrice && (
                    <div className="flex my-4 justify-between font-barlow-cond text-20 font-semibold">
                      <div>{t('total').toUpperCase()}</div>
                      <Currency
                        value={cartTotal}
                        className="text-24"
                      ></Currency>
                    </div>
                  )}
                  <Button
                    loading={isFinishing}
                    onClick={() => {
                      finishCart()
                      tracking.track({
                        event: 'begin_checkout',
                        currency: currency,
                        value: cartTotal,
                        items: cartTrackingItems,
                      })
                    }}
                    className="block w-full"
                    buttonType="button"
                    highContrast={accessibility.highContrast}
                  >
                    {t('checkout_now').toUpperCase()}
                  </Button>
                </>
              )}
              {hasUnavailable && (
                <div className="font-barlow-cond mt-4 rounded-md text-16 font-semibold text-center p-4 bg-primary space-y-2">
                  {t('you_have_unavailable_products')}
                </div>
              )}
            </div>
          )}

          {isCartEmpty && (
            <div>
              <Button
                onClick={goToProducts}
                className="block w-full"
                buttonType="button"
                highContrast={accessibility.highContrast}
              >
                {t('see_products').toUpperCase()}
              </Button>
            </div>
          )}
        </div>
      </FloatingPage>
    </div>
  )
}

function CartItemRender({
  item,
  stockInfo,
  editCart,
  tracking,
  currency,
}: {
  item: CartItem
  stockInfo: StockInfo
  editCart: (
    product: Product,
    variant: ProductVariant,
    quantity: number
  ) => void
  tracking: any
  currency: string
}) {
  const errorMessage =
    (stockInfo.available &&
      !stockInfo.quantityAvailable &&
      t('unavailable_for_selected_quantity')) ||
    (!stockInfo.available && t('product_unavailable'))

  return (
    <div className="relative" key={item.variant.id}>
      <CartProductCard
        title={item.product.title}
        variantTitle={getVariantTitle(item.product, item.variant)}
        errorMessage={errorMessage?.toString() || undefined}
        price={item.variant.price || 0}
        priceCompare={item.variant.priceCompare || 0}
        image={getProductThumb(item.product, item.variant)}
        type="cart"
      >
        <CartProductCard.options.EditCartOption
          value={item.quantity}
          onChangeQuantity={(quantity) => {
            editCart(item.product, item.variant, quantity)
          }}
          onRemoveClick={() => {
            tracking.track({
              event: 'remove_from_cart',
              currency: currency,
              value: (item.variant.price || 0) * item.quantity,
              items: [
                {
                  item_id: item.variant.sku,
                  item_name: item.product.title,
                  currency: currency,
                  price: item.variant.price,
                  quantity: item.quantity,
                },
              ],
            })
            editCart(item.product, item.variant, 0)
          }}
          title={item.product.title}
        />
      </CartProductCard>
    </div>
  )
}

export function CartPageHandler({ type }: PageHandlerProps) {
  const route = useMimoRouter()
  return (
    <TransitionGroup data-testid="CartPageHandler">
      {route.meta?.showCart && (
        <CSSTransition timeout={500} classNames="animate-from-bottom">
          <CartPage type={type} />
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
