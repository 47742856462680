import { DEFAULT_OPTIONS } from 'consts'
import { MimoPlayerOptions } from 'Types'

export function mergeOptions(
  options1: MimoPlayerOptions,
  options2: MimoPlayerOptions
): MimoPlayerOptions {
  return { ...DEFAULT_OPTIONS, ...normalize(options1), ...normalize(options2) }
}

function normalize(config: MimoPlayerOptions) {
  return Object.keys(config).reduce((acc, _key: string) => {
    const key = _key as keyof MimoPlayerOptions
    if (config[key] !== undefined) {
      acc[key] = config[key] as any
    }
    return acc
  }, {} as MimoPlayerOptions)
}
