import Currency from '@components/currency/Currency'
import { useSettings } from '@providers/SettingsProviders'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useConfig } from '../../providers/ConfigProvider'
import { formatMoney } from '../../utils/FormatUtil'

export type HotProductCardProps = {
  price: number
  image?: string
  className?: string
  onClick(): void
  title?: string
}

const defaultProps = {
  onClick: () => {},
}

export default function HotProductCard(props: HotProductCardProps) {
  const { t } = useTranslation()
  const { image, price, onClick } = props
  const { settings } = useSettings()
  const config = useConfig()

  const formattedPrice = formatMoney(
    price,
    config.settings?.currency?.locale || 'pt-BR',
    config.settings?.currency?.code || 'BRL'
  )
  return (
    <button
      onClick={onClick}
      className="rounded-lg bg-white block w-full  overflow-hidden shadow"
      title={t('view_product')}
      aria-label={`${props.title || ''} ${' '} ${formattedPrice}`}
    >
      <div
        style={
          image
            ? { backgroundImage: `url("${image}")` }
            : { backgroundColor: 'white' }
        }
        className="aspect-w-1 aspect-h-1 bg-contain bg-no-repeat bg-center"
      />

      {!!settings?.uiSettings.showProductPrice && (
        <Currency
          value={price ? price : 0}
          className="text-14 py-1 text-center overflow-ellipsis overflow-hidden px-[1px]"
        />
      )}
    </button>
  )
}

HotProductCard.defaultProps = defaultProps
