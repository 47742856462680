import React from 'react'

export function LikeCountIncrease({ type }: { type: string }) {
  const color: any = {
    light: {
      fill: '#fff0',
      values: '#fff0;#fff0;#fff9;#ffffff9c;#fff0;#fff0',
    },
    dark: {
      fill: '#6669',
      values: '#6660;#6660;#6669;#6666669c;#6660;#6660',
    },
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 300"
      overflow="hidden"
      preserveAspectRatio="xMidYMid meet"
      id="eaf-12c92-0"
    >
      <defs>
        <clipPath id="eaf-12c92-1">
          <rect x="0" y="0" width="80" height="300"></rect>
        </clipPath>
      </defs>
      <g clipPath="url('#eaf-12c92-1')" style={{ isolation: 'isolate' }}>
        <g>
          <g transform="translate(5.3004 0.1245)">
            <path
              fill={color[type]?.fill}
              d="m17.612 2.4145c-0.8796 -0.8969 -2.1054 -1.4145 -3.3497 -1.4145c-1.2443 0 -2.4701 0.5176 -3.3497 1.4145l-0.9128 0.9302l-0.9128 -0.9302c-0.8884 -0.9054 -2.0934 -1.414 -3.3497 -1.414c-1.2564 0 -2.4613 0.5086 -3.3497 1.414c-0.8884 0.9054 -1.3875 2.1334 -1.3875 3.4138c0 4.9846 5.5023 8.4268 8.9998 11.1717c3.498 -2.7454 9.0002 -6.1863 9.0002 -11.1717c0 -1.2681 -0.5079 -2.5173 -1.388 -3.4138z"
              transform="translate(3.2623 314.4906)"
            >
              <animate
                keyTimes="0;0.1;0.3;0.3767;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="fill"
                values={color[type]?.values}
              ></animate>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="0 0"
                to="0 0"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
              ></animateTransform>
              <animateMotion
                keyTimes="0;0.2;0.4;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="transform"
                keyPoints="0;0.4695;1;1"
                path="m13.2623 323.4906q-19.6795 -84.234 11.7893 -161.2911q28.4741 -55.6459 8.0887 -182.9345"
                additive="sum"
              ></animateMotion>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="-10 -9"
                to="-10 -9"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
                additive="sum"
              ></animateTransform>
            </path>
          </g>
          <g transform="translate(24.2102 0)">
            <path
              fill={color[type]?.fill}
              d="m17.612 2.4145c-0.8796 -0.8969 -2.1054 -1.4145 -3.3497 -1.4145c-1.2443 0 -2.4701 0.5176 -3.3497 1.4145l-0.9128 0.9302l-0.9128 -0.9302c-0.8884 -0.9054 -2.0934 -1.414 -3.3497 -1.414c-1.2564 0 -2.4613 0.5086 -3.3497 1.414c-0.8884 0.9054 -1.3875 2.1334 -1.3875 3.4138c0 4.9846 5.5023 8.4268 8.9998 11.1717c3.498 -2.7454 9.0002 -6.1863 9.0002 -11.1717c0 -1.2681 -0.5079 -2.5173 -1.388 -3.4138z"
              transform="translate(3.2623 314.4906)"
            >
              <animate
                keyTimes="0;0.2033;0.3033;0.5033;0.58;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="fill"
                values={color[type]?.values}
              ></animate>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="0 0"
                to="0 0"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
              ></animateTransform>
              <animateMotion
                keyTimes="0;0.2033;0.4033;0.6033;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="transform"
                keyPoints="0;0;0.4695;1;1"
                path="m13.2623 323.4906q-19.6795 -84.234 11.7893 -161.2911q28.4741 -55.6459 8.0887 -182.9345"
                additive="sum"
              ></animateMotion>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="-10 -9"
                to="-10 -9"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
                additive="sum"
              ></animateTransform>
            </path>
          </g>
          <g transform="translate(40.1483 0)">
            <path
              fill={color[type]?.fill}
              d="m17.612 2.4145c-0.8796 -0.8969 -2.1054 -1.4145 -3.3497 -1.4145c-1.2443 0 -2.4701 0.5176 -3.3497 1.4145l-0.9128 0.9302l-0.9128 -0.9302c-0.8884 -0.9054 -2.0934 -1.414 -3.3497 -1.414c-1.2564 0 -2.4613 0.5086 -3.3497 1.414c-0.8884 0.9054 -1.3875 2.1334 -1.3875 3.4138c0 4.9846 5.5023 8.4268 8.9998 11.1717c3.498 -2.7454 9.0002 -6.1863 9.0002 -11.1717c0 -1.2681 -0.5079 -2.5173 -1.388 -3.4138z"
              transform="translate(3.2623 314.4906)"
            >
              <animate
                keyTimes="0;0.4;0.5;0.7;0.7767;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="fill"
                values={color[type]?.values}
              ></animate>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="0 0"
                to="0 0"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
              ></animateTransform>
              <animateMotion
                keyTimes="0;0.4;0.6;0.8;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="transform"
                keyPoints="0;0;0.4686;1;1"
                path="m13.2623 323.4906q-19.2041 -59.899 0.9113 -161.2911q25.678 -100.6115 8.0887 -182.9345"
                additive="sum"
              ></animateMotion>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="-10 -9"
                to="-10 -9"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
                additive="sum"
              ></animateTransform>
            </path>
          </g>
          <g transform="translate(5.0152 0.273)">
            <path
              fill={color[type]?.fill}
              d="m17.612 2.4145c-0.8796 -0.8969 -2.1054 -1.4145 -3.3497 -1.4145c-1.2443 0 -2.4701 0.5176 -3.3497 1.4145l-0.9128 0.9302l-0.9128 -0.9302c-0.8884 -0.9054 -2.0934 -1.414 -3.3497 -1.414c-1.2564 0 -2.4613 0.5086 -3.3497 1.414c-0.8884 0.9054 -1.3875 2.1334 -1.3875 3.4138c0 4.9846 5.5023 8.4268 8.9998 11.1717c3.498 -2.7454 9.0002 -6.1863 9.0002 -11.1717c0 -1.2681 -0.5079 -2.5173 -1.388 -3.4138z"
              transform="translate(3.2623 314.4906)"
            >
              <animate
                keyTimes="0;0.5;0.6;0.8;0.8767;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="fill"
                values={color[type]?.values}
              ></animate>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="0 0"
                to="0 0"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
              ></animateTransform>
              <animateMotion
                keyTimes="0;0.5;0.7;0.9;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="transform"
                keyPoints="0;0;0.4686;1;1"
                path="m13.2623 323.4906q-19.2041 -59.899 0.9113 -161.2911q25.678 -100.6115 8.0887 -182.9345"
                additive="sum"
              ></animateMotion>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="-10 -9"
                to="-10 -9"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
                additive="sum"
              ></animateTransform>
            </path>
          </g>
          <g transform="translate(26.7635 0)">
            <path
              fill={color[type]?.fill}
              d="m17.612 2.4145c-0.8796 -0.8969 -2.1054 -1.4145 -3.3497 -1.4145c-1.2443 0 -2.4701 0.5176 -3.3497 1.4145l-0.9128 0.9302l-0.9128 -0.9302c-0.8884 -0.9054 -2.0934 -1.414 -3.3497 -1.414c-1.2564 0 -2.4613 0.5086 -3.3497 1.414c-0.8884 0.9054 -1.3875 2.1334 -1.3875 3.4138c0 4.9846 5.5023 8.4268 8.9998 11.1717c3.498 -2.7454 9.0002 -6.1863 9.0002 -11.1717c0 -1.2681 -0.5079 -2.5173 -1.388 -3.4138z"
              transform="translate(3.2623 314.4906)"
            >
              <animate
                keyTimes="0;0.1;0.2;0.4;0.4767;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="fill"
                values={color[type]?.values}
              ></animate>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="0 0"
                to="0 0"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
              ></animateTransform>
              <animateMotion
                keyTimes="0;0.1;0.3;0.5;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="transform"
                keyPoints="0;0;0.5015;1;1"
                path="m13.2623 323.4906q27.4953 -73.7811 19.878 -171.5736q-10.1501 -102.9295 0 -172.6521"
                additive="sum"
              ></animateMotion>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="-10 -9"
                to="-10 -9"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
                additive="sum"
              ></animateTransform>
            </path>
          </g>
          <g transform="translate(-0.913 0)">
            <path
              fill={color[type]?.fill}
              d="m17.612 2.4145c-0.8796 -0.8969 -2.1054 -1.4145 -3.3497 -1.4145c-1.2443 0 -2.4701 0.5176 -3.3497 1.4145l-0.9128 0.9302l-0.9128 -0.9302c-0.8884 -0.9054 -2.0934 -1.414 -3.3497 -1.414c-1.2564 0 -2.4613 0.5086 -3.3497 1.414c-0.8884 0.9054 -1.3875 2.1334 -1.3875 3.4138c0 4.9846 5.5023 8.4268 8.9998 11.1717c3.498 -2.7454 9.0002 -6.1863 9.0002 -11.1717c0 -1.2681 -0.5079 -2.5173 -1.388 -3.4138z"
              transform="translate(3.2623 314.4906)"
            >
              <animate
                keyTimes="0;0.2833;0.3833;0.5833;0.66;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="fill"
                values={color[type]?.values}
              ></animate>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="0 0"
                to="0 0"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
              ></animateTransform>
              <animateMotion
                keyTimes="0;0.2833;0.4833;0.6833;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="transform"
                keyPoints="0;0;0.5015;1;1"
                path="m13.2623 323.4906q27.4953 -73.7811 19.878 -171.5736q-10.1501 -102.9295 0 -172.6521"
                additive="sum"
              ></animateMotion>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="-10 -9"
                to="-10 -9"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
                additive="sum"
              ></animateTransform>
            </path>
          </g>
          <g transform="translate(28.878 0.273)">
            <path
              fill={color[type]?.fill}
              d="m17.612 2.4145c-0.8796 -0.8969 -2.1054 -1.4145 -3.3497 -1.4145c-1.2443 0 -2.4701 0.5176 -3.3497 1.4145l-0.9128 0.9302l-0.9128 -0.9302c-0.8884 -0.9054 -2.0934 -1.414 -3.3497 -1.414c-1.2564 0 -2.4613 0.5086 -3.3497 1.414c-0.8884 0.9054 -1.3875 2.1334 -1.3875 3.4138c0 4.9846 5.5023 8.4268 8.9998 11.1717c3.498 -2.7454 9.0002 -6.1863 9.0002 -11.1717c0 -1.2681 -0.5079 -2.5173 -1.388 -3.4138z"
              transform="translate(3.2623 314.4906)"
            >
              <animate
                keyTimes="0;0.4667;0.5667;0.7667;0.8434;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="fill"
                values={color[type]?.values}
              ></animate>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="0 0"
                to="0 0"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
              ></animateTransform>
              <animateMotion
                keyTimes="0;0.4667;0.6667;0.8667;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="transform"
                keyPoints="0;0;0.5015;1;1"
                path="m13.2623 323.4906q27.4953 -73.7811 19.878 -171.5736q-10.1501 -102.9295 0 -172.6521"
                additive="sum"
              ></animateMotion>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="-10 -9"
                to="-10 -9"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
                additive="sum"
              ></animateTransform>
            </path>
          </g>
          <g transform="translate(28.878 0.273)">
            <path
              fill={color[type]?.fill}
              d="m17.612 2.4145c-0.8796 -0.8969 -2.1054 -1.4145 -3.3497 -1.4145c-1.2443 0 -2.4701 0.5176 -3.3497 1.4145l-0.9128 0.9302l-0.9128 -0.9302c-0.8884 -0.9054 -2.0934 -1.414 -3.3497 -1.414c-1.2564 0 -2.4613 0.5086 -3.3497 1.414c-0.8884 0.9054 -1.3875 2.1334 -1.3875 3.4138c0 4.9846 5.5023 8.4268 8.9998 11.1717c3.498 -2.7454 9.0002 -6.1863 9.0002 -11.1717c0 -1.2681 -0.5079 -2.5173 -1.388 -3.4138z"
              transform="translate(3.2623 314.4906)"
            >
              <animate
                keyTimes="0;0.5867;0.6867;0.8867;0.9634;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="fill"
                values={color[type]?.values}
              ></animate>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="0 0"
                to="0 0"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
              ></animateTransform>
              <animateMotion
                keyTimes="0;0.5867;0.7867;0.9867;1"
                calcMode="linear"
                dur="10000ms"
                repeatCount="1"
                attributeName="transform"
                keyPoints="0;0;0.5015;1;1"
                path="m13.2623 323.4906q27.4953 -73.7811 19.878 -171.5736q-10.1501 -102.9295 0 -172.6521"
                additive="sum"
              ></animateMotion>
              <animateTransform
                attributeName="transform"
                type="translate"
                from="-10 -9"
                to="-10 -9"
                calcMode="discrete"
                dur="10000ms"
                repeatCount="1"
                additive="sum"
              ></animateTransform>
            </path>
          </g>
        </g>
      </g>
    </svg>
  )
}
