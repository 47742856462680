import { CloseIcon } from '@icons/CloseIcon'
import { useEmbedParams } from 'providers/EmbedParamsProvider'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export type FatalErrorAlertProps = {
  error?: Error
  title: string
}
export function FatalErrorAlert(props: FatalErrorAlertProps) {
  const { error, title } = props

  const reload = useCallback(() => {
    window.location.reload()
  }, [])

  const params = useEmbedParams()
  const { t } = useTranslation()

  return (
    <div className="flex w-full h-full bg-immutableBlack text-immutableWhite items-center justify-center flex-col p-6 space-y-6">
      <div className="text-[40px] text-text">
        <CloseIcon />
      </div>
      <div className="text-20  font-barlow-cond">{title}</div>
      {params.debug && !!error && (
        <div className="font-customFont text-10">{error.message}</div>
      )}

      <button
        onClick={reload}
        className="text-primary border p-2 font-barlow-cond"
      >
        {t('restart')}
      </button>
    </div>
  )
}
