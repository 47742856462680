import React from 'react'
import { IconProps } from 'Types'

/**
 * fill: #2BB6F2
 */
export function TwitterIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="8" fill="currentColor" />
      <path
        d="M33 12.3724C32.0439 12.7733 31.0167 13.0441 29.9369 13.1666C31.0511 12.5358 31.8846 11.5429 32.282 10.3733C31.2353 10.9616 30.0896 11.3757 28.8949 11.5977C28.0915 10.786 27.0274 10.248 25.8677 10.0672C24.7081 9.88638 23.5178 10.0729 22.4816 10.5978C21.4455 11.1228 20.6215 11.9567 20.1376 12.9701C19.6537 13.9836 19.5369 15.1199 19.8053 16.2026C17.6843 16.1018 15.6094 15.5802 13.7152 14.6715C11.821 13.7628 10.1499 12.4874 8.81036 10.928C8.35233 11.6757 8.08896 12.5425 8.08896 13.4656C8.08845 14.2967 8.30473 15.115 8.71861 15.848C9.1325 16.581 9.73118 17.2059 10.4616 17.6675C9.61452 17.642 8.78617 17.4254 8.04545 17.0358V17.1008C8.04537 18.2664 8.47145 19.3961 9.25142 20.2982C10.0314 21.2004 11.1172 21.8194 12.3246 22.0503C11.5388 22.2515 10.715 22.2811 9.91535 22.137C10.256 23.1399 10.9196 24.0169 11.8132 24.6452C12.7067 25.2735 13.7856 25.6217 14.8987 25.641C13.0091 27.0446 10.6756 27.806 8.27332 27.8026C7.84779 27.8027 7.42262 27.7792 7 27.7322C9.4384 29.2157 12.2769 30.003 15.1758 30C24.9891 30 30.3537 22.3092 30.3537 15.6391C30.3537 15.4224 30.348 15.2036 30.3377 14.9869C31.3812 14.2728 32.2819 13.3886 32.9977 12.3756L33 12.3724Z"
        fill="white"
      />
    </svg>
  )
}
