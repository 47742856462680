import { CustomFont, ThemeColors } from 'Types'
import clsx from 'clsx'
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import theme from '../../tailwind.theme.json'

type Size = 'mob' | 'desk'

const videoAspect = 9 / 16

function hexToRgb(hex: string) {
  let c: any
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(' ')
  }
  throw new Error('Bad Hex')
}

function fitsDesktop(full: DOMRectReadOnly) {
  const videoW = full.height * videoAspect // video width

  const sideBarsWidth = (full.width - videoW) / 2

  return sideBarsWidth > 320
}

function useMaxHeight(ref: React.RefObject<HTMLDivElement>) {
  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new ResizeObserver(([params]) => {
      setHeight(params.contentRect.height)
    })

    observer.observe(ref.current!)

    return () => {
      observer.disconnect()
    }
  }, [height, setHeight, ref])

  return height
}

function useGetSize(ref: React.RefObject<HTMLDivElement>) {
  const [size, setSize] = useState<'mob' | 'desk'>('mob')

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const observer = new ResizeObserver(([params]) => {
      const isDesktop = fitsDesktop(params.contentRect)

      isDesktop && size != 'desk' && setSize('desk')
      !isDesktop && size != 'mob' && setSize('mob')
    })
    observer.observe(ref.current!)

    return () => {
      observer.disconnect()
    }
  }, [size, setSize, ref])

  return size
}

const ThemeContext = React.createContext<{
  size: Size
}>({
  size: 'mob',
})

type ThemeProviderProps = PropsWithChildren<{
  colors?: ThemeColors
  fonts?: CustomFont
}>

function addFont(fonts: CustomFont = {}) {
  for (const weight in fonts) {
    const file = fonts[weight]
    const fileUrl = file.includes('http') ? file : `${import.meta.env.VITE_S3_BUCKET_ASSETS}/${file}`;
    const new_font = new FontFace('customFont', `url(${fileUrl})`, {
      weight: weight,
    })
    new_font
      .load()
      .then(function (loaded_face: any) {
        document.fonts.add(loaded_face)
      })
      .catch(console.error)
  }
}

export default function ThemeProvider(props: ThemeProviderProps) {
  const ref = useRef<HTMLDivElement>(null)

  const size = useGetSize(ref)

  const maxHeight = useMaxHeight(ref)

  const [hasFont, setHasFont] = useState(false)

  const contextValue = useMemo(() => {
    return {
      size,
      maxHeight,
    }
  }, [size, maxHeight])

  if (!hasFont) {
    addFont(props.fonts || {})
    setHasFont(true)
  }

  return (
    <>
      <div
        ref={ref}
        data-testid="ThemeProvider"
        className={clsx(
          `font-customFont text-16 mimo-container bg-primary`,
          contextValue.size
        )}
        style={
          {
            '--color-primary': hexToRgb(
              props.colors?.primary || theme.colors.primary.fallback
            ),
            '--color-secondary': hexToRgb(
              props.colors?.secondary || theme.colors.secondary.fallback
            ),
            '--color-accent': hexToRgb(
              props.colors?.accent || theme.colors.accent.fallback
            ),
            '--color-text': hexToRgb(
              props.colors?.text || theme.colors.text.fallback
            ),
            '--color-subtext': hexToRgb(
              props.colors?.subtext || theme.colors.subtext.fallback
            ),
            '--color-cta-primary': hexToRgb(
              props.colors?.ctaPrimary || theme.colors.ctaPrimary.fallback
            ),
            '--color-cta-text': hexToRgb(
              props.colors?.ctaText || theme.colors.ctaText.fallback
            ),
            '--color-immutable-white': hexToRgb(
              props.colors?.immutableWhite ||
                theme.colors.immutableWhite.fallback
            ),
            '--color-immutable-black': hexToRgb(
              props.colors?.immutableBlack ||
                theme.colors.immutableBlack.fallback
            ),
            '--color-chat-text': hexToRgb(
              props.colors?.chatText || theme.colors.chatText.fallback
            ),
            '--color-chat-primary': hexToRgb(
              props.colors?.chatPrimary || theme.colors.chatPrimary.fallback
            ),
            '--color-chat-accent': hexToRgb(
              props.colors?.chatAccent || theme.colors.chatAccent.fallback
            ),
            '--color-chat-opacity':
              (props.colors?.chatOpacity || theme.colors.chatOpacity.fallback) /
              100,
            '--maxHeight': maxHeight + 'px',
          } as any
        }
      >
        <ThemeContext.Provider value={contextValue}>
          {props.children}
        </ThemeContext.Provider>
      </div>
    </>
  )
}

export function useTheme() {
  return useContext(ThemeContext)
}

ThemeProvider.defaultProps = {}
