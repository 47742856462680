import { Loading } from '@components/loading/Loading'
import clsx from 'clsx'
import React, { PropsWithChildren, useCallback } from 'react'

export type ButtonProps = PropsWithChildren<{
  disabled?: boolean
  loading?: boolean
  title?: string
  onClick?(): void
  type?: 'primary' | 'secondary'
  className?: string
  buttonType?: 'submit' | 'button'
  elemType?: 'button' | 'a'
  elemProps?: JSX.IntrinsicElements['a'] | JSX.IntrinsicElements['button']
  highContrast?: boolean
}>
export default function Button(props: ButtonProps) {
  const {
    children,
    loading,
    buttonType = 'button',
    title,
    className = '',
    disabled = false,
    onClick = () => {},
    type = 'primary',
    elemType = 'button',
    elemProps = {},
  } = props

  const onClickHandler = useCallback(() => {
    if (!disabled && !loading) {
      onClick()
    }
  }, [onClick, disabled, loading])

  const Type = elemType
  return (
    <Type
      {...(elemProps as any)}
      type={buttonType}
      onClick={onClickHandler}
      disabled={disabled}
      title={title || children?.toString()}
      className={clsx(
        'font-barlow-cond font-semibold uppercase rounded-full py-1 min-h-[48px] px-2 text-center relative text-16 flex items-center justify-center w-full',
        type == 'primary' && 'bg-ctaPrimary text-ctaText',
        type == 'secondary' && 'bg-immutableWhite text-immutableBlack',
        props.highContrast && 'border border-white',
        disabled && 'opacity-30 cursor-not-allowed',
        className
      )}
    >
      {loading && (
        <div className="inset-0 absolute cursor-wait flex items-center justify-center bg-primary-bgop bg-opacity-50 rounded-full">
          <Loading />
        </div>
      )}
      {children}
    </Type>
  )
}
