import clsx from 'clsx'
import React, { MouseEvent, ReactNode } from 'react'
import { useAccessibility } from '../../providers/AccessibilityProvider'

export type FloatingButtonProps = {
  icon: ReactNode
  circled: boolean
  label?: string
  flasher?: boolean
  count?: number
  color?: string
  disabled?: boolean
  onClick?(e: MouseEvent): void
  ariaLabel?: string
}

export default function FloatingButton(props: FloatingButtonProps) {
  const { accessibility } = useAccessibility()
  const { color, icon, circled, label, count, onClick, flasher, disabled } =
    props

  const Tag = onClick ? 'button' : 'div'
  const disable = disabled ? disabled : false

  return (
    <Tag
      onClick={onClick}
      className={`flex flex-col items-center ${disable && 'cursor-default'}`}
      disabled={disable}
      {...(onClick ? { 'aria-label': props.ariaLabel } : {})}
    >
      <div
        className={clsx(
          'text-18 flex items-center justify-center p-2 ',
          !!label && !circled && 'pb-0',
          circled && `rounded-full w-14 h-14 bg-${color}`,
          accessibility.highContrast && '!bg-primary rounded-full'
        )}
      >
        {flasher && (
          <div className="blur absolute w-[30px] h-[30px] rounded-full animate-mute"></div>
        )}
        <div className="relative">
          {icon}
          {count !== undefined && (
            <div
              className={`right-[2.5em] top-[0em] bg-ctaPrimary text-ctaText absolute text-12 leading-[1em] p-1 rounded-xl min-w-[1.4em] justify-center flex font-bold items-center ${
                accessibility.highContrast && 'border border-white'
              }`}
            >
              {count}
            </div>
          )}
        </div>
      </div>
      {label && <div className="text-10 py-2 font-bold">{label}</div>}
    </Tag>
  )
}
