import { PayloadAction } from '@reduxjs/toolkit'
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useDispatch } from 'react-redux'
import { Observable, of } from 'rxjs'
import { useFilteredAction, useSelector } from 'store/Store'
import { videoReducer } from 'store/videoReducer'

type VideoContext = {
  muted: boolean
  playing: boolean
  intentToggleMute(): void
  setMute(muted: boolean): void
  setPlay(playing: boolean): void
  intentMuteActions$: Observable<PayloadAction<boolean>>
  setHotProduct(products: any): void
  hotProduct: any
  setInvisibleProduct(products: any): void
  invisibleProduct: any
  setProgress(progress: any): void
  progress: any
  setMessages(messages: any): void
  messages: any
  setLikes(like: any): void
  likes: any
  setError(e: any): void
  error: boolean
}

const Context = React.createContext<VideoContext>({
  muted: false,
  playing: false,
  intentToggleMute: () => {},
  setMute: () => {},
  setPlay: () => {},
  intentMuteActions$: of(),
  setHotProduct: () => {},
  hotProduct: [],
  setProgress: () => {},
  invisibleProduct: [],
  setInvisibleProduct: () => {},
  progress: {},
  setMessages: () => {},
  messages: [],
  setLikes: () => {},
  likes: [] || 0,
  setError: () => {},
  error: false,
})

type VideoProviderProps = PropsWithChildren<{
  //
}>

export function VideoProvider(props: VideoProviderProps) {
  const state = useSelector((state) => state.video)

  const dispatch = useDispatch()

  const intentToggleMute = useCallback(() => {
    dispatch(videoReducer.actions.intent_setMute(!state.muted))
  }, [dispatch, state])

  const setMute = useCallback(
    (muted: boolean) => {
      dispatch(videoReducer.actions.setMute(muted))
    },
    [dispatch]
  )

  const setPlay = useCallback(
    (playing: boolean) => {
      dispatch(videoReducer.actions.setPlay(playing))
    },
    [dispatch]
  )

  const intentMuteActions$ = useFilteredAction<boolean>(
    videoReducer.actions.intent_setMute
  )

  const setHotProduct = useCallback(
    (products: any) => {
      dispatch(videoReducer.actions.setHotProduct(products))
    },
    [dispatch]
  )

  const setInvisibleProduct = useCallback(
    (products: any) => {
      dispatch(videoReducer.actions.setInvisibleProduct(products))
    },
    [dispatch]
  )

  const setProgress = useCallback(
    (progress: any) => {
      dispatch(videoReducer.actions.setProgress(progress))
    },
    [dispatch]
  )

  const setMessages = useCallback(
    (messages: any) => {
      dispatch(videoReducer.actions.setMessages(messages))
    },
    [dispatch]
  )

  const setLikes = useCallback(
    (likes: any) => {
      dispatch(videoReducer.actions.setLikes(likes))
    },
    [dispatch]
  )

  const setError = useCallback(
    (error: any) => {
      dispatch(videoReducer.actions.setError(error))
    },
    [dispatch]
  )

  const value: VideoContext = useMemo(() => {
    return {
      setMute,
      setPlay,
      playing: state.playing,
      muted: state.muted,
      intentToggleMute,
      intentMuteActions$,
      setHotProduct,
      hotProduct: state.hotProduct,
      setInvisibleProduct,
      invisibleProduct: state.invisibleProduct,
      setProgress,
      progress: state.progress,
      setMessages,
      messages: state.messages,
      setLikes,
      likes: state.likes,
      setError,
      error: state.error,
    }
  }, [
    state,
    intentToggleMute,
    setMute,
    setPlay,
    intentMuteActions$,
    setHotProduct,
    setInvisibleProduct,
    setProgress,
    setMessages,
    setLikes,
  ])

  return <Context.Provider value={value}>{props.children}</Context.Provider>
}

export const useVideo = function () {
  return useContext(Context)
}
