import React from 'react'
import { IconProps } from 'Types'
export function ChatOFFIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      height="1.5em"
      width="1.5em"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8 17.4C24.5934 15.8292 25.0046 14.0932 25 12.3334V11.6667C24.8467 8.88757 23.6737 6.26265 21.7055 4.29452C19.7374 2.32639 17.1125 1.15336 14.3333 1.00004H13.6667C11.9068 0.99545 10.1708 1.40661 8.6 2.20004M5 5C4.37156 5.62384 4.49775 5.61658 4.02763 6.37677C2.92082 8.16651 2.33415 10.229 2.33333 12.3334C2.32875 14.0932 2.73991 15.8292 3.53333 17.4L1 25L8.6 22.4667C10.1708 23.2601 11.9068 23.6713 13.6667 23.6667C15.771 23.6659 17.8335 23.0792 19.6233 21.9724C20.1085 21.6723 20.5685 21.3372 21 20.9706"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L25 25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
