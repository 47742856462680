import { t } from 'i18next'
import React, { ReactNode, createContext, useContext, useState } from 'react'
import { HighContrastIcon } from '../icons/HighContrastIcon'

interface AccessibilityContextProps {
  accessibility: { highContrast: boolean }
  setAccessibility: React.Dispatch<
    React.SetStateAction<{ highContrast: boolean }>
  >
  highContrastTheme: typeof highContrastTheme
  items: {
    icon: React.ReactElement
    title: string
    active: boolean
    onClick: () => void
  }[]
}

const AccessibilityContext = createContext<
  AccessibilityContextProps | undefined
>(undefined)

const AccessibilityProvider = ({ children }: { children: ReactNode }) => {
  const [accessibility, setAccessibility] = useState({ highContrast: false })

  const items = [
    {
      title: t('high_contrast'),
      icon: <HighContrastIcon />,
      active: accessibility.highContrast,
      onClick: () =>
        setAccessibility((prev) => ({
          ...prev,
          highContrast: !accessibility?.highContrast,
        })),
    },
  ]

  return (
    <AccessibilityContext.Provider
      value={{ accessibility, setAccessibility, highContrastTheme, items }}
    >
      {children}
    </AccessibilityContext.Provider>
  )
}

const useAccessibility = (): AccessibilityContextProps => {
  const context = useContext(AccessibilityContext)
  if (context === undefined) {
    throw new Error(
      'useAccessibility must be used within an AccessibilityProvider'
    )
  }
  return context
}

export { AccessibilityProvider, useAccessibility }

const highContrastTheme = {
  accent: '#FFFFFF',
  chatAccent: '#747475',
  chatOpacity: 100,
  chatPrimary: '#0E0E0F',
  chatText: '#FFFFFF',
  ctaPrimary: '#171719',
  ctaText: '#FFFFFF',
  immutableBlack: '#FFFFFF',
  immutableWhite: '#262626',
  primary: '#171719',
  secondary: '#0E0E0F',
  subtext: '#FFFFFF',
  text: '#FFFFFF',
}
