import React from 'react'
import { IconProps } from 'Types'

/**
 * fill: #2AAE1F
 */
export function WhatsIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="8" fill="currentColor" />
      <path
        d="M25.038 22.1842C24.7623 22.0446 23.4196 21.3894 23.1687 21.2959C22.9177 21.2066 22.7356 21.1598 22.5527 21.4355C22.3739 21.7043 21.8473 22.3196 21.6864 22.4991C21.5256 22.6785 21.3674 22.6923 21.0959 22.5706C20.8202 22.431 19.9395 22.1443 18.8938 21.2066C18.0771 20.4799 17.5332 19.5848 17.3717 19.3091C17.2108 19.0368 17.3538 18.8828 17.4899 18.7467C17.6151 18.6216 17.7656 18.4318 17.9052 18.2668C18.0379 18.1018 18.0805 17.9911 18.1774 17.8124C18.2668 17.6192 18.2207 17.4686 18.1527 17.3325C18.0846 17.1964 17.5367 15.8468 17.3077 15.3092C17.0891 14.7757 16.8602 14.8438 16.6917 14.8438C16.5343 14.8293 16.3514 14.8293 16.1693 14.8293C15.9871 14.8293 15.6894 14.8974 15.4384 15.1586C15.1875 15.4343 14.4787 16.0929 14.4787 17.4253C14.4787 18.7611 15.4597 20.0536 15.5959 20.2468C15.7354 20.4256 17.5257 23.1756 20.2723 24.3574C20.9274 24.6331 21.4362 24.7981 21.8336 24.9342C22.4887 25.1418 23.0869 25.1129 23.5592 25.0449C24.0817 24.9589 25.1776 24.3821 25.4072 23.7379C25.6402 23.0896 25.6402 22.5527 25.5722 22.431C25.5041 22.3059 25.3254 22.2378 25.0497 22.1161L25.038 22.1842ZM20.0571 28.9375H20.0426C18.4167 28.9375 16.8093 28.4968 15.4054 27.6732L15.0761 27.4766L11.6386 28.3717L12.5626 25.027L12.3406 24.6833C11.4327 23.2397 10.9511 21.5691 10.9511 19.8639C10.9511 14.8726 15.0369 10.8012 20.0639 10.8012C22.4991 10.8012 24.7836 11.75 26.5024 13.4688C27.3503 14.3065 28.0228 15.3048 28.4808 16.4052C28.9388 17.5057 29.173 18.6864 29.1699 19.8783C29.163 24.8661 25.0806 28.9375 20.0605 28.9375H20.0571ZM27.8093 12.1618C25.7179 10.1419 22.9679 9 20.0426 9C14.0091 9 9.09625 13.8916 9.09281 19.9031C9.09281 21.8226 9.594 23.6953 10.5538 25.3529L9 31L14.808 29.4854C16.417 30.3529 18.2154 30.8092 20.0433 30.8137H20.0468C26.0837 30.8137 30.9966 25.9221 31 19.9065C31 16.9956 29.8649 14.2559 27.7956 12.1969L27.8093 12.1618Z"
        fill="white"
      />
    </svg>
  )
}
