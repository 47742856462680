import DefaultModal from '@components/defaultModal/DefaultModal'
import FloatingButton from '@components/floatingButton/FloatingButton'
import ShareBlock from '@components/shareBlock/ShareBlock'
import { ShareIcon } from '@icons/ShareIcon'
import { t } from 'i18next'
import { useModal } from 'modal'
import { useSettings } from 'providers/SettingsProviders'
import React, { useCallback } from 'react'

export function ShareButton() {
  const { settings } = useSettings()

  const shareText = `${t('share_live_text_1')} ${settings?.live.title} ${t(
    'share_live_text_2'
  )}`

  const shareURL = settings?.live.shareURL as string

  const modal = useModal({
    component: (
      <DefaultModal title={t('share')}>
        <ShareBlock text={shareText} url={shareURL} />
      </DefaultModal>
    ),
    open: false,
  })

  const onClick = useCallback(() => {
    try {
      window.navigator.share({
        title: settings?.live.title,
        text: shareText,
        url: shareURL,
      })
    } catch (e) {
      modal.open()
    }
  }, [settings?.live, modal, shareText, shareURL])

  return (
    <>
      {modal.elem}
      <FloatingButton
        circled={false}
        icon={<ShareIcon className="text-text" />}
        onClick={onClick}
        ariaLabel={t('share')}
      />
    </>
  )
}
