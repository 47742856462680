import {
  ActionCreatorWithPayload,
  configureStore,
  EnhancedStore,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  useObservable,
  useObservableCallback,
  useSubscription,
} from 'observable-hooks'
import { useEmbedParams } from 'providers/EmbedParamsProvider'
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { createSelectorHook, Provider, useDispatch } from 'react-redux'
import { Action } from 'redux'
import { filter, Observable, of } from 'rxjs'
import { ChatState, createChatReducer } from './chatReducer'
import { CheckoutState, createCheckoutReducer } from './checkoutReducer'
import { coreReducer, CoreState } from './coreReducer'
import { createPersistFactory } from './persist'
import { recordedLiveReducer, RecordedLiveState } from './recordedLiveReducer'
import { settingsReducer, SettingsState } from './settingsReducer'
import { videoReducer, VideoState } from './videoReducer'

type StoreProps = PropsWithChildren<{
  //
}>

const Actions$Context = React.createContext<Observable<PayloadAction<unknown>>>(
  of()
)

type StoreType = EnhancedStore<RootState>

type RootState = {
  chat: ChatState
  settings: SettingsState
  core: CoreState
  video: VideoState
  checkout: CheckoutState
  recordedLive: RecordedLiveState
}

export const Store = (props: StoreProps) => {
  const params = useEmbedParams()

  const [addAction, actions$] = useObservableCallback<
    PayloadAction,
    PayloadAction
  >((event$) => event$)

  const store = useMemo(() => {
    const persistFactory = createPersistFactory(params.liveId || 'not-set')

    const actionStreamMiddleware: Middleware = (_) => (next) => (action) => {
      addAction(action)
      next(action)
    }
    const checkoutReducer = createCheckoutReducer(persistFactory)
    const chatReducer = createChatReducer(persistFactory)

    const rootReducer = (state: RootState = {} as any, action: Action) => {
      return {
        checkout: checkoutReducer.reducer(state.checkout, action),
        core: coreReducer.reducer(state.core, action),
        chat: chatReducer.reducer(state.chat, action),
        video: videoReducer.reducer(state.video, action),
        settings: settingsReducer.reducer(state.settings, action),
        recordedLive: recordedLiveReducer.reducer(state.recordedLive, action),
      }
    }

    const store: StoreType = configureStore({
      reducer: rootReducer,
      // Typescript is dumb sometimes...
      middleware: [params.middleware!, actionStreamMiddleware].filter(
        (v) => !!v
      ),
    })
    store.dispatch(coreReducer.actions.init())
    return store
  }, [addAction, params])

  return (
    <Actions$Context.Provider value={actions$}>
      <Provider store={store}>{props.children}</Provider>
    </Actions$Context.Provider>
  )
}

export function useAction$() {
  const stream$ = useContext(Actions$Context)
  return stream$
}

export const useSelector = createSelectorHook<RootState>()

export function useFilteredAction<P>(actionFn: ActionCreatorWithPayload<P>) {
  const _actions$ = useAction$()

  const actions$ = useObservable<PayloadAction<P>>(() => {
    return _actions$.pipe(filter((v) => v.type === actionFn.type)) as any
  })

  return actions$
}

/**
 *  Creates a action loop that will be dispatched to the store and subscribed back
 */
export function useActionLoop<P>(
  actionFn: ActionCreatorWithPayload<P>,
  callback: (action: PayloadAction<P>) => void
) {
  const dispatch = useDispatch()

  const actions$ = useFilteredAction(actionFn)

  useSubscription(actions$, callback as any) // TODO: proper fix typing

  const dispatchFn = useCallback(
    (payload: P) => {
      return dispatch(actionFn(payload))
    },
    [dispatch, actionFn]
  )

  return dispatchFn
}
