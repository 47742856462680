import { PATHS } from './Paths'

export type RouterMeta = {
  showProducts?: boolean
  showProductInfo?: boolean
  showCart?: boolean
}

export type Route = {
  path: string
  exact?: boolean
  meta?: RouterMeta
}

export const ROUTES: Route[] = [
  { path: PATHS.home, exact: true, meta: {} },
  { path: PATHS.products, exact: true, meta: { showProducts: true } },
  { path: PATHS.cart, exact: true, meta: { showCart: true } },
  { path: PATHS.product, exact: true, meta: { showProductInfo: true } },
  {
    path: PATHS.productWithVariant,
    exact: true,
    meta: { showProductInfo: true },
  },

  {
    path: '*',
    exact: false,
  },
]
