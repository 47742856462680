import { ToasterProvider } from '@components/toaster/Toaster'
import React, { PropsWithChildren } from 'react'
import { FatalError } from '../errors/FatalError'
import { ModalProvider } from '../modal'
import ThemeProvider from '../theme/ThemeProvider'
import {
  AccessibilityProvider,
  useAccessibility,
} from './AccessibilityProvider'
import { useConfig } from './ConfigProvider'
import { useSettings } from './SettingsProviders'
import TrackingProvider from './TrackingProvider'
import { ChatDisabledProvider } from './chat/ChatDisabledProvider'
import { ChatFirebaseProvider } from './chat/ChatFirebaseProvider'
import { ChatRecordedLiveProvider } from './chat/ChatRecordedLiveProvider'
import { CheckoutBoticarioURLProvider } from './checkout/CheckoutBoticarioURLProvider'
import { CheckoutHeadlessProvider } from './checkout/CheckoutHeadlessProvider'
import { CheckoutMagentoAPIProvider } from './checkout/CheckoutMagentoAPIProvider'
import { CheckoutProductURLProvider } from './checkout/CheckoutProductURLProvider'
import { CheckoutShopifyBudaURLProvider } from './checkout/CheckoutShopifyBudaURLProvider'
import { CheckoutTheBodyShopURLProvider } from './checkout/CheckoutTheBodyShopURLProvider'
import { CheckoutVndaURLProvider } from './checkout/CheckoutVndaURLProvider'
import { CheckoutVtexAPIProvider } from './checkout/CheckoutVtexAPIProvider'
import { CheckoutVtexURLProvider } from './checkout/CheckoutVtexURLProvider'
import { CheckoutWakeURLProvider } from './checkout/CheckoutWakeURLProvider'
import { CheckoutWhatsappProvider } from './checkout/CheckoutWhatsappProvider'
import { SettingsApiProvider } from './settings/SettingsApiProvider'
import { SettingsFirebaseProvider } from './settings/SettingsFirebaseProvider'

const providers: any = {
  checkout: {
    headless: CheckoutHeadlessProvider,
    vtex_checkout_api: CheckoutVtexAPIProvider,
    vtex_checkout_url: CheckoutVtexURLProvider,
    magento_checkout_api: CheckoutMagentoAPIProvider,
    product_url: CheckoutProductURLProvider,
    whatsapp: CheckoutWhatsappProvider,
    shopify_checkout_api: CheckoutVtexAPIProvider, //Mesmo provider que a VTEX, futuro alterar nome e "extender"
    shopify_checkout_url: CheckoutVtexURLProvider,
    shopify_buda_checkout_url: CheckoutShopifyBudaURLProvider,
    vnda_checkout_url: CheckoutVndaURLProvider,
    wake_checkout_url: CheckoutWakeURLProvider,
    boticario_checkout_url: CheckoutBoticarioURLProvider,
    thebodyshop_checkout_url: CheckoutTheBodyShopURLProvider,
  },
  settings: {
    firebase: SettingsFirebaseProvider,
    api: SettingsApiProvider,
  },
  chat: {
    disabled: ChatDisabledProvider,
    firebase: ChatFirebaseProvider,
    recorded: ChatRecordedLiveProvider,
  },
}

type ProviderComponent = ({
  children,
  config,
}: PropsWithChildren<{ config: any }>) => JSX.Element

type BusinessProviderProps = PropsWithChildren<{
  //
}>

export default function BusinessProvider(props: BusinessProviderProps) {
  const { children } = props
  const config = useConfig()

  const ChatProvider = providers.chat[config.chat.type] as ProviderComponent
  const SettingsProvider = providers.settings[
    config.settings.type
  ] as ProviderComponent
  const CheckoutProvider = providers.checkout[
    config.checkout.type
  ] as ProviderComponent

  if (!ChatProvider) {
    throw new FatalError(
      `Erro ao iniciar o chat, Provider "${config?.chat?.type}" não encontrado`
    )
  }

  if (!SettingsProvider) {
    throw new FatalError(
      `Erro ao iniciar as configurações, Provider "${config?.settings?.type}" não encontrado`
    )
  }

  if (!CheckoutProvider) {
    throw new FatalError(
      `Erro ao iniciar o carrinho, Provider "${config?.checkout?.type}" não encontrado`
    )
  }

  return (
    <SettingsProvider config={config.settings}>
      <TrackingProvider>
        <AccessibilityProvider>
          <ConnectedTheme>
            <ToasterProvider>
              <ModalProvider>
                <CheckoutProvider config={config.checkout}>
                  <ChatProvider config={config.chat}>{children}</ChatProvider>
                </CheckoutProvider>
              </ModalProvider>
            </ToasterProvider>
          </ConnectedTheme>
        </AccessibilityProvider>
      </TrackingProvider>
    </SettingsProvider>
  )
}

export function ConnectedTheme({
  children,
}: PropsWithChildren<{
  //
}>) {
  const { settings } = useSettings()
  const { accessibility, highContrastTheme } = useAccessibility()

  return (
    <ThemeProvider
      fonts={settings?.uiSettings.fonts}
      colors={
        accessibility.highContrast
          ? highContrastTheme
          : settings?.uiSettings.colors
      }
    >
      {children}
    </ThemeProvider>
  )
}
