import React from 'react'
import { IconProps } from 'Types'
export function ChatONIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      height="1.5em"
      width="1.5em"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 12.3334C25.0046 14.0932 24.5934 15.8292 23.8 17.4C22.8592 19.2824 21.413 20.8656 19.6233 21.9724C17.8335 23.0792 15.771 23.6659 13.6667 23.6667C11.9068 23.6713 10.1708 23.2601 8.6 22.4667L1 25L3.53333 17.4C2.73991 15.8292 2.32875 14.0932 2.33333 12.3334C2.33415 10.229 2.92082 8.16651 4.02763 6.37677C5.13444 4.58704 6.71767 3.14079 8.6 2.20004C10.1708 1.40661 11.9068 0.99545 13.6667 1.00004H14.3333C17.1125 1.15336 19.7374 2.32639 21.7055 4.29452C23.6737 6.26265 24.8467 8.88757 25 11.6667V12.3334Z"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
