export function arrayEqual<T>(arr1: Array<T>, arr2: Array<T>): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }
  return arr1.filter((v) => arr2.includes(v)).length === arr1.length
}

export function arrayRemoveChunk<T>(arr: Array<T>, chunk: Array<T>): Array<T> {
  return arr.filter((v) => {
    return !chunk.includes(v)
  })
}
