import { MimoId } from '../Types'

export type Persist = {
  set<T>(v: T): T
  get<T>(defaultValue: T): T
}
export type PersistFactory = (key: string) => Persist

export function createPersistFactory(liveId: MimoId): PersistFactory {
  const keyAppend = `__mimo__${liveId}__`

  return function persist(key: string) {
    return {
      set<T>(v: T) {
        localStorage.setItem(keyAppend + key, JSON.stringify(v))
        return v
      },
      get<T>(defaultValue: T) {
        const v = localStorage.getItem(keyAppend + key)
        if (v) {
          try {
            const json = JSON.parse(v)
            return json as T
          } catch (e) {
            return defaultValue
          }
        }
        return defaultValue
      },
    }
  }
}
