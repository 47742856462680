import React from 'react'
import { useChat } from '../../providers/ChatProviders'
import { useSettings } from '../../providers/SettingsProviders'
import LiveCount from './LiveCount'

export type LiveCountProps = {
  total?: number
}

export default React.memo(function LiveCountFirebase() {
  const chat = useChat()
  const settings = useSettings()

  if (settings.settings?.live.status != 'awaiting') {
    return null
  }

  return <LiveCount total={chat.liveCount} />
})
