import { createSlice } from '@reduxjs/toolkit'

export type SettingsState = {
  // empty for now
}

const initialState: SettingsState = {}

export const settingsReducer = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {},
})
