import { MimoId } from 'Types'
import clsx from 'clsx'
import { useField, useFormikContext } from 'formik'
import React, {
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  PropsWithChildren,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useAccessibility } from '../../providers/AccessibilityProvider'

export type TextInputProps = {
  value?: MimoId
  onChange(e: ChangeEvent<HTMLInputElement>): void
  onClick?(e: MouseEvent<HTMLInputElement>): void
  onFocus?(e: FocusEvent<HTMLInputElement>): void
  onBlur?(e: FocusEvent<HTMLInputElement>): void
  type: 'text' | 'password'
  placeholder: string
  name: string
  error?: boolean
  readOnly?: boolean
  className?: string
  max?: number
}
export default function TextInput(props: TextInputProps) {
  const {
    onChange,
    onClick,
    value,
    type,
    onBlur,
    placeholder,
    name,
    readOnly,
    error,
    className,
    onFocus,
    max,
  } = props

  const { accessibility } = useAccessibility()
  return (
    <input
      onFocus={onFocus}
      onClick={onClick}
      onBlur={onBlur}
      name={name}
      placeholder={placeholder}
      className={clsx(
        'min-h-[48px] px-4 text-immutableBlack border-2 border-transparent text-14 bg-immutableWhite rounded-full block w-full',
        error && 'border-error',
        className,
        accessibility.highContrast && '!placeholder-text'
      )}
      type={type || 'text'}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      maxLength={max}
    />
  )
}

type FormikTextInputProps = Omit<TextInputProps, 'value' | 'onChange'>

export function FormikTextInput(props: FormikTextInputProps) {
  const { name, type, placeholder, max } = props
  const [input] = useField(name)
  const { i18n } = useTranslation()

  const errorMsg = useFieldError(name)

  function phoneOnChange(v: any) {
    const number = v.target.value.replace(/\D/g, '')
    if (i18n.language === 'pt-BR') {
      const phoneMask =
        number.slice(2, 3) === '9' ||
        number.slice(2, 3) === '8' ||
        number.slice(2, 3) === '7'
          ? number.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
          : number.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
      v.target.value = phoneMask
      input.value = phoneMask
    } else {
      v.target.value = '+' + number
      input.value = '+' + number
    }
    input.onChange(v)
  }

  return (
    <TextInput
      error={!!errorMsg}
      placeholder={placeholder}
      value={input.value}
      onChange={name === 'phone' ? phoneOnChange : input.onChange}
      name={name}
      type={type}
      max={max}
    />
  )
}

type FormikFieldProps = PropsWithChildren<{
  name: string
}>
export function FormikField(props: FormikFieldProps) {
  const { name, children } = props

  const errorMsg = useFieldError(name)

  return (
    <div>
      <div>{children}</div>
      {errorMsg && (
        <div className="text-error text-12 text-center p-1">{errorMsg}</div>
      )}
    </div>
  )
}

function useFieldError(name: string) {
  const [_, meta] = useField(name)

  const context = useFormikContext()

  return ((context.submitCount > 0 || meta.touched) && meta.error) || false
}
