import { Observable, throwError, timer } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

type GenericRetryStrategyOptions = {
  maxRetryAttempts?: number
  scalingDuration?: number
  excludedStatusCodes?: number[]
}

export const genericRetryStrategy = ({
  maxRetryAttempts = 2,
  scalingDuration = 1000,
  excludedStatusCodes = [404],
}: GenericRetryStrategyOptions = {}) => (attempts: Observable<Response>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1

      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find((e) => e === error?.status)
      ) {
        return throwError(() => error)
      }

      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration)
    })
  )
}
