import React from 'react'
import { IconProps } from 'Types'
export function AccessibilityIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M8.88602 4.50585C9.21577 4.72619 9.60346 4.84379 10.0001 4.84379C10.5317 4.84322 11.0414 4.63178 11.4173 4.25585C11.7932 3.87993 12.0047 3.37022 12.0053 2.83858C12.0053 2.44199 11.8877 2.0543 11.6673 1.72455C11.447 1.39479 11.1338 1.13778 10.7674 0.986012C10.401 0.834243 9.99783 0.794533 9.60886 0.871904C9.21988 0.949276 8.86259 1.14025 8.58216 1.42069C8.30172 1.70112 8.11074 2.05841 8.03337 2.44739C7.956 2.83636 7.99571 3.23954 8.14748 3.60594C8.29925 3.97235 8.55626 4.28552 8.88602 4.50585ZM16.286 4.87668L16.3021 4.87239C16.9825 4.69371 17.4476 5.38586 17.4476 6.01715C17.4476 6.64843 16.8747 6.94563 16.3018 7.16047C16.2828 7.16765 16.2634 7.17386 16.2438 7.17909L13.0469 8.29664C12.6358 8.45097 12.4597 8.61138 12.421 8.683C12.4202 8.68486 12.4192 8.68666 12.4181 8.68837C12.3773 8.76106 12.2566 9.07115 12.4067 10.0097L12.614 11.621L13.7824 17.8776C13.7931 17.9249 13.8021 17.9736 13.8096 18.023C13.8948 18.6031 13.4701 19.1667 12.6638 19.1667C11.9619 19.1667 11.6522 18.6822 11.5179 18.023C11.5179 18.023 10.4852 12.3808 9.98394 12.3808C9.48263 12.3808 8.48003 18.023 8.48003 18.023C8.34575 18.6822 8.03602 19.1667 7.3342 19.1667C6.52996 19.1667 6.10529 18.6056 6.18836 18.0273L6.1966 17.9818C6.19946 17.9639 6.20269 17.9463 6.20627 17.9292L7.3589 11.6203L7.56659 10.0089C7.60848 9.74683 7.72664 8.99595 7.57876 8.61675C7.53329 8.49859 7.44019 8.36216 7.09178 8.2286L3.68221 7.16369V7.15367C3.11466 6.9399 2.55249 6.64198 2.55249 6.01643C2.55249 5.38479 3.01799 4.69371 3.69832 4.87274C3.79549 4.89807 3.89113 4.92891 3.98478 4.96513C4.9258 5.23261 7.86737 6.02037 9.98465 6.02037C12.2631 6.02037 15.4972 5.10871 16.1636 4.91321C16.1994 4.90211 16.2352 4.89136 16.271 4.88134L16.286 4.87668Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
