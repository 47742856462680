import 'firebase/database'
import { t } from 'i18next'
import { useObservableGetState } from 'observable-hooks'
import { SettingsProviderContext } from 'providers/SettingsProviders'
import React, { PropsWithChildren, useMemo } from 'react'
import { catchError } from 'rxjs'
import { Settings, SettingsApiConfig } from 'Types'
import { FatalError } from '../../errors/FatalError'
import { jsonFetch } from '../../http/http'

export function SettingsApiProvider({
    config,
    children,
}: PropsWithChildren<{
    config: SettingsApiConfig
}>) {
    // faz o get

    const request$ = jsonFetch<Settings>(
        config.meta.endpointUrl
    );

    const settings = useObservableGetState(
        request$.pipe(
            catchError((err: Error) => {
                throw new FatalError(t('error_to_load_settings'), err)
            })
        ),
        undefined
    )

    const contextValue = useMemo(() => {
        return {
            enabled: true,
            type: config.type,
            settings,
        }
    }, [config.type, settings])

    return (
        <SettingsProviderContext.Provider value={contextValue}>
            {settings && children}
        </SettingsProviderContext.Provider>
    )
}
