export interface IPrivacy {
  'en-US': string
  'pt-BR': string
}

export interface ICookies {
  'en-US': string
  'pt-BR': string
}

export const PrivacyOptions: IPrivacy = {
  'en-US': 'https://www.mimo.com.br/en-US/privacy',
  'pt-BR': 'https://www.mimo.com.br/privacy',
}

export const CookiesOptions: ICookies = {
  'en-US': 'https://www.mimo.com.br/en-US/cookies',
  'pt-BR': 'https://www.mimo.com.br/cookies',
}
