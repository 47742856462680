/* eslint-disable react-hooks/rules-of-hooks */
import 'firebase/database'
import React, { PropsWithChildren, useContext } from 'react'
import { Settings } from 'Types'

type SettingsContext = {
  enabled: boolean
  type: string
  settings?: Settings
}

const disabledContext: SettingsContext = {
  enabled: false,
  type: 'disabled',
  settings: undefined,
}

export const SettingsProviderContext = React.createContext(disabledContext)

export function useSettings() {
  return useContext(SettingsProviderContext)
}

// The default chat provider is disabled
export function SettingsDisabledProvider({
  children,
}: PropsWithChildren<{
  //
}>) {
  return (
    <SettingsProviderContext.Provider value={disabledContext}>
      {children}
    </SettingsProviderContext.Provider>
  )
}
