import clsx from 'clsx'
import React, { PropsWithChildren, useState } from 'react'
import { Collapsible } from 'react-hook-collapse'
import { useTranslation } from 'react-i18next'

export type CollapsibleTextProps = PropsWithChildren<{
  //
  className?: string
}>

export default function CollapsibleText(props: CollapsibleTextProps) {
  const { children } = props
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  return (
    <div>
      <Collapsible
        className={clsx(
          'overflow-hidden transition-all duration-300 ',
          props.className
        )}
        expanded={expanded}
      >
        {children}
      </Collapsible>

      <button
        onClick={() => {
          setExpanded(!expanded)
        }}
        className="mt-4 w-full font-barlow-cond text-14 p-1 font-semibold text-center border-t border-white border-opacity-50"
      >
        {expanded ? t('see_less').toUpperCase() : t('see_more').toUpperCase()}
      </button>
    </div>
  )
}
