import clsx from 'clsx'
import React from 'react'
import { formatMoney } from 'utils/FormatUtil'
import { useConfig } from '../../providers/ConfigProvider'

type CurrencyProps = {
  value: number
  className: string
}
export default function Currency({ value, className }: CurrencyProps) {
  const config = useConfig()

  return (
    <span className={clsx('font-barlow-cond font-semibold ', className)}>
      {formatMoney(
        value,
        config?.settings?.currency?.locale || 'pt-BR',
        config?.settings?.currency?.code || 'BRL'
      )}
    </span>
  )
}
