import React from 'react'

export function Loading({ className }: { className?: string }) {
  return (
    <div
      aria-roledescription="Loading"
      className={`rounded-full p-2 inline-block bg-primary-bgop bg-opacity-50 ${className}`}
    >
      <div className="w-[1em] h-[1em] border-[0.1em] border-text rounded-full animate-spin cursor-wait border-l-[transparent]" />
    </div>
  )
}
