import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef
} from 'react'
import { useConfig } from './ConfigProvider'

type TrackingProviderProps = PropsWithChildren<{
  //
}>

const Context = React.createContext({
  track: (_event: any) => { },
})

export function useTracking() {
  return useContext(Context)
}

export default function TrackingProvider(props: TrackingProviderProps) {
  const { children } = props
  const ref = useRef<HTMLIFrameElement>(null)
  const config = useConfig()
  const track = useCallback((event) => {
    ref.current?.contentWindow?.postMessage(event, '*')
  }, [])

  const value = useMemo(() => {
    return { track }
  }, [track])

  return (
    <>
      <iframe
        title='tracking'
        ref={ref}
        src={
          import.meta.env.VITE_BASE_URL +
          'tracking/index.html?utm_campaign=' + config.id +
          '&utm_id=' + config.id
        }
        style={{
          position: 'absolute',
          width: 0,
          height: 0,
          border: 0,
        }}
      />
      <Context.Provider value={value}>{children}</Context.Provider>
    </>
  )
}
