import CartProductCard from '@components/cartProductCard/CartProductCard'
import FloatingPage from '@components/floatingPage/FloatingPage'
import { PageHandlerProps } from 'Types'
import { useProducts } from 'providers/ProductsProvider'
import React, { MouseEvent, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useMimoRouter } from 'router/MimoRouter'
import { PATHS } from 'router/Paths'
import { getProductThumb, getVariantOrDefault } from 'utils/ProductUtil'
import { useChatEnabled } from '../../hooks/useChatHooks'
import { useChat } from '../../providers/ChatProviders'
import { useConfig } from '../../providers/ConfigProvider'
import { useTracking } from '../../providers/TrackingProvider'

export function ProductsPage({ type }: PageHandlerProps) {
  const modalRef = useRef<HTMLDivElement>(null)
  const { go } = useMimoRouter()
  const { t } = useTranslation()

  const onClose = useCallback(() => {
    go(PATHS.home, {})
  }, [go])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (modalRef.current) modalRef.current.focus()
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div
      ref={modalRef}
      tabIndex={-1}
      role="dialog"
      aria-modal
      className="absolute bottom-0 w-full left-0 z-3-page"
    >
      <FloatingPage type={type} title={t('products')} onClose={onClose}>
        <div className="overflow-auto max-h-64 px-4 pb-4 scrollbar">
          <NakedProductList />
        </div>
      </FloatingPage>
    </div>
  )
}

export function NakedProductList() {
  const { go } = useMimoRouter()
  const { askForInformation } = useChat()

  const chatEnabled = useChatEnabled()

  const onClose = useCallback(() => {
    go(PATHS.home, {})
  }, [go])

  const products = useProducts()
  const tracking = useTracking()
  const currency = useConfig().settings?.currency?.code

  return (
    <div className="space-y-1">
      {products.map((prod) => {
        const defaultVariant = getVariantOrDefault(prod)

        return (
          <CartProductCard
            key={prod.id}
            title={prod.title}
            price={defaultVariant?.price || 0}
            priceCompare={defaultVariant?.priceCompare || 0}
            image={getProductThumb(prod, defaultVariant)}
            onClick={() => {
              tracking.track({
                event: 'view_item',
                currency: currency,
                value: defaultVariant?.price || 0,
                items: [
                  {
                    item_id: defaultVariant?.sku,
                    item_name: prod.title,
                    currency: currency,
                    price: defaultVariant?.price,
                    image: getProductThumb(prod, defaultVariant),
                  },
                ],
              })
              go(PATHS.product, { id: prod.id })
            }}
          >
            <CartProductCard.options.AddToCartOption
              hideQuestion={!chatEnabled}
              onAddClick={(e: MouseEvent) => {
                e.stopPropagation()
                tracking.track({
                  event: 'view_item',
                  currency: currency,
                  value: defaultVariant?.price || 0,
                  items: [
                    {
                      item_id: defaultVariant?.sku,
                      item_name: prod.title,
                      currency: currency,
                      price: defaultVariant?.price,
                      image: getProductThumb(prod, defaultVariant),
                    },
                  ],
                })
                go(PATHS.product, { id: prod.id })
              }}
              onInfoClick={(e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
                onClose()
                tracking.track({ event: 'search', search_term: prod.title })
                !!askForInformation && askForInformation(prod)
              }}
              title={prod.title}
            />
          </CartProductCard>
        )
      })}
    </div>
  )
}

export function ProductsPageHandler({ type }: PageHandlerProps) {
  const route = useMimoRouter()
  return (
    <TransitionGroup data-testid="ProductsPage">
      {route.meta?.showProducts && (
        <CSSTransition timeout={500} classNames="animate-from-bottom">
          <ProductsPage type={type} />
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
