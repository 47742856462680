import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { identity } from './reducerUtils'

export type VideoState = {
  muted: boolean
  playing: boolean
  hasUnmutedOnce: boolean
  hotProduct: any
  invisibleProduct: any
  progress: any
  messages: any
  likes: any
  error: boolean
}

const initialState: VideoState = {
  muted: true,
  playing: false,
  hasUnmutedOnce: false,
  hotProduct: [],
  invisibleProduct: [],
  progress: {},
  messages: [],
  likes: [] || 0,
  error: false,
}

export const videoReducer = createSlice({
  name: 'video',
  initialState: initialState,
  reducers: {
    intent_setMute: identity<PayloadAction<boolean>>(),
    setMute(state, action: PayloadAction<boolean>) {
      // the first time the video unmutes, store the flag so we check if it was clicked
      if (
        state.hasUnmutedOnce === false &&
        state.muted === true &&
        action.payload === false
      ) {
        return { ...state, muted: action.payload, hasUnmutedOnce: true }
      }
      return { ...state, muted: action.payload }
    },
    setPlay(state, action: PayloadAction<boolean>) {
      return { ...state, playing: action.payload }
    },
    setHotProduct(state, action: PayloadAction<any>) {
      return { ...state, hotProduct: action.payload }
    },
    setInvisibleProduct(state, action: PayloadAction<any>) {
      return { ...state, invisibleProduct: action.payload }
    },
    setProgress(state, action: PayloadAction<any>) {
      return { ...state, progress: action.payload }
    },
    setMessages(state, action: PayloadAction<any>) {
      return { ...state, messages: action.payload }
    },
    setLikes(state, action: PayloadAction<any>) {
      return { ...state, likes: action.payload }
    },
    setError(state, action: PayloadAction<any>) {
      return { ...state, error: action.payload }
    },
  },
})
