import React from 'react'
import { IconProps } from 'Types'
export function PauseWhiteIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_12480_151696)">
        <path
          d="M42 68C42 70.2092 43.7909 72 46 72H48C50.2091 72 52 70.2092 52 68V40C52 37.7909 50.2091 36 48 36H46C43.7909 36 42 37.7909 42 40V68Z"
          fill="#F5F5F7"
        />
        <path
          d="M64 36C61.7908 36 60 37.7909 60 40V68C60 70.2092 61.7908 72 64 72H66C68.2092 72 70 70.2092 70 68V40C70 37.7909 68.2092 36 66 36H64Z"
          fill="#F5F5F7"
        />
        <path
          d="M104 54C104 80.5097 82.5097 102 56 102C29.4903 102 8 80.5097 8 54C8 27.4903 29.4903 6 56 6C82.5097 6 104 27.4903 104 54ZM96 54C96 31.9086 78.0914 14 56 14C33.9086 14 16 31.9086 16 54C16 76.0914 33.9086 94 56 94C78.0914 94 96 76.0914 96 54Z"
          fill="#F5F5F7"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_12480_151696"
          x="0"
          y="0"
          width="112"
          height="112"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.152941 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12480_151696"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12480_151696"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
