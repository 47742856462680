import { Field, FieldProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAccessibility } from '../../providers/AccessibilityProvider'
import {
  CookiesOptions,
  ICookies,
  IPrivacy,
  PrivacyOptions,
} from '../../utils/UrlMap'

interface Props {
  id: string
  name: string
  className: string
}

const Checkbox = ({ id, name, className }: Props): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { accessibility } = useAccessibility()

  const currentLang = i18n.language

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <label className="checkbox-label relative">
          <input
            id={id}
            {...field}
            type="checkbox"
            checked={field.value}
            className={`checkbox ${className} mr-2 align-middle border-none`}
            style={{ accentColor: 'transparent' }}
          />
          <span
            className={`checkbox absolute left-0 ${
              field.value ? 'checkbox--active' : ''
            }`}
          >
            {field.value ? (
              <svg viewBox="0 0 24 24" className="checkbox-marked">
                <polyline points="20 6 9 17 4 12" />
              </svg>
            ) : (
              <span></span>
            )}
          </span>
          <span>
            {t('terms_accept')}{' '}
            <a
              href={PrivacyOptions[currentLang as keyof IPrivacy]}
              className={`underline ${
                accessibility.highContrast ? 'text-ctaText' : 'text-ctaPrimary'
              }`}
              target="_blank"
              rel="noreferrer"
            >
              {t('privacy_notice')}
            </a>{' '}
            {t('and')}{' '}
            <a
              href={CookiesOptions[currentLang as keyof ICookies]}
              className={`underline ${
                accessibility.highContrast ? 'text-ctaText' : 'text-ctaPrimary'
              }`}
              target="_blank"
              rel="noreferrer"
            >
              {t('cookies')}
            </a>
          </span>
        </label>
      )}
    </Field>
  )
}

export default Checkbox
