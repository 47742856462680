export function formatMoney(num: number, locale?: string, currency?: string) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(num)
}

/**
 * Note: We can't use dollar sign or brackets eg: ${PARAM} or $PARAM, because vite.js internaly expand env.variables, so, I had to come up with the (MY_VAR) pattern
 */
export function generateURL(
  url: string,
  options: Record<string, string | number>
) {
  return Object.keys(options).reduce((url, key) => {
    return url.split('(' + key + ')').join(options[key].toString())
  }, url)
}

export function bgURL(url: string) {
  return `url("${url}")`
}

export function pluralize(
  value: number,
  obj: Record<number, string>,
  fallback: string
) {
  if (obj[value] !== undefined) {
    return obj[value]
  }
  return fallback
}

export function generateRandomNumber() {
  const minm = 100000
  const maxm = 999999
  return Math.floor(Math.random() * (maxm - minm + 1)) + minm
}
