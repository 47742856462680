import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DesktopTitle } from '../../components/desktopTitle/DesktopTitle'
import { useCheckoutEnabled } from '../../hooks/useCheckoutHooks'
import { useProductsEnabled } from '../../hooks/useProductsHooks'
import { NakedProductList } from '../../pages/productsPage/ProductsPage'
import { useCheckout } from '../../providers/CheckoutProvider'
import { useMimoRouter } from '../../router/MimoRouter'

export default function ProductsDesktop() {
  const checkout = useCheckout()

  const checkoutEnabled = useCheckoutEnabled()

  const productsEnabled = useProductsEnabled()

  const router = useMimoRouter()

  const { t } = useTranslation()

  const fade = router.meta?.showProductInfo || router.meta?.showCart

  if (!productsEnabled) {
    return null
  }

  return (
    <div className="relative w-full h-0 flex flex-col justify-end flex-1">
      <DesktopTitle
        leftSlot={t('products').toUpperCase()}
        rightStot={checkoutEnabled ? checkout.renderCartButton() : null}
      ></DesktopTitle>

      <div
        role="list"
        aria-label={t('products')}
        className={clsx(
          'flex-1 min-h-0 py-3 mx-1 px-4  overflow-auto scrollbar transition-opacity duration-500',
          fade && 'opacity-25 pointer-events-none'
        )}
      >
        <NakedProductList />
      </div>
    </div>
  )
}
