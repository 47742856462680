import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Action } from 'redux'
import { identity } from './reducerUtils'
export type CoreState = {
  // empty for now
}

const initialState: CoreState = {}

type NavigateToPayload = {
  path: string
  state?: any
  method: 'push' | 'replace' | 'goBack'
}

/**
 * Core reducer
 */
export const coreReducer = createSlice({
  name: 'core',
  initialState: initialState,
  reducers: {
    init: identity<Action>(),
    intent_ShowToaster: identity<PayloadAction<string>>(),
    intent_NavigateTo: identity<PayloadAction<NavigateToPayload>>(),
    intent_Like: identity<PayloadAction<unknown>>(),
    intent_ShowPopup: identity<PayloadAction<string>>(),
  },
})
