import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { coreReducer } from '../../store/coreReducer'
import { useActionLoop } from '../../store/Store'

export type ToasterProps = {
  content: string
}

export function Toaster(props: ToasterProps) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.content }}
      className="bg-ctaPrimary text-ctaText text-16 px-4 py-1 tracking-wider font-barlow-cond uppercase font-semibold rounded-lg text-center"
    ></div>
  )
}

type ContextType = [(content: string) => void]
const Context = React.createContext<ContextType>([
  () => {
    throw new Error('<ToasterProvider> not initialized')
  },
])

let key = 0
export function ToasterProvider({
  children,
}: PropsWithChildren<{
  //
}>) {
  const [toasters, setToasters] = useState<{ key: number; value: string }[]>([])

  const show = useCallback(
    (content: string) => {
      setToasters((state) => [...state, { key: ++key, value: content }])
      setTimeout(() => {
        setToasters(([_toBeRemoved, ...rest]) => rest)
      }, 4000)
    },
    [setToasters]
  )

  const showToasterIntent = useActionLoop(
    coreReducer.actions.intent_ShowToaster,
    (v) => {
      show(v.payload)
    }
  )

  const value = useMemo<ContextType>(() => {
    return [showToasterIntent]
  }, [showToasterIntent])

  return (
    <Context.Provider value={value}>
      <TransitionGroup
        data-testid="Toaster"
        className="absolute bottom-6 w-full left-0 space-y-2 flex items-center flex-col justify-center"
      >
        {toasters.map((toaster) => {
          return (
            <CSSTransition
              timeout={500}
              classNames="animate-toaster"
              key={toaster.key}
            >
              <div className="relative z-5-toaster inline-block overflow-hidden">
                <Toaster content={toaster.value} />
              </div>
            </CSSTransition>
          )
        })}
      </TransitionGroup>

      {children}
    </Context.Provider>
  )
}

export function useToaster() {
  return useContext(Context)
}
