import firebase from 'firebase/app'
import { useEffect, useRef } from 'react'
import {
  useList as _useList,
  useObjectVal as _useObjectVal,
} from 'react-firebase-hooks/database'
import { useEmbedParams } from '../providers/EmbedParamsProvider'

export type UseFirebasOptions = {
  databaseURL: string
}

export function useFirebase(appName: string, options: UseFirebasOptions) {
  const firebaseRef = useRef<firebase.app.App>()

  useEffect(() => {
    try {
      firebaseRef.current = firebase.app(appName)
    } catch (e) {
      firebaseRef.current = firebase.initializeApp(options, appName)
    }

    return () => {
      firebaseRef.current?.delete()
    }
  }, [options, appName])

  return firebaseRef.current
}

function wrapFirebaseFnWithErrorLog<T>(fn: T): T {
  const fn2 = ((...args: any[]) => {
    const arr = (fn as any)(...args)
    const error = arr[2]
    const params = useEmbedParams()

    if (error) {
      console.error(error)
      if (params.debug) {
        throw error
      }
    }

    return arr
  }) as any

  return fn2 as T
}

/** Returns the exact same firebase function, but wrapped with an error logger and/or throw on debug mode */
export const useObjectVal = wrapFirebaseFnWithErrorLog(_useObjectVal)
export const useList = wrapFirebaseFnWithErrorLog(_useList)
