import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useAccessibility } from '../../providers/AccessibilityProvider'
import { useActionLoop } from '../../store/Store'
import { coreReducer } from '../../store/coreReducer'

export type PopupProps = {
  content: string
}

export function Popup(props: PopupProps) {
  const { accessibility } = useAccessibility()
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.content }}
      className={`text-20 text-text font-barlow-cond px-4 py-1 text-center 
      tracking-wider rounded-[24px] w-[175px] h-[108px] flex justify-center items-center 
      ${accessibility.highContrast ? 'bg-primary' : 'bg-accent opacity-75'}`}
    ></div>
  )
}

type ContextType = [(content: string) => void]
const Context = React.createContext<ContextType>([
  () => {
    throw new Error('<PopupProvider> not initialized')
  },
])

let key = 0
export function PopupProvider({
  children,
}: PropsWithChildren<{
  //
}>) {
  const [popups, setPopups] = useState<{ key: number; value: string }[]>([])

  const show = useCallback(
    (content: string) => {
      setPopups((state) => [...state, { key: ++key, value: content }])
      setTimeout(() => {
        setPopups(([_toBeRemoved, ...rest]) => rest)
      }, 6000)
    },
    [setPopups]
  )

  const showPopupIntent = useActionLoop(
    coreReducer.actions.intent_ShowPopup,
    (v) => {
      show(v.payload)
    }
  )

  const value = useMemo<ContextType>(() => {
    return [showPopupIntent]
  }, [showPopupIntent])

  return (
    <Context.Provider value={value}>
      {/* @ts-ignore */}
      <TransitionGroup
        data-testid="Popup"
        className="absolute top-1/4 w-full left-0 space-y-2 flex items-center flex-col justify-center"
      >
        {popups.map((popup) => {
          return (
            // @ts-ignore
            <CSSTransition
              timeout={750}
              classNames="animate-popup"
              key={popup.key}
            >
              <div className="relative z-5-toaster inline-block overflow-hidden">
                <Popup content={popup.value} />
              </div>
            </CSSTransition>
          )
        })}
      </TransitionGroup>

      {children}
    </Context.Provider>
  )
}

export function usePopup() {
  return useContext(Context)
}
