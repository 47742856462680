import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import {
  generatePath,
  matchPath,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { coreReducer } from '../store/coreReducer'
import { useActionLoop } from '../store/Store'
import { Route } from './Routes'

type GoParams = {
  [x: string]: string | number | boolean | undefined
}

const MetaContext = React.createContext<{
  meta?: Route['meta']
  params?: any
  go(path: string, params?: GoParams, state?: any): void
  goBack(): void
}>({
  go() {
    throw new Error('MimoRouterProvider not initialized')
  },
  goBack() {
    throw new Error('MimoRouterProvider not initialized')
  },
})

export function MimoRouterProvider({
  children,
  routes,
}: {
  children: ReactNode
  routes: Route[]
}) {
  // this is a internal stack to prevent goBack from leaving the page
  const stack = useRef<string[]>([])

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (history.action === 'POP') {
      stack.current.pop()
    } else if (history.action === 'PUSH') {
      stack.current.push(history.location.key || 'index')
    } else {
      stack.current[Math.max(0, stack.current.length - 1)] =
        history.location.key || 'index'
    }
  }, [history, location])

  const getInternalStack = useCallback(() => {
    return stack.current
  }, [])

  const goBack = useCallback(() => {
    const stack = getInternalStack()

    if (stack.length <= 1) {
      history.push('/home')
    } else {
      history.goBack()
    }
  }, [history, getInternalStack])

  const goIntent = useActionLoop(
    coreReducer.actions.intent_NavigateTo,
    (action) => {
      history.push(action.payload.path, action.payload.state)
    }
  )

  const go = useCallback(
    (path: string, params: GoParams = {}, state?: any) => {
      // history.push(generatePath(path, params), state)
      goIntent({ method: 'push', path: generatePath(path, params), state })
    },
    [goIntent]
  )

  const pathname = location.pathname
  const value = useMemo(() => {
    const matchedRoutes = routes.map((route) => {
      const match = matchPath(pathname, {
        exact: route.exact,
        path: route.path,
      })

      return { match, route }
    })

    const found = matchedRoutes.find((v) => !!v.match)

    return {
      goBack,
      go,
      meta: found?.route?.meta,
      params: found?.match?.params,
      getInternalStack,
    }
  }, [routes, pathname, getInternalStack, go, goBack])

  return <MetaContext.Provider value={value}>{children}</MetaContext.Provider>
}

export function useMimoRouter() {
  return useContext(MetaContext)
}
