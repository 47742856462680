import React from 'react'
import { IconProps } from 'Types'
export function EyeIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6C2 6 3.45455 3 6 3C8.54545 3 10 6 10 6C10 6 8.54545 9 6 9C3.45455 9 2 6 2 6Z"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00107 7.125C6.60356 7.125 7.09197 6.62132 7.09197 6C7.09197 5.37868 6.60356 4.875 6.00107 4.875C5.39857 4.875 4.91016 5.37868 4.91016 6C4.91016 6.62132 5.39857 7.125 6.00107 7.125Z"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
