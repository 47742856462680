import React, { PropsWithChildren, useContext } from 'react'
import { MimoPlayerOptions } from 'Types'

type EmbedParamsProviderProps = {
  options: MimoPlayerOptions
}

const Context = React.createContext<EmbedParamsProviderProps['options']>({
  configEndpoint: 'config-endpoint-not-set',
  debug: false,
  liveId: 'live-id-not-set',
  customerId: 'customer-id-not-set',
  liveUrl: 'live-url-not-set',
  useShadowDom: true,
  dataCheckoutModalOff: 'true',
})

export default function EmbedParamsProvider(
  props: PropsWithChildren<EmbedParamsProviderProps>
) {
  const { children, options } = props

  return <Context.Provider value={options}>{children}</Context.Provider>
}

export const useEmbedParams = function () {
  return useContext(Context)
}
