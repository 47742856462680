import { useConfig } from '@providers/ConfigProvider'
import { useObservableCallback } from 'observable-hooks'
import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ChatFirebaseConfig, Message } from '../../Types'
import { useSelector } from '../../store/Store'
import { ChatContext, ChatProviderContext } from '../ChatProviders'
import { normalizeMessageCache } from './ChatFirebaseProvider'

export const RecordedLiveChatContext = React.createContext({} as ChatContext)

export function ChatRecordedLiveProvider({
  config,
  children,
}: PropsWithChildren<{ config: ChatFirebaseConfig }>) {
  const liveConfig = useConfig()
  const [messagesRecorded, setMessages] = useState<Message[]>([])
  const [_messages, _setMessages] = useState<Message[]>([])
  const [pinnedMessageRecorded, setPinnedMessageRecorded] = useState<Message[]>(
    []
  )
  const [_pinnedMessage, _setPinnedMessage] = useState<Message[]>([])

  const chatUrl = liveConfig?.chat?.chat_data_url?.includes('http') ? liveConfig?.chat?.chat_data_url : import.meta.env.VITE_S3_BUCKET_ASSETS + liveConfig?.chat?.chat_data_url;

  const chatJson = liveConfig?.chat?.chat_data_url && chatUrl

  const videoProgress = useSelector((v) => v.video.progress)
  const videoPlay = useSelector((v) => v.video.playing)
  const userData = useSelector((s) => s.chat.user)

  // ===== Chat =====
  const visible = useSelector((v) => v.chat.visible)

  function calcSeconds(value: any, liveDate: Date) {
    const live = new Date(liveDate)
    const noTimezone = new Date(live.toISOString())
    return Math.floor((value - noTimezone.getTime()) / 1000)
  }

  const msgCache = useRef<Message[]>([])
  const messages: Message[] = useMemo(() => {
    msgCache.current = normalizeMessageCache<Message>(
      msgCache.current,
      Object.entries(_messages || {})?.map((v: any) => {
        const messageDate = v[1].t
        const seconds = calcSeconds(messageDate, liveConfig?.started)
        return { ...v[1], id: v[0], seconds } as Message
      })
    )
      .sort((a, b) => a.t - b.t)
      .filter((msg) => {
        //Mensagens desabilitadas são mostradas para o usuário logado (p/ ele não perceber que foi bloqueado)
        if (userData?.id) {
          return !(msg.disabled && msg.uid != userData.id)
        } else {
          return !msg.disabled
        }
      })
    return msgCache.current
  }, [_messages, userData])

  const pinMsgCache = useRef<Message[]>([])
  const pinnedMessages: Message[] = useMemo(() => {
    pinMsgCache.current = Object.entries(_pinnedMessage || {})
      ?.map((v: any) => {
        const pinMsgInitial = v[1].t
        const pinMsgEnd = v[1].removed
        const secondsToAdd = calcSeconds(pinMsgInitial, liveConfig?.started)
        const secondsToRemove = calcSeconds(pinMsgEnd, liveConfig?.started) ?? false
        return { ...v[1], id: v[0], secondsToAdd, secondsToRemove } as Message
      })
      .sort((a, b) => a.t - b.t)
    return pinMsgCache.current
  }, [_pinnedMessage])

  useEffect(() => {
    if (chatJson) {
      fetch(chatJson, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            _setMessages(res.chat)
            _setPinnedMessage(res.pinned_messages_history)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [])

  useEffect(() => {
    if (videoPlay) {
      setMessages((prevMessages: any) => [
        ...messages.filter(
          (message: any) =>
            message.seconds! <= Math.floor(videoProgress.playedSeconds)
        ),
      ])
    }
  }, [videoProgress, videoPlay, messages])

  useEffect(() => {
    if (videoPlay) {
      const progressIndicator = Math.floor(videoProgress.playedSeconds)
      const pinList = pinnedMessages.filter((pinnedMsg: any) => {
        const addPinMsg = progressIndicator >= pinnedMsg.secondsToAdd!
        const removePinMsg = progressIndicator <= pinnedMsg.secondsToRemove!
        return (pinnedMsg.secondsToRemove! ? (addPinMsg && removePinMsg) : addPinMsg)
      })
      setPinnedMessageRecorded(pinList)
    }
  }, [videoProgress, videoPlay, pinnedMessages])

  const [likeDiff, likesDiff$] = useObservableCallback<number>(
    (likes$) => likes$
  )

  const contextValue = useMemo(() => {
    return {
      visible,
      enabled: false,
      type: config.type,
      messages: messagesRecorded || [],
      pinnedMessage: pinnedMessageRecorded || ({} as Message),
      likesDiff$,
      liveCount: 0,
      isSendingLike: false,
      isSendingMessage: false,
      like: () => {},
      setUser: () => {},
      sendMessage: () => {},
      chatMessageClick: () => {},
      askForInformation: () => {},
      chatUnpinnedMessage: () => {},
      toggleVisibility: () => {},
    }
  }, [config, visible, messages, pinnedMessageRecorded, likesDiff$])

  return (
    <ChatProviderContext.Provider value={contextValue}>
      {children}
    </ChatProviderContext.Provider>
  )
}

