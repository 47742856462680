import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/button/Button'
import { useModalContent } from '../../modal'
import { useAccessibility } from '../../providers/AccessibilityProvider'

export type CheckoutFinishedModalProps = {
  text: string
  contact?: {
    label: string
    target: string
    url: string
  }
}

export function CheckoutFinishedModal(props: CheckoutFinishedModalProps) {
  const { accessibility } = useAccessibility()
  const { t } = useTranslation()
  const { text, contact } = props
  const modal = useModalContent()
  return (
    <div className="px-4 pb-4 max-h-screen max-w-sm space-y-4">
      <div
        dangerouslySetInnerHTML={{ __html: `<div>${text}</div>` }}
        className="pb-4"
      ></div>

      {contact && (
        <Button
          elemType="a"
          elemProps={{ target: contact.target, href: contact.url }}
          highContrast={accessibility.highContrast}
        >
          {t(contact.label)}
        </Button>
      )}

      <Button onClick={modal.close} highContrast={accessibility.highContrast}>
        {t('close')}
      </Button>
    </div>
  )
}
