import Button from '@components/button/Button'
import { FormikField, FormikTextInput } from '@components/textInput/TextInput'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generateRandomNumber } from 'utils/FormatUtil'
import * as Yup from 'yup'
import { CustomChatAttributes } from '../../Types'
import Checkbox from '../../components/checkboxTermAccept/Checkbox'
import { useAccessibility } from '../../providers/AccessibilityProvider'
import { useEmbedParams } from '../../providers/EmbedParamsProvider'
import { RadioButton } from './RaditoButton'

const initialValues = {
  name: '',
  email: '',
  phone: '',
  nickname: '',
  is_whatsapp: 'false',
  termAccept: false,
}

type ChatFormModalProps = {
  onSubmit(values: typeof initialValues): void
  loading?: boolean
  chatForm?: CustomChatAttributes
}

export function ChatFormModal(props: ChatFormModalProps) {
  const { accessibility } = useAccessibility()
  const liveId = useEmbedParams().liveId
  const termAccept = localStorage.getItem(`__mimo__${liveId}__termAccept`)
  const { t, i18n } = useTranslation()
  const [filtered, setFiltered] = useState<any>({})

  const invalid_email = t('invalid_email')
  const required_field = t('required_field')

  const maxLengthOfPhone = i18n.language === 'pt-BR' ? 12 : 16

  const validationCustomAttributes =
    props.chatForm && props.chatForm?.custom_attributes?.length > 0

  useEffect(() => {
    const newFiltered: any = {}

    if (props.chatForm) {
      const newObj: any = props.chatForm
      Object.keys(newObj).forEach((key: any) => {
        switch (key) {
          case 'email':
            if (newObj[key]) {
              newFiltered[key] = Yup.string()
                .email(invalid_email)
                .required(required_field)
            }
            break
          case 'phone':
            if (newObj[key]) {
              newFiltered[key] = Yup.string()
                .min(9, 'eita tá pequeno')
                .required(required_field)
            }
            break
          case 'custom_attributes':
            newObj![key].map((item: any) => {
              if (item.isEnabled && item.type !== 'boolean')
                newFiltered[item.name] = Yup.string().required(required_field)
            })
            break
          default:
            if (newObj[key]) {
              newFiltered[key] = Yup.string().required(required_field)
            }
            break
        }
      })

      setFiltered(newFiltered)
    }
  }, [props.chatForm])

  const customFields: any =
    validationCustomAttributes &&
    props.chatForm?.custom_attributes?.map((item: any) => {
      if (item.isEnabled) {
        if (item.type === 'boolean') return { [item.name]: 'false' }
        return { [item.name]: '' }
      }
    })

  const ValidationSchema = Yup.object().shape(filtered)

  function onSubmit(value: any) {
    const customObj: any = {}

    for (const i in value) {
      if (value[i] === 'true' || value[i] === 'false') {
        value[i] = value[i] === 'true'
      }

      if (i === 'phone' && value[i]) {
        if (i18n.language === 'pt-BR') value[i] = '55' + value[i]
        value[i] = value[i].replace(/[()+\-\s]/g, '')
      }

      if (
        i !== 'phone' &&
        i !== 'nickname' &&
        i !== 'name' &&
        i !== 'is_whatsapp' &&
        i !== 'email' &&
        i !== 'termAccept'
      ) {
        customObj[i] = value[i]
      }
    }
    value['custom_attributes'] = customObj
    const userNumber = generateRandomNumber().toString()
    value.name = value.name || value.nickname || `${t('user')}${userNumber}`
    value.email = value.email || `${t('user')}${userNumber}@mimochat.com.br`
    props.onSubmit(value)
  }

  return (
    <div className="px-4 pb-4 max-h-screen max-w-sm">
      <div className="pb-4">{t('please_enter_your_details')}</div>
      <Formik
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
        initialValues={Object.assign(initialValues, customFields[0])}
      >
        {(_formik) => {
          return (
            <Form className="space-y-2" autoComplete="off">
              <div className="space-y-2 flex flex-col w-full max-h-[390px] overflow-y-auto scrollbar pr-1 pl-px py-px">
                {props.chatForm?.name && (
                  <FormikField name="name">
                    <FormikTextInput
                      placeholder={t('name')}
                      type="text"
                      name="name"
                    />
                  </FormikField>
                )}
                {props.chatForm?.nickname && (
                  <FormikField name="nickname">
                    <FormikTextInput
                      placeholder={t('nickname')}
                      type="text"
                      name="nickname"
                    />
                  </FormikField>
                )}
                {props.chatForm?.email && (
                  <FormikField name="email">
                    <FormikTextInput
                      placeholder={t('email')}
                      type="text"
                      name="email"
                    />
                  </FormikField>
                )}
                {props.chatForm?.phone && (
                  <>
                    <FormikField name="phone">
                      <FormikTextInput
                        placeholder={t('phone')}
                        type="text"
                        name="phone"
                        max={maxLengthOfPhone}
                      />
                    </FormikField>
                    {props.chatForm?.is_whatsapp && (
                      <RadioButton text="isWhatsapp" name="is_whatsapp" />
                    )}
                  </>
                )}

                {validationCustomAttributes &&
                  props.chatForm?.custom_attributes.map((field: any) => {
                    if (!field.isEnabled) return
                    return (
                      field.isEnabled &&
                      (field.type !== 'boolean' ? (
                        <FormikField name={field.name} key={field.name}>
                          <FormikTextInput
                            placeholder={t(`${field.name}`)}
                            type={
                              field.type === 'integer' ? 'number' : field.type
                            }
                            name={field.name}
                          />
                        </FormikField>
                      ) : (
                        <RadioButton key={field.name} name={field.name} />
                      ))
                    )
                  })}
              </div>

              {!termAccept ? (
                <div className="pt-2 ml-7 mr-3 text-14">
                  <Checkbox
                    id="termAccept"
                    className="termAccept"
                    name="termAccept"
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="pt-2">
                <Button
                  loading={props.loading}
                  buttonType="submit"
                  className="w-full"
                  disabled={!_formik.values.termAccept && !termAccept}
                  highContrast={accessibility.highContrast}
                >
                  {t('confirm')}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
