import React, { ReactNode } from 'react'

export type LiveTemplateProps = {
  top: ReactNode
  middle: ReactNode
  bottom: ReactNode
  right: ReactNode
}

export default function LiveTemplate(props: LiveTemplateProps) {
  return (
    <div className="w-full h-full absolute left-0 top-0 flex flex-row p-2.5">
      <div className="flex-1">
        <div className=" flex flex-col h-full  flex-1 space-y-2 ">
          <div id="l-top" className="relative z-2-overlay">
            {props.top}
          </div>
          <div id="l-middle" className="flex-1 flex">
            {props.middle}
          </div>
          <div
            id="l-bottom"
            className="flex flex-col min-h-0 relative z-2-overlay max-h-[40%]"
          >
            {props.bottom}
          </div>
        </div>
      </div>
      <div className="relative z-2-overlay">{props.right}</div>
    </div>
  )
}
