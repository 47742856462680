import FloatingButton from '@components/floatingButton/FloatingButton'
import { CartIcon } from '@icons/CartIcon'
import { CheckoutWhatsappConfig, MimoId, Product, ProductVariant } from 'Types'
import { useObservableCallback, useSubscription } from 'observable-hooks'
import { CheckoutProviderContext } from 'providers/CheckoutProvider'
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { catchError, mapTo, of, switchMap, tap } from 'rxjs'
import { useActionLoop, useSelector } from 'store/Store'
import { checkoutReducer } from 'store/checkoutReducer'
import DefaultModal from '../../components/defaultModal/DefaultModal'
import { useToaster } from '../../components/toaster/Toaster'
import { API_VERSION } from '../../consts'
import { HTTPError, jsonFetch } from '../../http/http'
import { useModal } from '../../modal'
import {
  CheckoutFinishedModal,
  CheckoutFinishedModalProps,
} from '../../modals/checkoutFinishedModal/CheckoutFinishedModal'
import { UserFormModal } from '../../modals/userFormModal/UserFormModal'
import { useMimoRouter } from '../../router/MimoRouter'
import { PATHS } from '../../router/Paths'
import {
  getCartCount,
  getCartItemsForTracking,
  getCartTotal,
} from '../../utils/CartUtils'
import { generateURL } from '../../utils/FormatUtil'
import { useConfig } from '../ConfigProvider'
import { useEmbedParams } from '../EmbedParamsProvider'
import { useSettings } from '../SettingsProviders'
import { useTracking } from '../TrackingProvider'

type FinishCartPayload = {
  name: string
  email: string
  phone: string
  variants: { id: MimoId; quantity: number }[]
}

export function CheckoutWhatsappProvider({
  config,
  children,
}: PropsWithChildren<{
  config: CheckoutWhatsappConfig
}>) {
  const dispatch = useDispatch()

  const router = useMimoRouter()

  const [showToaster] = useToaster()

  const { t } = useTranslation()

  const intentAddToCart = useActionLoop(
    checkoutReducer.actions.intent_addToCart,
    (_action) => {
      dispatch(checkoutReducer.actions.addToCart(_action.payload))
      showToaster(t('product_added_to_cart'))
      router.go(PATHS.home)
    }
  )

  const cart = useSelector((state) => state.checkout.cart)

  const addToCart = useCallback(
    (product: Product, variant: ProductVariant, quantity: number) => {
      intentAddToCart({
        product,
        quantity,
        variant,
      })
    },
    [intentAddToCart]
  )

  const editCart = useCallback(
    (product: Product, variant: ProductVariant, quantity: number) => {
      dispatch(
        checkoutReducer.actions.editCart({
          product,
          variant,
          quantity,
        })
      )
    },
    [dispatch]
  )

  const cartCount = useMemo(() => getCartCount(cart), [cart])

  const renderCartButton = useCallback(() => {
    return (
      <FloatingButton
        color="primary"
        onClick={() => {
          tracking.track({
            event: 'view_cart',
            currency: currency,
            value: cartTotal,
            items: cartTrackingItems,
          })
          router.go(PATHS.cart)
        }}
        circled={true}
        count={cartCount > 0 ? cartCount : undefined}
        icon={<CartIcon className="text-text" />}
        ariaLabel={t('cart')}
      />
    )
  }, [router, cartCount])

  const params = useEmbedParams()

  const [isFinishing, setIsFinishing] = useState(false)

  const tracking = useTracking()
  const currency = useConfig().settings?.currency?.code
  const cartTotal = useMemo(() => {
    return getCartTotal(cart)
  }, [cart])
  const cartTrackingItems = useMemo(() => {
    return getCartItemsForTracking(cart, currency)
  }, [cart, currency])

  // POST - send finish cart post
  const [finishCartPost, finishCartPost$] = useObservableCallback<
    string,
    FinishCartPayload
  >(($evt) =>
    $evt.pipe(
      switchMap((payload) => {
        setIsFinishing(true)
        return jsonFetch<{ success: boolean; message: string }>(
          generateURL(config.meta.checkout.endpoint, {
            MIMO_LIVE_ID: params.liveId!,
            MIMO_API_VERSION: API_VERSION,
          }),
          {
            method: 'POST',
            body: JSON.stringify(payload),
          }
        ).pipe(
          tap<any>((res) => {
            dispatch(checkoutReducer.actions.resetCart())
            res.text = `${t('order_number')} <b>${res.text}</b><br><br>${t(
              'order_created'
            )}`

            if (!config.meta.checkout.skip_modal) {
              res.contact.label = 'open_again'
              const opener = window.open(res.contact.url, res.contact.target)
              if (!opener) res.text = t('redirect_whatsapp')
            }
            successModal.open(res)
            formModal.close()
            router.go(PATHS.home)
          }),
          catchError((err: HTTPError) => {
            console.error(err)
            showToaster(err.message || t('unable_to_add_to_cart'))
            // setIsAddingToCart(false)
            return of('ok')
          }),
          tap(() => {
            setIsFinishing(false)
          }),
          mapTo('ok')
        )
      })
    )
  )

  useSubscription(finishCartPost$)

  const onFormSubmit = useCallback(
    (data: {
      name: string
      email: string
      phone: string
      termAccept: boolean
    }) => {
      localStorage.setItem(
        `__mimo__${params.liveId}__termAccept`,
        JSON.stringify({ termAccept: data.termAccept })
      )

      const formData = {
        ...data,
        variants: cart.map((v) => ({
          id: v.variant.id,
          quantity: v.quantity,
        })),
      }
      finishCartPost(formData)
    },
    [finishCartPost, cart]
  )

  const defaultCheckoutData = useSelector((v) => {
    if (
      v.checkout.user &&
      (v.checkout.user.name || v.checkout.user?.phone || v.checkout.user?.email)
    ) {
      return v.checkout.user
    } else if (v.chat.user) {
      return v.chat.user
    } else {
      return {}
    }
  })

  const formModal = useModal({
    component: (
      <DefaultModal title={t('checkout_now')}>
        <UserFormModal
          loading={isFinishing}
          initialValues={defaultCheckoutData}
          onSubmit={(data) => {
            onFormSubmit(data)
            tracking.track({
              event: 'purchase',
              currency: currency,
              value: cartTotal,
              transaction_id: 0,
              items: cartTrackingItems,
            })
          }}
        />
      </DefaultModal>
    ),
    open: false,
  })

  const successModal = useModal({
    component: (
      <CheckoutFinishedModalWithMeta
        // meta will be sent by modal.open
        meta={{ contact: { label: '', target: '', url: '' }, text: '' }}
      />
    ),
    open: false,
  })

  const getStockInfo = useRealtimeStock()

  const finishCart = useCallback(() => {
    formModal.open()
  }, [formModal])

  const contextValue = useMemo(() => {
    return {
      getStockInfo,
      finishCart,
      renderCartButton,
      type: config.type,
      addToCart,
      isAddingToCart: false,
      allowQuantityControl: true,
      cart,
      editCart,
      cartCount,
    }
  }, [
    getStockInfo,
    config,
    addToCart,
    finishCart,
    renderCartButton,
    cart,
    editCart,
    cartCount,
  ])

  return (
    <CheckoutProviderContext.Provider value={contextValue}>
      {formModal.elem}
      {successModal.elem}
      {children}
    </CheckoutProviderContext.Provider>
  )
}

function CheckoutFinishedModalWithMeta(props: {
  meta: CheckoutFinishedModalProps
}) {
  const { t } = useTranslation()
  return (
    <DefaultModal title={t('checkout_now')}>
      <CheckoutFinishedModal {...props.meta}></CheckoutFinishedModal>
    </DefaultModal>
  )
}

function useRealtimeStock() {
  const { settings } = useSettings()

  const stock = settings?.stock

  const getStockInfo = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    (v: ProductVariant, quantity: number = 1) => {
      if (!v) {
        return { available: false, quantityAvailable: false }
      }
      const info = stock?.[v.id] || {
        quantity: 0,
        purchased: 0,
      }

      return {
        available: (info.purchased || 0) < (info.quantity || 0),
        quantityAvailable:
          (info.quantity || 0) >= (info.purchased || 0) + quantity,
      }
    },
    [stock]
  )

  return getStockInfo
}
