import Button from '@components/button/Button'
import { FormikField, FormikTextInput } from '@components/textInput/TextInput'
import { Form, Formik, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import Checkbox from '../../components/checkboxTermAccept/Checkbox'
import { useAccessibility } from '../../providers/AccessibilityProvider'
import { useEmbedParams } from '../../providers/EmbedParamsProvider'
import { CheckoutUser, checkoutReducer } from '../../store/checkoutReducer'

const initialValues = { name: '', email: '', phone: '', termAccept: false }

type UserFormModalProps = {
  onSubmit(values: typeof initialValues): void
  initialValues: Partial<typeof initialValues> | null
  loading?: boolean
}

function FormikFormCheckoutUserPersist() {
  const dispatch = useDispatch()

  const context = useFormikContext<CheckoutUser>()

  useEffect(() => {
    dispatch(checkoutReducer.actions.setUser({ ...context.values }))
  }, [dispatch, context.values])

  return null
}

export function UserFormModal(props: UserFormModalProps) {
  const { accessibility } = useAccessibility()
  const liveId = useEmbedParams().liveId
  const termAccept = localStorage.getItem(`__mimo__${liveId}__termAccept`)
  const { t } = useTranslation()

  const invalid_email = t('invalid_email')
  const required_field = t('required_field')

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email(invalid_email).required(required_field),
    name: Yup.string().required(required_field),
    phone: Yup.string().required(required_field),
  })

  return (
    <div className="px-4 pb-4 max-h-screen max-w-sm">
      <div className="pb-4">{t('info_to_order')}</div>
      <Formik
        validationSchema={ValidationSchema}
        onSubmit={props.onSubmit}
        initialValues={{ ...initialValues, ...props.initialValues }}
      >
        {(_formik) => {
          return (
            <Form className="space-y-2">
              <FormikFormCheckoutUserPersist />

              <FormikField name="name">
                <FormikTextInput
                  placeholder={t('name')}
                  type="text"
                  name="name"
                />
              </FormikField>

              <FormikField name="email">
                <FormikTextInput
                  placeholder={t('email')}
                  type="text"
                  name="email"
                />
              </FormikField>

              <FormikField name="phone">
                <FormikTextInput
                  placeholder={t('phone')}
                  type="text"
                  name="phone"
                  //   maskProps={{ mask: '(99)999999999' }} // TODO: Put mask back once shadowdom bug is fixed
                />
              </FormikField>

              {!termAccept ? (
                <div className="pt-2 ml-7 mr-3 text-14">
                  <Checkbox
                    id="termAccept"
                    className="termAccept"
                    name="termAccept"
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="pt-2">
                <Button
                  loading={props.loading}
                  buttonType="submit"
                  className="w-full"
                  disabled={!_formik.values.termAccept && !termAccept}
                  highContrast={accessibility.highContrast}
                >
                  {t('confirm')}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
