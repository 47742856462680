import { createContext, useContext } from 'react'
import { Product, ProductVariant } from 'Types'
import { CartItem } from '../store/checkoutReducer'

export type StockInfo = { available: boolean; quantityAvailable: boolean }

export type CheckoutContext = {
  renderCartButton(): JSX.Element | null
  type: string
  addToCart(product: Product, variant: ProductVariant, quantity: number): void
  editCart(product: Product, variant: ProductVariant, quantity: number): void
  isAddingToCart: boolean
  addToCartLabel?: string
  allowQuantityControl: boolean
  cart: CartItem[]
  cartCount: number
  finishCart(): void
  getStockInfo(variant?: ProductVariant, quantity?: number): StockInfo
  isFinishing?: boolean
}

export const CheckoutProviderContext = createContext<CheckoutContext>({
  getStockInfo() {
    return { available: true, quantityAvailable: true }
  },
  renderCartButton() {
    return null
  },
  finishCart() {
    throw new Error('CheckoutProvider not initiated')
  },
  cartCount: 0,
  addToCart: () => {
    throw new Error('CheckoutProvider not initiated')
  },
  editCart: () => {
    throw new Error('CheckoutProvider not initiated')
  },
  allowQuantityControl: false,
  type: 'disabled',
  isAddingToCart: false,
  cart: [],
})

export function useCheckout() {
  return useContext(CheckoutProviderContext)
}
