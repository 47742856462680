import clsx from 'clsx'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { bgURL } from '../../utils/FormatUtil'

export type ImageGalleryProps = PropsWithChildren<{
  images: { thumb?: string; image: string }[]
}>

export default function ImageGallery(props: ImageGalleryProps) {
  const { t } = useTranslation()
  const { images } = props
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    setCurrentImage(0)
  }, [images, setCurrentImage])

  const onScroll = useCallback(
    ({ currentTarget }: { currentTarget: HTMLElement }) => {
      const elem = currentTarget

      const { clientHeight, scrollHeight, scrollTop } = elem

      const hasScroll = clientHeight >= scrollHeight

      if (hasScroll || clientHeight + scrollTop >= scrollHeight) {
        endGradientRef.current?.classList.add('opacity-0')
        endGradientRef.current?.classList.add('opacity-0')
      } else {
        //middle
        endGradientRef.current?.classList.remove('opacity-0')
      }

      if (hasScroll || scrollTop === 0) {
        startGradientRef.current?.classList.add('opacity-0')
      } else {
        //middle
        startGradientRef.current?.classList.remove('opacity-0')
      }
    },
    []
  )

  const startGradientRef = useRef<HTMLDivElement>(null)
  const endGradientRef = useRef<HTMLDivElement>(null)

  return (
    <div className="grid grid-cols-5 gap-2">
      <div className="col-span-4">
        <div className="aspect-w-1 aspect-h-1 relative">
          <div className="absolute inset-0 flex items-center justify-center rounded-3xl overflow-hidden">
            <img
              className="w-full h-full object-contain"
              src={
                images[currentImage]?.image ||
                images[0]?.image ||
                import.meta.env.VITE_S3_BUCKET_ASSETS +
                  '/product_image/no-product-img.png'
              }
              alt={t('product_image')}
            />
          </div>
        </div>
      </div>
      <div className="col-span-1 relative rounded-lg overflow-hidden">
        <div
          ref={startGradientRef}
          className="bg-gradient-to-b from-black h-8 absolute left-0 w-full z-3-page transition-all duration-300 pointer-events-none"
        />
        <div
          ref={endGradientRef}
          className="bg-gradient-to-b from-transparent to-black h-8 absolute transition-all duration-300 bottom-0 left-0 w-full z-3-page pointer-events-none"
        />

        <div
          ref={(obj) => {
            obj && onScroll({ currentTarget: obj })
          }}
          onScroll={onScroll}
          className="absolute left-0 top-0 h-full w-full overflow-auto scroll-snap-y space-y-2"
          role="list"
          aria-label={t('image_gallery')}
        >
          {images.map((v, k) => (
            <button
              onClick={() => {
                setCurrentImage(k)
              }}
              key={k}
              style={{ backgroundImage: bgURL(v.thumb || v.image) }}
              className={clsx(
                'aspect-w-1 aspect-h-1 box-border block w-full bg-cover bg-no-repeat bg-center rounded-lg relative'
              )}
              aria-label={t('product_image') + ' ' + (k + 1)}
            >
              {k === currentImage && (
                <div className="inset-0 absolute border-2 border-ctaPrimary rounded-lg" />
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
