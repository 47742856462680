import React, { PropsWithChildren } from 'react'

export type BulletButtonProps = PropsWithChildren<{
  onClick(): void
}>

BulletButton.defaultProps = {
  onClick: () => {},
}

export default function BulletButton(props: BulletButtonProps) {
  return (
    <button
      onClick={props.onClick}
      className="text-immutableBlack bg-immutableWhite-bgop bg-opacity-70 text-10 text-center w-full overflow-hidden rounded-full font-medium block px-1 py-1"
    >
      {props.children}
    </button>
  )
}
