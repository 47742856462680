import TextInput from '@components/textInput/TextInput'
import { EmailIcon } from '@icons/EmailIcon'
import { FacebookIcon } from '@icons/FacebookIcon'
import { TwitterIcon } from '@icons/TwitterIcon'
import { WhatsIcon } from '@icons/WhatsIcon'
import React, { PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { copyToClipboard } from 'utils/copyToClipboard'
import { useTracking } from '../../providers/TrackingProvider'
import BtnIcon from './btnIcon/BtnIcon'

export type ShareBlockProps = PropsWithChildren<{
  url: string
  text: string
}>

const popupShare = function (url: string) {
  window.open(
    url,
    'shareWindow',
    'height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
  )
}

/**
 * TODO: Avaliar quando tiver o modal, para ver se não vale apena deixar centralizado os ícones
 */
export default function ShareBlock(props: ShareBlockProps) {
  const { url, text } = props
  const { t } = useTranslation()
  const tracking = useTracking()

  const onCopyClick = useCallback(() => {
    copyToClipboard(url).then(function () {
      //TODO: SHOW TOAST
    })
  }, [url])

  const onFacebookClick = useCallback(() => {
    tracking.track( { event: 'share', method: 'Facebook', item_id: url })
    popupShare(`https://www.facebook.com/sharer/sharer.php?u=${url}`)
  }, [url, tracking])

  const onTwitterClick = useCallback(() => {
    tracking.track( { event: 'share', method: 'Twitter', item_id: url })
    popupShare(`http://twitter.com/share?text=${text}&url=${url}`)
  }, [url, text, tracking])

  const onFocus = useCallback((e) => {
    const input = e.target as HTMLInputElement
    const selectAll = () => input.setSelectionRange(0, input.value.length)
    selectAll()
    requestAnimationFrame(selectAll)
  }, [])
  return (
    <div className="relative">
      <div className="flex flex-row justify-flex-start">
        <button aria-label="Facebook" onClick={onFacebookClick}>
          <BtnIcon label="Facebook">
            <FacebookIcon className="text-[#4259D2]" />
          </BtnIcon>
        </button>
        <a
          href={`https://api.whatsapp.com/send?text=${text} ${url}`}
          title="Whatsapp"
          target="_blank"
          rel="noreferrer"
          onClick={() => { tracking.track( { event: 'share', method: 'Whatsapp', item_id: url }) }}
        >
          <BtnIcon label="Whatsapp">
            <WhatsIcon className="text-[#2AAE1F]" />
          </BtnIcon>
        </a>

        <button onClick={onTwitterClick}>
          <BtnIcon label="Twitter">
            <TwitterIcon className="text-[#2BB6F2]" />
          </BtnIcon>
        </button>
        <a
          href={`mailto:?subject=Mimo&text=${text}`}
          title={t('email')}
          target="_blank"
          rel="noreferrer"
          onClick={() => { tracking.track( { event: 'share', method: 'Email', item_id: url }) }}
        >
          <BtnIcon label={t('email')}>
            <EmailIcon className="text-ctaPrimary" />
          </BtnIcon>
        </a>
      </div>

      <div className="relative mt-4">
        <TextInput
          className="pr-[80px] overflow-ellipsis overflow-hidden"
          onClick={onFocus}
          onFocus={onFocus}
          name="url"
          type="text"
          placeholder=""
          onChange={() => { }}
          value={url}
          readOnly={true}
        />

        <button
          type={'button'}
          onClick={onCopyClick}
          className={
            'absolute top-0 bg-immutableWhite right-0 bottom-0 font-barlow-cond font-semibold uppercase rounded-full py-1 min-h-[48px] px-5 text-center text-16 text-ctaPrimary'
          }
        >
          {t('copy')}
        </button>
      </div>
    </div >
  )
}
