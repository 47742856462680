import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Message, Product } from 'Types'
import { createPersistFactory, PersistFactory } from './persist'
import { identity } from './reducerUtils'

export type ChatUser = Record<string, any>

export type ChatState = {
  messages: Message[]
  visible: boolean
  user: ChatUser | null
}

/** Please only use this for action helpers or testing, this checkout reducer isn't binded to the liveID */
export const chatReducer = createChatReducer(createPersistFactory('not-set'))

export function createChatReducer(persistFactory: PersistFactory) {
  const persistChatUser = persistFactory('chatUser')

  const initialState: ChatState = {
    visible: true,
    user: persistChatUser.get(null),
    messages: [],
  }

  return createSlice({
    name: 'chat',
    initialState: initialState,
    reducers: {
      // STATE REDUCERS
      setUser(state, action: PayloadAction<ChatUser>) {
        return { ...state, user: persistChatUser.set(action.payload) }
      },
      toggleVisibility(state) {
        return { ...state, visible: !state.visible }
      },
      setVisibility(state, action: PayloadAction<boolean>) {
        return { ...state, visible: action.payload }
      },
      // INTENT REDUCERS
      intent_ChatMessageClick: identity<PayloadAction<Message>>(),
      intent_ChatMessageSend: identity<PayloadAction<string>>(),
      intent_AskForInformation: identity<PayloadAction<Product>>(),
      intent_UnpinnedMessage: identity<PayloadAction<Array<Message>>>(),
    },
  })
}
