import React from 'react'
import { AccessibilityMenu } from '../../components/AccessibilityMenu'
import LiveHeader from '../../components/liveHeader/LiveHeader'
import { useSettings } from '../../providers/SettingsProviders'
import { ShareButton } from '../shareButton/ShareButton'

export function HeaderDesktop() {
  const { settings } = useSettings()

  const logo = settings?.customer.logo
  const title =
    (!!settings?.uiSettings.showCustomerName &&
      settings?.customer.tradingName) ||
    undefined

  const subTitle =
    (!!settings?.uiSettings.showLiveTitle && settings?.live.title) || undefined

  const showImage = settings?.uiSettings.showCustomerLogo

  const showShare = !!settings?.live.shareURL && !!settings.uiSettings.showShare

  const showAccessibility = !!settings?.uiSettings.showAccessibility

  if (!showImage && !subTitle && !title && !showShare && !showAccessibility) {
    return null
  }

  return (
    <div className="bg-primary border-primary">
      <div className="py-6 pl-7 pr-8 flex justify-between items-center">
        <LiveHeader
          image={logo}
          title={title}
          subTitle={subTitle}
          showImage={showImage}
        />
        <div className="flex items-center justify-end gap-x-[24px] w-full">
          {showShare && <ShareButton />}
          {showAccessibility && (
            <AccessibilityMenu type="desktop" className="text-text" />
          )}
        </div>
      </div>
    </div>
  )
}
