import React from 'react'
import { useTranslation } from 'react-i18next'

export type LiveHeaderProps = {
  title?: string
  subTitle?: string
  image?: string
  showImage?: boolean
}

const defaultProps = {
  showImage: true,
}

export default function LiveHeader(props: LiveHeaderProps) {
  const { t } = useTranslation()
  const { image, title, subTitle, showImage } = props

  if (!showImage && !title && !subTitle) {
    return null
  }

  return (
    <div className="w-full">
      <div className="inline-flex text-text space-x-2 items-center z-2-overlay relative">
        {showImage && (
          <img
            className="w-12 h-12 rounded-lg"
            src={
              image || import.meta.env.VITE_S3_BUCKET_ASSETS + '/customer_logo/mimo.jpg'
            }
            alt={t('company_logo')}
          />
        )}
        <div className="space-y-1 leading-none py-1">
          {!!title && <div className="text-14 font-bold">{title}</div>}
          {!!subTitle && <div className="text-12">{subTitle}</div>}
        </div>
      </div>
    </div>
  )
}

LiveHeader.defaultProps = defaultProps
