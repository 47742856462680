import Button from '@components/button/Button'
import CollapsibleText from '@components/collapsibleText/CollapsibleText'
import Currency from '@components/currency/Currency'
import FloatingInfoPage from '@components/floatingInfoPage/FloatingInfoPage'
import ImageGallery from '@components/imageGallery/ImageGallery'
import OptionInput, { InputLabel } from '@components/optionInput/OptionInput'
import QuantityInput from '@components/quantityInput/QuantityInput'
import { useSettings } from '@providers/SettingsProviders'
import { MimoId, PageHandlerProps } from 'Types'
import clsx from 'clsx'
import { useCheckout } from 'providers/CheckoutProvider'
import { useProductById } from 'providers/ProductsProvider'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useMimoRouter } from 'router/MimoRouter'
import { arrayEqual, arrayRemoveChunk } from 'utils/ArrayUtil'
import { getProductThumb, getVariantOrDefault } from 'utils/ProductUtil'
import { useCheckoutEnabled } from '../../hooks/useCheckoutHooks'
import { useAccessibility } from '../../providers/AccessibilityProvider'
import { useConfig } from '../../providers/ConfigProvider'
import { useTracking } from '../../providers/TrackingProvider'

export function ProductInfoPage({ type }: PageHandlerProps) {
  const { accessibility } = useAccessibility()
  const modalRef = useRef<HTMLDivElement>(null)
  const router = useMimoRouter()

  // This cache is created to prevent the page from going blank when the modal is closed
  const [routeState, setRouteState] = useState<{
    prodId: null | number
    variantId: null | number
  }>({
    prodId: null,
    variantId: null,
  })

  const checkoutEnabled = useCheckoutEnabled()

  const [selectedOptions, setSelectedOptions] = useState<MimoId[]>([])
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1)

  const routerProdId = router.params.id
  const routerVariantId = router.params.variantId

  useEffect(() => {
    // This cache is created to prevent the page from going blank when the modal is closed
    if (!routerProdId) {
      return
    }
    if (
      routerProdId !== routeState.prodId ||
      routerVariantId !== routeState.variantId
    ) {
      setRouteState({
        prodId: routerProdId,
        variantId: routerVariantId,
      })
    }
  }, [routerProdId, routerVariantId, setRouteState, routeState])

  const product = useProductById(routeState.prodId || -1)

  useEffect(() => {
    if (!product) {
      return
    }
    const defaultVariant = getVariantOrDefault(product, routerVariantId)

    setSelectedOptions(defaultVariant?.optionItems || [])
  }, [product, setSelectedOptions, routerVariantId])

  const selectedVariant = useMemo(
    () =>
      product?.variants.find((variant) =>
        arrayEqual(variant.optionItems, selectedOptions)
      ),
    [product, selectedOptions]
  )
  const checkout = useCheckout()

  const { settings } = useSettings()

  const stockInfo = checkout.getStockInfo(selectedVariant, selectedQuantity)

  const variantImages = selectedVariant?.images || []
  const productImages = product?.images || []

  const addToCart = checkout.addToCart
  const addToCartLabel = checkout.addToCartLabel
  const isAddingToCart = checkout.isAddingToCart

  const { t } = useTranslation()
  const tracking = useTracking()
  const currency = useConfig().settings?.currency?.code

  const onAddToCartClick = useCallback(() => {
    product &&
      selectedVariant &&
      addToCart(product, selectedVariant, selectedQuantity)
    tracking.track({
      event: 'add_to_cart',
      currency: currency,
      value: (selectedVariant?.price || 0) * selectedQuantity,
      items: [
        {
          item_id: selectedVariant?.sku,
          item_name: product?.title,
          currency: currency,
          price: selectedVariant?.price,
          quantity: selectedQuantity,
          image: getProductThumb(product, selectedVariant),
        },
      ],
    })
  }, [addToCart, product, selectedVariant, selectedQuantity, tracking])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (modalRef.current) modalRef.current.focus()
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div
      ref={modalRef}
      tabIndex={-1}
      className="absolute bottom-0 w-full left-0 z-3-page"
      role="dialog"
      aria-label={t('product_info')}
      aria-modal
    >
      <FloatingInfoPage type={type} onClose={router.goBack}>
        <div className="overflow-auto desk:max-h-[calc(var(--maxHeight,100vh)-225px)] mob:max-h-[calc(0.5*var(--maxHeight,100vh))] py-4 pb-4 scrollbar">
          <div className="px-4 space-y-4">
            {product?.images && product?.images?.length > 0 && (
              <ImageGallery
                images={
                  variantImages.length > 0 ? variantImages : productImages
                }
              />
            )}

            <div className="space-y-2">
              <div className="font-bold text-20">
                {product?.title}
                {!!settings?.uiSettings.showSkuOnProduct &&
                  selectedVariant?.sku}
              </div>
              {!!settings?.uiSettings.showProductPrice && (
                <div className="flex space-x-2 items-center">
                  <Currency
                    value={selectedVariant?.price || 0}
                    className="font-bold text-16"
                  />
                  {selectedVariant &&
                    selectedVariant.priceCompare > selectedVariant?.price && (
                      <span aria-hidden>
                        <Currency
                          value={selectedVariant?.priceCompare}
                          className="text-14 text-subtext line-through"
                        />
                      </span>
                    )}
                </div>
              )}
            </div>

            {product?.description && (
              <div className="text-14">
                {product.description.length > 200 ? (
                  <CollapsibleText className="min-h-[3em]">
                    <div className="text-subtext">{product?.description}</div>
                  </CollapsibleText>
                ) : (
                  <div className="text-subtext">{product?.description}</div>
                )}
              </div>
            )}

            <div
              className={clsx({
                invisible:
                  product?.options?.length == 1 &&
                  product?.options[0].name == '', //Variant com "ONE OPTION EMPTY"
              })}
            >
              {product?.options?.map((option, i) => {
                return (
                  <InputLabel key={i} label={option.name}>
                    <OptionInput
                      value={selectedOptions.find(
                        (v) => !!option.values.find((o) => o.id === v)
                      )}
                      onChange={(v) => {
                        setSelectedOptions([
                          ...arrayRemoveChunk(
                            selectedOptions,
                            option.values.map((v) => v.id)
                          ),
                          v,
                        ])
                      }}
                      data={option}
                    />
                  </InputLabel>
                )
              })}
            </div>

            {checkoutEnabled && (
              <>
                {stockInfo.available &&
                  selectedVariant &&
                  checkout.allowQuantityControl && (
                    <InputLabel label={t('amount').toUpperCase()}>
                      <div className="text-18">
                        <QuantityInput
                          onChange={setSelectedQuantity}
                          value={selectedQuantity}
                        />
                      </div>
                    </InputLabel>
                  )}

                {stockInfo.available && !stockInfo.quantityAvailable && (
                  <div className="font-barlow-cond text-18 font-bold">
                    {t('unavailable_for_selected_quantity')}
                  </div>
                )}

                {!stockInfo.available && (
                  <div className="font-barlow-cond text-18 font-bold">
                    {t('product_unavailable')}
                  </div>
                )}

                {!!settings?.uiSettings.showAddToCartButton &&
                  stockInfo.available &&
                  stockInfo.quantityAvailable && (
                    <div className="text-16 flex justify-between space-x-4">
                      <Button
                        onClick={onAddToCartClick}
                        disabled={!selectedVariant}
                        className="flex-grow"
                        loading={isAddingToCart}
                        highContrast={accessibility.highContrast}
                      >
                        {t(addToCartLabel || 'add_to_card').toUpperCase()}
                      </Button>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </FloatingInfoPage>
    </div>
  )
}

export function ProductsInfoHandler({ type }: PageHandlerProps) {
  const route = useMimoRouter()

  return (
    <TransitionGroup data-testid="ProductsInfoHandler">
      {route.meta?.showProductInfo && (
        <CSSTransition timeout={500} classNames="animate-from-bottom">
          <ProductInfoPage type={type} />
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
