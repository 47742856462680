import FloatingPage from '@components/floatingPage/FloatingPage'
import { useModalContent } from 'modal'
import React, { PropsWithChildren, useEffect, useRef } from 'react'

type DefaultModalProps = PropsWithChildren<{
  //
  title: string
}>
export default function DefaultModal(props: DefaultModalProps) {
  const modalRef = useRef<HTMLDivElement>(null)
  const context = useModalContent()

  const { title } = props

  useEffect(() => {
    if (modalRef.current) modalRef.current.focus()
  }, [])

  return (
    <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center z-4-modal">
      {/* Foreground */}
      <div
        role="none"
        onClick={context.close}
        className="absolute left-0 top-0 w-full h-full z-4-modal bg-primary-bgop bg-opacity-75"
      />
      {/* Content */}
      <div
        ref={modalRef}
        role="dialog"
        aria-modal
        tabIndex={-1}
        className="z-4-modal-content relative "
      >
        <FloatingPage title={title} asModal={true} onClose={context.close}>
          <div className="p-4">{props.children}</div>
        </FloatingPage>
      </div>
    </div>
  )
}
