import { MimoId, Product, ProductVariant } from 'Types'

function getDefaultVariant(product: Product) {
  return product.variants.find((v) => v.isDefault)
}

export function getVariantOrDefault(product: Product, variantId?: MimoId) {
  return (
    product?.variants.find((v) => v.id == variantId) ||
    getDefaultVariant(product) ||
    product?.variants[0]
  )
}

export function getProductThumb(product?: Product, variant?: ProductVariant) {
  return (
    variant?.images[0]?.thumb ||
    variant?.images[0]?.image ||
    (product?.images && product?.images[0]?.image) ||
    undefined
  )
}

export function getVariantTitle(product: Product, variant: ProductVariant) {
  return variant.optionItems
    .map((optionItemId) => {
      const option = product.options.find((option) =>
        option.values.find((optionValue) => optionValue.id === optionItemId)
      )

      const optionValue = option?.values.find(
        (optionValue) => optionValue.id === optionItemId
      )

      return optionValue?.name
    })
    .join(' - ')
}
