import { FatalErrorAlert } from '@components/fatalErrorAlert/FatalErrorAlert'
import React, { PropsWithChildren } from 'react'

type ErrorHandlerProps = PropsWithChildren<{
  //
}>

export class ErrorHandler extends React.Component<
  ErrorHandlerProps,
  {
    error?: any
    errorInfo?: any
  }
> {
  constructor(props: ErrorHandlerProps) {
    super(props)
    this.state = { error: undefined }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo)
    this.setState({ error: error, errorInfo: errorInfo })
  }
  render() {
    if (this.state.error) {
      return (
        <FatalErrorAlert
          error={this.state.error?.originalError || this.state.error}
          title={this.state.error.message || 'Unknown Error'}
        />
      )
    }
    return <>{this.props.children}</>
  }
}
