import FloatingButton from '@components/floatingButton/FloatingButton'
import { useToaster } from '@components/toaster/Toaster'
import { CartIcon } from '@icons/CartIcon'
import { CheckoutProviderContext } from 'providers/CheckoutProvider'
import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { checkoutReducer } from 'store/checkoutReducer'
import { useActionLoop } from 'store/Store'
import { CheckoutHeadlessConfig, Product, ProductVariant } from 'Types'

export function CheckoutHeadlessProvider({
  config,
  children,
}: PropsWithChildren<{
  config: CheckoutHeadlessConfig
}>) {
  const { t } = useTranslation()
  const [showToaster] = useToaster()

  const intentAddToCart = useActionLoop(
    checkoutReducer.actions.intent_addToCart,
    (_action) => {
      window.parent.postMessage(_action, '*')
      window.onmessage = function (msg) {
        if (msg.data?.type === 'core/intent_ShowToaster') {
          showToaster(msg.data.payload)
        }
      }
      // this intent event has no handler for now
    }
  )

  const addToCart = useCallback(
    (product: Product, variant: ProductVariant, quantity: number) => {
      intentAddToCart({
        product,
        quantity,
        variant,
      })
    },
    [intentAddToCart]
  )

  const renderCartButton = useCallback(() => {
    return config.meta.cartButton?.url ? (
      <a
        target={config.meta.cartButton.target || undefined}
        href={config.meta.cartButton.url}
        rel="noreferrer"
        aria-label={t('cart')}
      >
        <FloatingButton
          color="primary"
          circled={true}
          count={undefined}
          icon={<CartIcon className="text-text" />}
        />
      </a>
    ) : null
  }, [config.meta])

  const contextValue = useMemo(() => {
    return {
      renderCartButton,
      type: config.type,
      addToCart: addToCart,
      getStockInfo: () => ({ available: true, quantityAvailable: true }),
      isAddingToCart: false,
      allowQuantityControl: true,
      cart: [],
      editCart: () => {}, // n/a
      finishCart: () => {}, // n/a
      cartCount: 0,
    }
  }, [config, addToCart, renderCartButton])

  return (
    <CheckoutProviderContext.Provider value={contextValue}>
      {children}
    </CheckoutProviderContext.Provider>
  )
}
