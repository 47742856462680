import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MimoId, Product, ProductVariant } from 'Types'
import { createPersistFactory, PersistFactory } from './persist'
import { identity } from './reducerUtils'

export type CartItem = {
  product: Product
  variant: ProductVariant
  quantity: number
}

export type AddToCartPayload = {
  product: Product
  variant: ProductVariant
  quantity: number
}

export type CheckoutUser = {
  name: string
  email: string
  phone: string
}

export type CheckoutState = {
  cart: CartItem[]
  user?: CheckoutUser
}

/** Please only use this for action helpers, this checkout reducer isn't binded to the liveID */
export const checkoutReducer = createCheckoutReducer(
  createPersistFactory('default')
)

export function createCheckoutReducer(persistFactory: PersistFactory) {
  const persistCart = persistFactory('cart')
  const persistUserCheckout = persistFactory('userCheckout')

  const initialState: CheckoutState = {
    cart: persistCart.get([]),
    user: persistUserCheckout.get(undefined),
  }

  return createSlice({
    name: 'checkout',
    initialState: initialState,
    reducers: {
      // INTENT REDUCERS
      intent_addToCart: identity<PayloadAction<AddToCartPayload>>(),
      intent_addedToCart: identity<PayloadAction>(),
      addToCart(state, action: PayloadAction<CartItem>) {
        return {
          ...state,
          cart: persistCart.set(normalizeCart([...state.cart, action.payload])),
        }
      },
      editCart(state, { payload }: PayloadAction<CartItem>) {
        const newCart = state.cart.map((v) => {
          return v.variant.id === payload.variant.id ? payload : v
        })
        return {
          ...state,
          cart: persistCart.set(normalizeCart(newCart)),
        }
      },
      resetCart(state) {
        return { ...state, cart: persistCart.set([]) }
      },
      setUser(state, action: PayloadAction<CheckoutUser>) {
        return { ...state, user: persistUserCheckout.set(action.payload) }
      },
    },
  })
}

/**
 * Normalizes quantities of multiple cart items, reducing them to a single record.
 *
 * This function can also be used to remove items from the array, just adding a negative quantity to a record.
 */
export function normalizeCart<
  C extends {
    quantity: number
    variant: { id: MimoId }
  }
>(cart: C[]) {
  return cart
    .reduce((acc, next) => {
      const foundIndex = acc.findIndex((v) => v.variant.id === next.variant.id)

      if (foundIndex === -1) {
        acc.push(next)
      } else {
        const found = acc[foundIndex]
        acc[foundIndex] = { ...found, quantity: found.quantity + next.quantity }
      }

      return acc
    }, [] as C[])
    .filter((v) => v.quantity > 0)
}
