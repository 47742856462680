import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

export function BorderedBlock(
  props: PropsWithChildren<{
    //
    className?: string
  }>
) {
  return (
    <div
      className={clsx(
        'border-t border-b border-secondary px-6 bg-secondary',
        props.className
      )}
    >
      {props.children}
    </div>
  )
}
