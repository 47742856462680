import React from 'react'
import { IconProps } from 'Types'

/**
 * fill: #FF794E
 */
export function EmailIcon({ className }: IconProps) {
  return (
    <svg
      className={`icon ${className}`}
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="8" fill="currentColor" />
      <path
        d="M29.6 10H10.4C9.08 10 8.012 11.125 8.012 12.5L8 27.5C8 28.875 9.08 30 10.4 30H29.6C30.92 30 32 28.875 32 27.5V12.5C32 11.125 30.92 10 29.6 10ZM29.12 15.3125L20.636 20.8375C20.252 21.0875 19.748 21.0875 19.364 20.8375L10.88 15.3125C10.7597 15.2421 10.6543 15.1471 10.5703 15.0331C10.4862 14.9191 10.4253 14.7885 10.3911 14.6492C10.3569 14.51 10.3502 14.3649 10.3714 14.2229C10.3926 14.0808 10.4412 13.9448 10.5143 13.8229C10.5875 13.701 10.6836 13.5958 10.7969 13.5137C10.9102 13.4317 11.0382 13.3744 11.1734 13.3454C11.3085 13.3164 11.4479 13.3162 11.5831 13.3449C11.7184 13.3737 11.8466 13.4307 11.96 13.5125L20 18.75L28.04 13.5125C28.1534 13.4307 28.2817 13.3737 28.4169 13.3449C28.5521 13.3162 28.6915 13.3164 28.8266 13.3454C28.9618 13.3744 29.0898 13.4317 29.2031 13.5137C29.3164 13.5958 29.4125 13.701 29.4857 13.8229C29.5588 13.9448 29.6074 14.0808 29.6286 14.2229C29.6498 14.3649 29.6431 14.51 29.6089 14.6492C29.5747 14.7885 29.5138 14.9191 29.4297 15.0331C29.3457 15.1471 29.2403 15.2421 29.12 15.3125Z"
        fill="white"
      />
    </svg>
  )
}
