/* eslint-disable react-hooks/rules-of-hooks */
import 'firebase/database'
import React, { useContext } from 'react'
import { Observable } from 'rxjs'
import { Message, Product } from 'Types'
import { disabledContext } from './chat/ChatDisabledProvider'

export type ChatContext = {
  enabled: boolean
  setUser(user: ChatUser): void
  type: string
  messages?: Message[]
  pinnedMessage?: Message[]
  sendMessage(text: string): void
  isSendingMessage: boolean
  toggleVisibility(): void
  visible: boolean
  chatMessageClick?(msg: Message): void
  like(v: unknown): void
  likesDiff$: Observable<number>
  isSendingLike: boolean
  askForInformation?(product: Product): void
  chatUnpinnedMessage?(msg: Message[]): void
  liveCount?: number
}

type ChatUser = {
  id?: string
  name: string
  email: string
  termAccept: boolean
}

export const ChatProviderContext = React.createContext<ChatContext>(
  disabledContext
)

export function useChat() {
  return useContext(ChatProviderContext)
}
