import { MimoId, ProductOptions } from 'Types'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { useAccessibility } from '../../providers/AccessibilityProvider'

type InputLabelProps = PropsWithChildren<{
  label: string
}>
export function InputLabel({ label, children }: InputLabelProps) {
  return (
    <div>
      <div className="text-text font-barlow-cond font-medium pb-2 mt-4 uppercase">
        {label}
      </div>
      {children}
    </div>
  )
}

type OptionInputProps = {
  data: ProductOptions
  value?: MimoId
  onChange(id: MimoId): void
}
export default function OptionInput(props: OptionInputProps) {
  const { data, onChange, value } = props
  return (
    <div className="flex flex-wrap gap-2">
      {data.values.map((item) => (
        <OptionButton
          onClick={() => {
            onChange(item.id)
          }}
          selected={value === item.id}
          label={item.name}
          key={item.id}
        />
      ))}
    </div>
  )
}

type OptionButtonProps = {
  label: string
  selected?: boolean
  onClick(): void
}
function OptionButton(props: OptionButtonProps) {
  const { accessibility } = useAccessibility()
  const { label, selected, onClick } = props
  return (
    <button
      aria-pressed={selected}
      onClick={onClick}
      className={clsx(
        'uppercase font-barlow-cond py-1 px-2 rounded-lg',
        selected && 'bg-ctaPrimary text-ctaText',
        !selected && 'bg-accent text-secondary',
        accessibility.highContrast && '!bg-ctaPrimary !text-ctaText',
        accessibility.highContrast && selected && 'border border-white'
      )}
    >
      {label}
    </button>
  )
}
